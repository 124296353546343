import { React, useEffect } from "react";
// import { getQueryString } from "../../shared/constants";
// import { url, config } from "../../shared/constants/cognitoConstants";

import { signOut } from "aws-amplify/auth";

//import { useNavigate } from "react-router-dom";
export default function Logout() {
  //const navigate = useNavigate();
  useEffect(() => {
    signOut();
    localStorage.clear();
    //window.location.href = url + "login" + getQueryString(config);
    window.location.href = "/";
    //navigate("/");
    //window.history.forward();
  }, []);
  return <div></div>;
}
