//estimate cards
export const ESTIMATE_CARDS_REQUESTED = "ESTIMATE_CARDS_REQUESTED";
export const ESTIMATE_CARDS_SUCCESS = "ESTIMATE_CARDS_SUCCESS";
export const ESTIMATE_CARDS_FAILED = "ESTIMATE_CARDS_FAILED";

//pay bill Invoice details
export const INVOICE_DETAILS_REQUESTED = "INVOICE_DETAILS_REQUESTED";
export const INVOICE_DETAILS_SUCCESS = "INVOICE_DETAILS_SUCCESS";
export const INVOICE_DETAILS_FAILED = "INVOICE_DETAILS_FAILED";
export const RESET_INVOICE_DETAILS_LIST = "RESET_INVOICE_DETAILS_LIST";

export const INVOICE_ROW_DATA_REQUESTED = "INVOICE_ROW_DATA_REQUESTED";
//home tasks
export const CASE_STATUSES_REQUESTED = "CASE_STATUSES_REQUESTED";
export const CASE_STATUSES_SUCCESS = "CASE_STATUSES_SUCCESS";
export const CASE_STATUSES_FAILED = "CASE_STATUSES_FAILED";

//stage list
export const GET_STAGES_REQUESTED = "GET_STAGES_REQUESTED";
export const GET_STAGES_SUCCESS = "GET_STAGES_SUCCESS";
export const GET_STAGES_FAILED = "GET_STAGES_FAILED";

//adjustment list
export const ADJUSTMENT_LIST_REQUESTED = "ADJUSTMENT_LIST_REQUESTED";
export const ADJUSTMENT_LIST_SUCCESS = "ADJUSTMENT_LIST_SUCCESS";
export const ADJUSTMENT_LIST_FAILED = "ADJUSTMENT_LIST_FAILED";

//optional list
export const OPTIONAL_LIST_REQUESTED = "OPTIONAL_LIST_REQUESTED";
export const OPTIONAL_LIST_SUCCESS = "OPTIONAL_LIST_SUCCESS";
export const OPTIONAL_LIST_FAILED = "OPTIONAL_LIST_FAILED";

//neccasry list
export const NECESSARY_LIST_REQUESTED = "NECESSARY_LIST_REQUESTED";
export const NECESSARY_LIST_SUCCESS = "NECESSARY_LIST_SUCCESS";
export const NECESSARY_LIST_FAILED = "NECESSARY_LIST_FAILED";

//documents list
export const DOCUMENT_LIST_REQUESTED = "DOCUMENT_LIST_REQUESTED";
export const DOCUMENT_LIST_SUCCESS = "DOCUMENT_LIST_SUCCESS";
export const DOCUMENT_LIST_FAILED = "DOCUMENT_LIST_FAILED";

//practice details
export const PRACTICE_DATA_REQUESTED = "PRACTICE_DATA_REQUESTED";
export const PRACTICE_DATA_SUCCESS = "PRACTICE_DATA_SUCCESS";
export const PRACTICE_LIST_FAILED = "PRACTICE_DATA_FAILED";

export const LIST_OF_MESSAGES_REQUESTED = "LIST_OF_MESSAGES_REQUESTED";
export const LIST_OF_MESSAGES_SUCCESS = "LIST_OF_MESSAGES_SUCCESS";
export const LIST_OF_MESSAGES_FAILED = "LIST_OF_MESSAGES_FAILED";
export const PRACTICE_DATA_FAILED = "PRACTICE_DATA_FAILED";
//patient's profile data
export const PROFILE_DATA_REQUESTED = "PROFILE_DATA_REQUESTED";
export const PROFILE_DATA_SUCCESS = "PROFILE_DATA_SUCCESS";
export const PROFILE_DATA_FAILED = "PROFILE_DATA_FAILED";

//pratice hours and info
export const PRACTICE_INFO_REQUESTED = "PRACTICE_INFO_REQUESTED";
export const PRACTICE_INFO_SUCCESS = "PRACTICE_INFO_SUCCESS";
export const PRACTICE_INFO_FAILED = "PRACTICE_INFO_FAILED";

export const RESET_COST_COVERAGE_DATA = "RESET_COST_COVERAGE_DATA";

//get fap
export const FERTILTY_ACCESS_PROGRAMS_REQUESTED = "FERTILTY_ACCESS_PROGRAMS_REQUESTED";
export const FERTILTY_ACCESS_PROGRAMS_SUCCESS = "FERTILTY_ACCESS_PROGRAMS_SUCCESS";
export const FERTILTY_ACCESS_PROGRAMS_FAILED = "FERTILTY_ACCESS_PROGRAMS_FAILED";
export const RESEET_FERTILTY_ACCESS_PROGRAMS = "RESEET_FERTILTY_ACCESS_PROGRAMS";

// Program selection
export const UPADATE_PROGRAM_SELECTIONS_REQUESTED = "UPADATE_PROGRAM_SELECTION_REQUESTED";
export const UPADATE_PROGRAM_SELECTIONS_SUCCESS = "UPADATE_PROGRAM_SELECTION_SUCCESS";
export const UPADATE_PROGRAM_SELECTIONS_FAILED = "UPADATE_PROGRAM_SELECTION_FAILED";
//for sending the message
export const CREATE_NEW_MESSAGE_REQUESTED = "CREATE_NEW_MESSAGE_REQUESTED";
export const CREATE_NEW_MESSAGES_SUCCESS = "CREATE_NEW_MESSAGES_SUCCESS";
export const CREATE_NEW_MESSAGES_FAILED = "CREATE_NEW_MESSAGES_FAILED";

//for toggling the message read status
export const TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED = "TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED";
export const TOGGLE_MESSAGE_ISREAD_STATUS_SUCCESS = "TOGGLE_MESSAGE_ISREAD_STATUS_SUCCESS";
export const TOGGLE_MESSAGE_ISREAD_STATUS_FAILED = "TOGGLE_MESSAGE_ISREAD_STATUS_FAILED";

//Delete messages
export const DELETE_MESSAGE_REQUESTED = "DELETE_MESSAGE_REQUESTED";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL";

//Update messages
export const UPDATE_MULTIPLE_MESSAGES_REQUESTED = "UPDATE_MULTIPLE_MESSAGES_REQUESTED";
export const UPDATE_MULTIPLE_MESSAGES_SUCCESS = "UPDATE_MULTIPLE_MESSAGES_SUCCESS";
export const UPDATE_MULTIPLE_MESSAGES_FAILED = "UPDATE_MULTIPLE_MESSAGES_FAILED";

//registrations patient key
export const REGISTRATIONS_PATIENT_KEY_REQUESTED = "REGISTRATIONS_PATIENT_KEY_REQUESTED";
export const REGISTRATIONS_PATIENT_KEY_SUCCESS = "REGISTRATIONS_PATIENT_KEY_SUCCESS";
export const REGISTRATIONS_PATIENT_KEY_FAILED = "REGISTRATIONS_PATIENT_KEY_FAILED";

//post registrations patient set password and id
export const PATIENT_PASSWORD_ID_REQUESTED = "PATIENT_PASSWORD_ID_REQUESTED";
export const PATIENT_PASSWORD_ID_SUCCESS = "PATIENT_PASSWORD_ID_SUCCESS";
export const PATIENT_PASSWORD_ID_FAILED = "PATIENT_PASSWORD_ID_FAILED";

//fetch file
export const GET_FILE_REQUESTED = "GET_FILE_REQUESTED";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const GET_FILE_FAILED = "GET_FILE_FAILED";

//send file
export const POST_FILE_REQUESTED = "POST_FILE_REQUESTED";
export const POST_FILE_SUCCESS = "POST_FILE_SUCCESS";
export const POST_FILE_FAILED = "POST_FILE_FAILED";
//practicePgtaDataForselectionChoice

export const PRACTICE_PGTA_DATA_REQUESTED = "PRACTICE_PGTA_DATA_REQUESTED";
export const PRACTICE_PGTA_DATA_SUCCESS = "PRACTICE_PGTA_DATA_SUCCESS";
export const PRACTICE_PGTA_DATA_FAILED = "PRACTICE_PGTA_DATA_FAILED";

//get contract document
export const CONTRACT_DOCUMENT_REQUESTED = "CONTRACT_DOCUMENT_REQUESTED";
export const CONTRACT_DOCUMENT_SUCCESS = "CONTRACT_DOCUMENT_SUCCESS";
export const CONTRACT_DOCUMENT_FAILED = "CONTRACT_DOCUMENT_FAILED";

//get person details
export const PERSON_DATA_REQUESTED = "PERSON_DATA_REQUESTED";
export const PERSON_DATA_SUCCESS = "PERSON_DATA_SUCCESS";
export const PERSON_DATA_FAILED = "PERSON_DATA_FAILED";
//reset success messages of reply and compose
export const RESET_MESSAGES_EXECUTE_STATUS = "RESET_MESSAGES_EXECUTE_STATUS";

export const RESET_MESSAGE = "RESET_MESSAGE";

export const REVIEW_ESTIMATE_REQUESTED = "REVIEW_ESTIMATE_REQUESTED";
export const REVIEW_ESTIMATE_SUCCESS = "REVIEW_ESTIMATE_SUCCESS";
export const REVIEW_ESTIMATE_FAILED = "REVIEW_ESTIMATE_FAILED";

export const RESET_ESTIMATE_CARDS = "RESET_ESTIMATE_CARDS";
//get User list
export const USER_LIST_REQUESTED = "USER_LIST_REQUESTED";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILED = "USER_LIST_FAILED";

//reset contract data
export const RESET_CONTRACT_DATA_REQUESTED = "RESET_CONTRACT_DATA_REQUESTED";

export const RESET_CASE_STATUS = "RESET_CASE_STATUS";
export const RESET_MSGS = "RESET_MSGS";

//get Page details
export const PAGES_DATA_REQUESTED = "PAGES_DATA_REQUESTED";
export const PAGES_DATA_SUCCESS = "PAGES_DATA_SUCCESS";
export const PAGES_DATA_FAILED = "PAGES_DATA_FAILED";

// Impersonate Logs
export const ADD_IMPERSONATE_LOGS_REQUESTED = "ADD_IMPERSONATE_LOGS_REQUESTED";
export const ADD_IMPERSONATE_LOGS_SUCCESS = "ADD_IMPERSONATE_LOGS_SUCCESS";
export const ADD_IMPERSONATE_LOGS_FAIL = "ADD_IMPERSONATE_LOGS_FAIL";

// financial estimates get note
export const FINANCIAL_ESTIMATE_NOTES_REQUESTED = "FINANCIAL_ESTIMATE_NOTES_REQUESTED";
export const FINANCIAL_ESTIMATE_NOTES_SUCCESS = "FINANCIAL_ESTIMATE_NOTES_SUCCESS";
export const FINANCIAL_ESTIMATE_NOTES_FAILED = "FINANCIAL_ESTIMATE_NOTES_FAILED";
