import {
  Box,
  Button,
  Divider,
  Grid,
  Card,
  CardMedia,
  Typography,
  MenuItem,
  CardContent
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArticleIcon from "@mui/icons-material/Article";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import StyledMenu from "../../shared/components/StyledMenu";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../../store/actions";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment-timezone";
import { getLocaleDateTime } from "../../shared/constants/common";

const Inbox = (props) => {
  //const practiceData = useSelector((state) => state.homeReducer.practiceData);
  //const personData = useSelector((state) => state.homeReducer.personData);

  const {
    actions,
    ListOfMessages,
    ListOfMessagesLoading,
    toggleLoading,
    updateMessagesLoading,
    downloadedFileUrl,
    profileData,
    practiceInfoData
  } = props;
  const [pageSize, setPageSize] = useState(10);
  const [openSelectedMessage, setOpenSelectedMessage] = useState({});
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const userId = localStorage.getItem("userId");

  const impersonatorId = localStorage.getItem("impersonatorId");
  const cognitoUserId = localStorage.getItem("cognitoUserId");
  const patientId = localStorage.getItem("patientId");
  const practiceId = localStorage.getItem("practiceId");
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  useEffect(() => {
    if (!Object.keys(profileData).length) {
      let payload = {
        practiceId,
        patientId
      };
      actions.getProfileData(payload);
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(practiceInfoData).length) {
      let payload = {
        practiceId
      };
      actions.getPracticeInfoData(payload);
    }
  }, [practiceId]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const updateSelectedMessage = ({ row }) => {
    setOpenSelectedMessage(row);
  };
  const messageBackClickHandler = () => {
    setOpenSelectedMessage({});
  };
  var toggleIsReadMessageStatus = [];
  const changeMessageIsReadStatus = (messageStatus) => {
    toggleIsReadMessageStatus = [];
    selectedMessages.map((value) => {
      toggleIsReadMessageStatus.push({
        toUser: userId,
        isRead: messageStatus,
        messageId: value.messageId
      });
    });
  };

  const markAsReadClicked = () => {
    changeMessageIsReadStatus(true);
    let payload = {
      userId,
      toggleIsReadMessageStatus
    };
    //console.log("payload read", payload);
    actions.toggleMessageIsReadStatus(payload);
    handleClose();
    setSelectionModel([]);
  };

  const markAsUnreadClicked = () => {
    changeMessageIsReadStatus(false);
    let payload = {
      userId,
      toggleIsReadMessageStatus
    };
    //console.log("payload unread", payload);
    actions.toggleMessageIsReadStatus(payload);
    handleClose();
    setSelectionModel([]);
  };

  useEffect(() => {
    if (impersonatorId) {
      actions.createImpersonateLog({
        impersonatorId: impersonatorId,
        eventName: "Login In",
        screenName: "Message",
        eventType: "impersonate",
        ipAddress: "192.168.1.1",
        patientId: cognitoUserId
      });
    }
  }, []);

  useEffect(() => {
    if (userId) {
      let payload = {
        userId: userId,
        messagesStatus: "Inbox"
      };
      actions.getMessagesList(payload);
    }
  }, [userId, toggleLoading, updateMessagesLoading]);

  const deleteMessageHandler = () => {
    if (selectedMessages.length) {
      selectedMessages.map((item) => {
        delete item.id;
      });
    }
    let updatedMessages = selectedMessages.map((value) => ({
      ...value,
      body: null,
      sentDate: null,
      status: "Deleted",
      updationDate: null
    }));
    let payload = {
      userId,
      updatedMessages
    };
    //console.log("payload", payload);
    actions.updateMultipleMessages(payload);
  };

  const messageReplyHandler = () => {
    navigate(`/messages/${selectedMessages[0].messageId}/reply`, {
      state: {
        data: selectedMessages[0]
      }
    });
  };

  const downloadHandler = () => {
    selectedMessages[0].messageAttachment.map((item) => {
      let payload = {
        filePath: item.attachmentUrl
      };
      actions.getFile(payload);
    });
    setSelectionModel([]);
  };
  useEffect(() => {
    if (downloadedFileUrl) {
      let a = document.createElement("a");
      a.href = downloadedFileUrl;
      a.download = "document.pdf";
      a.click();
      actions.resetMessageExecuteStatus();
    }
  }, [downloadedFileUrl]);

  useEffect(() => {
    if (ListOfMessages) {
      let rows = ListOfMessages.messageList.map((item, index) => {
        item.id = index;
        return item;
      });
      setRows(rows);
    }
  }, [ListOfMessages]);

  const columns = [
    {
      field: "type",
      headerName: "CATEGORY",
      // flex: 1.2,
      minWidth: 200,
      maxWidth: 250,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row">
            <CircleIcon
              className={row.messageRecipient[0].isRead == true ? "color-blue" : "color-pink"}
              sx={{ fontSize: "11.5px", marginTop: "4px" }}
            />
            <Typography className="word-wrap" marginLeft={2}>
              {row.type}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "sentDate",
      headerName: "DATE",
      type: "date",
      // flex: 1.5,
      minWidth: 200,
      maxWidth: 250,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {/* {moment.utc(row.sentDate).tz("America/New_York").format("MM/DD/YYYY hh:mm A") + " EST"} */}
            {getLocaleDateTime(row?.sentDate, "MM/DD/YYYY hh:mm A z", true)}
          </Typography>
        );
      },
      sortComparator: (v1, v2) => {
        return moment(v1).valueOf() - moment(v2).valueOf();
      }
    },
    {
      field: "subject",
      headerName: "SUBJECT",
      // flex: 2,
      minWidth: 400,
      renderCell: ({ row }) => {
        return <Typography>{row.subject}</Typography>;
      }
    }
  ];
  const redirectToAnotherPage = (Url) => {
    //let fertLink = "https://fertilityaccessprogram.com/";
    window.open(Url, "_blank", "noreferrer");
  };

  return (
    <Box>
      <Grid container className="mt-15">
        <Grid item sx={4} lg={1}>
          {Object.keys(openSelectedMessage).length != 0 && (
            <Button onClick={messageBackClickHandler}>
              <KeyboardBackspaceIcon />
            </Button>
          )}
        </Grid>
        <Grid item sx={4} lg={0.9} style={{ display: "none" }}>
          <Button
            variant="outlined"
            onClick={messageReplyHandler}
            disabled={selectedMessages.length != 1}>
            Reply
          </Button>
        </Grid>
        <Grid item sx={4} lg={0.95}>
          <Button
            variant="outlined"
            disabled={selectedMessages.length == 0}
            onClick={deleteMessageHandler}>
            Delete
          </Button>
        </Grid>
        <Grid item sx={4} lg={2.15}>
          <Button
            variant="outlined"
            onClick={downloadHandler}
            disabled={
              selectedMessages.length != 1 || !selectedMessages[0]?.messageAttachment?.length
            }>
            Download Attachment
          </Button>
        </Grid>
        <Grid item sx={4} lg={7}>
          {Object.keys(openSelectedMessage).length != 0 ? (
            <Button variant="outlined" onClick={markAsUnreadClicked}>
              Mark as unread
            </Button>
          ) : (
            <Stack direction="row">
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="outlined"
                disableElevation
                onClick={(event) => setAnchorEl(event.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
                disabled={selectedMessages.length == 0}>
                Mark As
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button"
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={markAsReadClicked}>Read</MenuItem>
                <MenuItem onClick={markAsUnreadClicked}>Unread</MenuItem>
              </StyledMenu>
            </Stack>
          )}
        </Grid>
      </Grid>
      {Object.keys(openSelectedMessage).length != 0 ? (
        <Box className="message-body">
          <Stack>
            <Stack gap={1}>
              <Typography>{openSelectedMessage.type}</Typography>
              <Typography>{openSelectedMessage.subject}</Typography>
              <Typography>
                {/* moment
                  .utc(openSelectedMessage.sentDate)
                  .tz("America/New_York")
                  .format("MM/DD/YYYY hh:mm A") + " EST" */}
                {getLocaleDateTime(openSelectedMessage?.sentDate, "MM/DD/YYYY hh:mm A z", true)}
              </Typography>
            </Stack>

            <Divider fitted="true" sx={{ mt: 2, mb: 2 }} />
            <Typography> Hello {profileData?.firstName},</Typography>
            <br />
            <Typography
              style={{ whiteSpace: "pre-line" }}
              component="span"
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: openSelectedMessage?.body?.split("#br#").join("<br />")
              }}
            />

            <Stack className="mt-15">
              <Typography>Thank you,</Typography>

              {(openSelectedMessage.type === "Enrollment" && (
                <>
                  <Typography sx={{ mb: 1, mt: 1 }}>{practiceInfoData.name}</Typography>
                </>
              )) || (
                <>
                  <Typography sx={{ mb: 1, mt: 1 }}>Fertility Access</Typography>
                </>
              )}
            </Stack>
            {(openSelectedMessage.type === "Enrollment" && (
              <>
                <Link
                  underline="none"
                  target="_blank"
                  onClick={() => redirectToAnotherPage(practiceInfoData.website)}
                  style={{ color: "#1BA3AD" }}>
                  {practiceInfoData.website}
                </Link>
              </>
            )) || (
              <>
                <Link
                  underline="none"
                  target="_blank"
                  onClick={() => redirectToAnotherPage("https://fertilityaccessprogram.com/")}
                  style={{ color: "#1BA3AD" }}>
                  https://fertilityaccessprogram.com/
                </Link>
              </>
            )}

            <br />
            <Typography>Please do not respond this message.</Typography>
          </Stack>
          <Stack direction="row" gap={2}>
            {openSelectedMessage.hasAttachment &&
              openSelectedMessage.messageAttachment.map((item, index) => {
                return (
                  <Card className="msg-attachment" key={index}>
                    <CardMedia
                      component="iframe"
                      src={item.attachmentUrl}
                      width="100%"
                      height="80"
                      scrolling="no"
                    />
                    <CardContent>
                      <Stack direction="row" className="flex-between">
                        <Stack direction="row" className="align-center" spacing={1}>
                          <ArticleIcon color="primary" />
                          <Typography>{item.attachFileName}</Typography>
                        </Stack>
                        <Link to={item.attachmentUrl} target="_blank">
                          <OpenInNewIcon />
                        </Link>
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
          </Stack>
        </Box>
      ) : (
        <Box className="grid-style1">
          <DataGrid
            sx={{ mx: 1 }}
            loading={ListOfMessagesLoading}
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            rows={rows}
            columns={columns}
            onRowClick={(e) => {
              updateSelectedMessage(e);
            }}
            checkboxSelection={true}
            onSelectionModelChange={(ids) => {
              setSelectionModel(ids);
              const selectedIds = new Set(ids);
              setSelectedMessages(rows.filter((row) => selectedIds.has(row.id)));
            }}
            selectionModel={selectionModel}
            // autoHeight={true}
          />
        </Box>
      )}
    </Box>
  );
};
const mapStateToProps = ({ messageReducer, profileReducer }) => {
  return {
    ListOfMessages: messageReducer.ListOfMessages,
    ListOfMessagesLoading: messageReducer.ListOfMessagesLoading,
    toggleLoading: messageReducer.toggleLoading,
    updateMessagesLoading: messageReducer.updateMessagesLoading,
    downloadedFileUrl: messageReducer.downloadedFileUrl,
    profileData: profileReducer.profileData,
    practiceInfoData: profileReducer.practiceInfoData
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMessagesList: (payload) => {
      dispatch(actionType.getMessagesList(payload));
    },
    toggleMessageIsReadStatus: (payload) => {
      dispatch(actionType.toggleMessageIsReadStatus(payload));
    },
    updateMultipleMessages: (payload) => {
      dispatch(actionType.updateMultipleMessages(payload));
    },
    getFile: (payload) => {
      dispatch(actionType.getFile(payload));
    },
    resetMessageExecuteStatus: () => {
      dispatch(actionType.resetMessageExecuteStatus());
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    },
    getProfileData: (payload) => {
      dispatch(actionType.getProfileData(payload));
    },
    getPracticeInfoData: (payload) => {
      dispatch(actionType.getPracticeInfoData(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
