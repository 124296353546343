import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Grid,
  Card,
  IconButton,
  Link,
  CircularProgress
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import SelectedProgram from "../../shared/components/SelectedProgram";
import NextSteps from "../../shared/components/NextStep";
import { connect, useSelector } from "react-redux";
import * as actionType from "../../store/actions";
import { toUSAFormat } from "../../shared/constants";
import { getLocaleDateTime } from "../../shared/constants/common";
import SelecctedProgramPayBillTreatmentEstimate from "./SelectedProgramPayBillTreatmentEstimate";

const ConfirmSelection = (props) => {
  const { actions, estimateCards, cardLoading, practiceInfoData } = props;
  const caseList = useSelector((state) => state.homeReducer.caseStatusList);
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedProgInvoice, setSelectedProgInvoice] = useState({});
  const practiceId = localStorage.getItem("practiceId");
  // const [programFees, setProgramFees] = useState();
  // const patientId = localStorage.getItem("patientId");
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!caseList?.finEstimateId) {
  //     actions.getCaseStatusesData({ practiceId, patientId });
  //   }
  // }, [caseStatusLoading]);

  useEffect(() => {
    if (estimateCards.length <= 0) {
      let payload = {
        practiceId: practiceId,
        estimateId: caseList.finEstimateId
      };
      actions.getEstimateCard(payload);
    }

    actions.getPracticeInfoData({ practiceId });
  }, []);

  const [programFees, setProgramFees] = useState();
  const [treatmentChange, setTreatmentChange] = useState(null);

  const handleProgramFees = (programCost) => {
    console.log("hr programCost", programCost);
    if (programCost) setProgramFees(programCost);
  };

  useEffect(() => {
    console.log("zhr estimateCards:", estimateCards);

    if (treatmentChange != null) {
      if (estimateCards.length > 0) {
        let res = [];

        if (treatmentChange == "1") {
          res = estimateCards.filter((item) => {
            return item?.isSelected == "1";
          });
          setSelectedProgInvoice(res[0]);
          setSelectedInvoice(res[0]);
        } else {
          res = estimateCards.filter((item) => {
            return item?.linkedEstimateTreatmentPlan?.isSelected == "1";
          });
          setSelectedProgInvoice(res[0]);
          setSelectedInvoice(res[0]?.linkedEstimateTreatmentPlan);
        }

        setSelectedTreatmentPlan(res[0]);
      }
    } else {
      if (estimateCards.length > 0) {
        let res = estimateCards.filter((item) => {
          return item.isSelected == "1";
        });

        if (res && res[0]) {
          setSelectedProgInvoice(res[0]);
          setSelectedTreatmentPlan(res[0]);
          // let invoieObject = {
          //   programName: res[0].optionSelected
          // };

          setSelectedInvoice(res[0]);
        }
      }
    }
  }, [estimateCards, treatmentChange]);

  // const convertDate = (publishDate) => {
  //   var date = new Date(publishDate);
  //   var year = date.toLocaleString("default", { year: "numeric" });
  //   var month = date.toLocaleString("default", { month: "2-digit" });
  //   var day = date.toLocaleString("default", { day: "2-digit" }); // Generate yyyy-mm-dd date string

  //   var formattedDate = month + "/" + day + "/" + year;
  //   return formattedDate;
  // };

  const spliteDate = (newdate, seprator) => {
    let finalDate = "";
    let datearr = newdate.split(seprator);
    if (seprator === "T") {
      let nSplit = datearr[0].split("-");
      let year = nSplit[0];
      let month = nSplit[1];
      let date = nSplit[2];
      finalDate = month + "/" + date + "/" + year;
    } else {
      let year = datearr[0];
      let month = datearr[1];
      let date = datearr[2];

      finalDate = month + "/" + date + "/" + year;
    }
    return finalDate;
  };

  const nextdate = (monthnumber) => {
    let dt = new Date();
    if (caseList?.publishedDate) {
      dt = new Date(caseList.publishedDate);
    }

    let no_of_months = monthnumber || 1;
    //dt.setMonth(dt.getMonth() + no_of_months);
    dt.setDate(dt.getDate() + no_of_months);
    return dt.toLocaleDateString();
  };

  return (
    <Box m={2}>
      {cardLoading ? (
        <Stack justifyContent="center" p={5} alignItems="center">
          <CircularProgress size={50} />
        </Stack>
      ) : (
        <>
          <Stack direction="row" gap={1}>
            <Typography>
              Treatment Estimate:
              {/* {caseList.estimateType == "D" ? " DETAILED ESTIMATE " : " PRILIMNARY ESTIMATE "} */}
            </Typography>
            <Typography>
              Published On
              {
                " " +
                  getLocaleDateTime(
                    caseList?.publishedDate,
                    "L",
                    true
                  ) /* spliteDate(caseList.publishedDate, "T") */
              }
            </Typography>
            {selectedTreatmentPlan?.programSelectedDate ? (
              <Typography>
                Selected On
                {" " + spliteDate(selectedTreatmentPlan?.programSelectedDate, "-")}
              </Typography>
            ) : null}
            {selectedTreatmentPlan?.invoiceNo ? (
              <Typography>Invoice No # {selectedTreatmentPlan?.invoiceNo}</Typography>
            ) : null}
          </Stack>
          <Stack mt={3} direction="row">
            {selectedTreatmentPlan?.optionSelected ? (
              <>
                <Typography variant="h6" style={{ paddingRight: "5px" }}>
                  Congratulations{" "}
                  <Typography variant="p">
                    and thank you for confirming your treatment plan!
                  </Typography>
                </Typography>
              </>
            ) : (
              <Typography>
                Thank you for selecting the your treatment plan. Your financial counselor will be
                notified of your decision to pay the practice directly one cycle at a time, and they
                will contact you shortly.
              </Typography>
            )}
          </Stack>
          <Stack mt={2} gap={1} direction="column">
            <Typography variant="body1">Next Steps</Typography>
            <Grid container gap={2}>
              {selectedTreatmentPlan?.optionSelected ? (
                <Grid item xs={12} lg={6}>
                  <Card variant="outlined" sx={{ p: 1 }} elevation={0}>
                    <Stack direction="row" gap={1} justifyContent="start" alignItems="baseline">
                      <IconButton>
                        <BorderColorOutlinedIcon />
                      </IconButton>
                      <Typography sx={{ fontSize: 18 }}>Sign Your Contract</Typography>
                    </Stack>
                    <Typography ml={6} my={1} width="83%">
                      When your contract is ready, you will receive a secure message and the
                      contract will be in your{" "}
                      <Link underline="none" onClick={() => navigate("/document-management")}>
                        Document Management
                      </Link>{" "}
                      section.
                    </Typography>
                  </Card>
                </Grid>
              ) : null}
              <Grid item xs={12} lg={6}>
                <Card variant="outlined" sx={{ p: 1 }} elevation={0}>
                  <Stack direction="row" gap={1} justifyContent="start" alignItems="baseline">
                    <IconButton>
                      <AttachMoneyOutlinedIcon sx={{ alignItems: "baseline" }} />
                    </IconButton>
                    <Typography sx={{ fontSize: 18 }}>Pay</Typography>
                  </Stack>
                  <Typography ml={6}>
                    You Must pay the full program costs before stimulation begins
                  </Typography>
                  <Link ml={6} underline="none" onClick={() => navigate("/pay-your-bill")}>
                    Pay your bill
                  </Link>
                </Card>
              </Grid>
              <Stack direction="column" width="100%">
                {/* <Typography variant="body1">Selected Program Details</Typography> */}

                {estimateCards.length ? (
                  <SelectedProgram
                    estimateCards={estimateCards}
                    selectedInvoice={selectedProgInvoice}
                    prgramFeesNotSet={true}
                    stPlan={selectedTreatmentPlan}
                    // handleProgramFees={handleProgramFees}
                    setTreatmentChange={setTreatmentChange}
                  />
                ) : null}

                {estimateCards && !cardLoading ? (
                  <SelecctedProgramPayBillTreatmentEstimate
                    estimateCards={estimateCards}
                    finEstimateId={selectedInvoice?.finEstimateId}
                    selectedInvoice={selectedInvoice}
                    handleProgramFees={handleProgramFees}
                    payProgramFees={programFees}
                    payProgramName={
                      selectedInvoice?.programName
                        ? selectedInvoice?.programName
                        : selectedInvoice?.optionSelected
                    }
                    // stPlan={selectedTreatmentPlan}
                  />
                ) : null}

                {selectedTreatmentPlan?.optionSelected ? (
                  <NextSteps
                    estimatePublishdate={caseList.publishedDate}
                    practiceInfo={practiceInfoData}
                  />
                ) : (
                  <Stack orientation="column" className="m-16">
                    <Typography
                      variant="h8"
                      sx={{ marginBottom: "10px", marginTop: "10px" }}
                      color="primary">
                      DISCLAIMERS
                    </Typography>
                    <Typography>
                      This estimate is valid until {nextdate(90)}. Only fees paid to{" "}
                      {practiceInfoData?.name} for services rendered by {practiceInfoData?.name} are
                      guaranteed for this time period. {practiceInfoData?.name} is not responsible
                      for fee increases related to services or items supplied from outside
                      providers, such as medications or anesthesia.
                    </Typography>
                    {/* <Typography>This estimate is valid until {nextdate(60)}</Typography> */}
                    <Typography sx={{ marginBottom: "10px" }}>
                      For an updated quote after this date, please call your financial counselor at{" "}
                      <Link
                        to={"tel://" + practiceInfoData?.phoneNumber}
                        target="_blank"
                        className="primary-one">
                        {(practiceInfoData &&
                          practiceInfoData.phoneNumber &&
                          toUSAFormat(practiceInfoData.phoneNumber)) ||
                          ""}
                      </Link>{" "}
                      .
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Stack>
        </>
      )}
    </Box>
  );
};
const mapStateToProps = ({ estimateReducer, homeReducer, profileReducer }) => {
  return {
    estimateCards: estimateReducer.estimateCards,
    caseStatusLoading: homeReducer.loading,
    cardLoading: estimateReducer.estimateCardLoading,
    cardLoaded: estimateReducer.cardLoaded,
    practiceInfoData: profileReducer.practiceInfoData
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    getCaseStatusesData: (payload) => {
      dispatch(actionType.getCaseStatusesData(payload));
    },
    getPracticeInfoData: (payload) => {
      dispatch(actionType.getPracticeInfoData(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSelection);
