import React from "react";
import { Route, Routes } from "react-router-dom";
import InitialRegistration from "../components/registration/InitialRegistration";

// import Login from "../components/login/Login";
import AuthorizationAccess from "../components/login/AuthorizationAccess";

const SignUpRoute = () => {
  return (
    <>
      <Routes>
        {/* <AuthorizationAccess /> */}
        <Route exact path="/" element={<AuthorizationAccess />} />
        <Route exact path="/initial-registration" element={<InitialRegistration />} />
      </Routes>
    </>
  );
};
export default SignUpRoute;
