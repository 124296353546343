import * as actionType from "./ActionTypes";

export const getEstimateCard = (payload) => {
  return {
    type: actionType.ESTIMATE_CARDS_REQUESTED,
    payload
  };
};
export const getInvoiceDetails = (payload) => {
  return {
    type: actionType.INVOICE_DETAILS_REQUESTED,
    payload
  };
};
export const invoiceRowData = (payload) => {
  return {
    type: actionType.INVOICE_ROW_DATA_REQUESTED,
    payload
  };
};
export const resetInvoiceDetailsList = () => {
  return {
    type: actionType.RESET_INVOICE_DETAILS_LIST
  };
};
export const getCaseStatusesData = (payload) => {
  return {
    type: actionType.CASE_STATUSES_REQUESTED,
    payload
  };
};
export const getStages = (payload) => {
  return {
    type: actionType.GET_STAGES_REQUESTED,
    payload
  };
};

export const getAdjustments = (payload) => {
  return {
    type: actionType.ADJUSTMENT_LIST_REQUESTED,
    payload
  };
};

export const getOptional = (payload) => {
  return {
    type: actionType.OPTIONAL_LIST_REQUESTED,
    payload
  };
};

export const getNecessary = (payload) => {
  return {
    type: actionType.NECESSARY_LIST_REQUESTED,
    payload
  };
};

export const getDocumentList = (payload) => {
  return {
    type: actionType.DOCUMENT_LIST_REQUESTED,
    payload
  };
};

export const getPracticeData = (payload) => {
  return {
    type: actionType.PRACTICE_DATA_REQUESTED,
    payload
  };
};

export const getMessagesList = (payload) => {
  return {
    type: actionType.LIST_OF_MESSAGES_REQUESTED,
    payload
  };
};

export const getProfileData = (payload) => {
  return {
    type: actionType.PROFILE_DATA_REQUESTED,
    payload
  };
};

export const getPracticeInfoData = (payload) => {
  return {
    type: actionType.PRACTICE_INFO_REQUESTED,
    payload
  };
};
export const resetCostCoverage = () => {
  return {
    type: actionType.RESET_COST_COVERAGE_DATA
  };
};

export const getFertilityAccessPrograms = (payload) => {
  return {
    type: actionType.FERTILTY_ACCESS_PROGRAMS_REQUESTED,
    payload
  };
};

export const updateProgramSelections = (payload) => {
  return {
    type: actionType.UPADATE_PROGRAM_SELECTIONS_REQUESTED,
    payload
  };
};
//for sending the message
export const createNewMessage = (payload) => {
  return {
    type: actionType.CREATE_NEW_MESSAGE_REQUESTED,
    payload
  };
};

//toggling message read status
export const toggleMessageIsReadStatus = (payload) => {
  return {
    type: actionType.TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED,
    payload
  };
};

//delete messages from list
export const deleteMessageFromList = (payload) => {
  return {
    type: actionType.DELETE_MESSAGE_REQUESTED,
    payload: payload
  };
};

//update messages
export const updateMultipleMessages = (payload) => {
  return {
    type: actionType.UPDATE_MULTIPLE_MESSAGES_REQUESTED,
    payload
  };
};

//GET /registrations/patients/key
export const getRegistrationsPatientKey = (payload) => {
  return {
    type: actionType.REGISTRATIONS_PATIENT_KEY_REQUESTED,
    payload: payload
  };
};

//POST /registrations/patients
export const postPatientPasswordId = (payload) => {
  return {
    type: actionType.PATIENT_PASSWORD_ID_REQUESTED,
    payload: payload
  };
};

//fetch file
export const getFile = (payload) => {
  return {
    type: actionType.GET_FILE_REQUESTED,
    payload
  };
};

//send file
export const sendFile = (payload) => {
  return {
    type: actionType.POST_FILE_REQUESTED,
    payload
  };
};

export const resetMessage = () => {
  return {
    type: actionType.RESET_MESSAGE,
    success: false,
    error: false
  };
};

//practicePGTAdata
export const getPracticePGTAData = (payload) => {
  return {
    type: actionType.PRACTICE_PGTA_DATA_REQUESTED,
    payload: payload
  };
};

//get Contract Document
export const getContractDocument = (payload) => {
  return {
    type: actionType.CONTRACT_DOCUMENT_REQUESTED,
    payload: payload
  };
};

//get person Data
export const getPersonData = (payload) => {
  return {
    type: actionType.PERSON_DATA_REQUESTED,
    payload
  };
};
//reset success messages of reply and compose
export const resetMessageExecuteStatus = () => {
  return {
    type: actionType.RESET_MESSAGES_EXECUTE_STATUS
  };
};

export const reviewEstimate = (payload) => {
  return {
    type: actionType.REVIEW_ESTIMATE_REQUESTED,
    payload
  };
};

export const resetEstimateCards = () => {
  return {
    type: actionType.RESET_ESTIMATE_CARDS
  };
};
export const getUserList = (payload) => {
  return {
    type: actionType.USER_LIST_REQUESTED,
    payload
  };
};

//reset contract document data
export const resetContractData = () => {
  return {
    type: actionType.RESET_CONTRACT_DATA_REQUESTED
  };
};
export const resetCaseStatus = () => {
  return {
    type: actionType.RESET_CASE_STATUS
  };
};
export const resetMsgs = () => {
  return {
    type: actionType.RESET_MSGS
  };
};

//practicePGTAdata
export const getPagesData = (payload) => {
  return {
    type: actionType.PAGES_DATA_REQUESTED,
    payload: payload
  };
};

export const createImpersonateLog = (payload) => {
  return {
    type: actionType.ADD_IMPERSONATE_LOGS_REQUESTED,
    payload: payload
  };
};

export const getNotes = (payload) => {
  return {
    type: actionType.FINANCIAL_ESTIMATE_NOTES_REQUESTED,
    payload
  };
};
