import * as actionType from "../actions/ActionTypes";
import { put, call, takeEvery, all } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

//get list of all 4 messages tab(Inbox, Draft, Sent, Delete)
function* getListOfMessages(action) {
  const { payload } = action;
  const { userId, messagesStatus } = payload;

  try {
    const params = {
      messageStatus: messagesStatus,
      offset: "0",
      limit: "10000"
    };

    const requestBody = createRequestBody("get", `/user/${userId}/messages`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.LIST_OF_MESSAGES_SUCCESS,
      payload: { messagesStatus, list: response.data }
    });
  } catch (error) {
    yield put({
      type: actionType.LIST_OF_MESSAGES_FAILED,
      message: error.message
    });
  }
}

function* getListOfMessagesSaga() {
  yield takeEvery(actionType.LIST_OF_MESSAGES_REQUESTED, getListOfMessages);
}

//create New Message
function* createMessage(action) {
  const { payload } = action;
  const { userId, updatedMessages } = payload;

  try {
    const requestBody = createRequestBody("post", `/user/${userId}/messages`, "", updatedMessages);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.CREATE_NEW_MESSAGES_SUCCESS,
      payload: response.data,
      successMsg: "Message send successfully"
    });
  } catch (err) {
    yield put({
      type: actionType.CREATE_NEW_MESSAGES_FAILED,
      errorMsg: err.response.data
    });
  }
}

function* createNewMessageSaga() {
  yield takeEvery(actionType.CREATE_NEW_MESSAGE_REQUESTED, createMessage);
}

//Save message Read status
function* updateMessageIsRead(action) {
  const { payload } = action;
  const { userId, toggleIsReadMessageStatus } = payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/user/${userId}/messages/ToggleRead`,
      "",
      toggleIsReadMessageStatus
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.TOGGLE_MESSAGE_ISREAD_STATUS_SUCCESS,
      payload: response.data,
      successMsg: "Toggled message status"
    });
  } catch (err) {
    yield put({
      type: actionType.TOGGLE_MESSAGE_ISREAD_STATUS_FAILED,
      errorMsg: err.response.data
    });
  }
}

function* updateMessageIsReadSaga() {
  yield takeEvery(actionType.TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED, updateMessageIsRead);
}

function* deleteMessage(action) {
  const { payload } = action;
  const { userId, deletedMessagesIds } = payload;
  try {
    const requestBody = createRequestBody("delete", `/user/${userId}/messages`, "", {
      data: deletedMessagesIds
    });
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.DELETE_MESSAGE_SUCCESS,
      payload: response.data,
      successMsg: "Message deleted Successfully"
    });
  } catch (err) {
    yield put({
      type: actionType.DELETE_MESSAGE_FAIL,
      errorMsg: err.response.data
    });
  }
}

function* deleteMessageSaga() {
  yield takeEvery(actionType.DELETE_MESSAGE_REQUESTED, deleteMessage);
}

//update multiple messages
function* updateMultipleMessage(action) {
  const { payload } = action;
  const { userId, updatedMessages } = payload;

  try {
    const requestBody = createRequestBody("put", `/user/${userId}/messages`, "", updatedMessages);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.UPDATE_MULTIPLE_MESSAGES_SUCCESS,
      payload: response.data,
      successMsg: "Messages updated successfully"
    });
  } catch (err) {
    yield put({
      type: actionType.UPDATE_MULTIPLE_MESSAGES_FAILED,
      errorMsg: err.response.data
    });
  }
}

function* updateMultipleMessagesSaga() {
  yield takeEvery(actionType.UPDATE_MULTIPLE_MESSAGES_REQUESTED, updateMultipleMessage);
}

function* fetchFile(action) {
  const { payload } = action;
  const { filePath } = payload;
  try {
    const params = {
      fileFormat: "url",
      filePath: filePath
    };
    const requestBody = createRequestBody("get", `/fileUpload`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.GET_FILE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.GET_FILE_FAILED,
      message: error.message
    });
  }
}

function* fetchFileSaga() {
  yield takeEvery(actionType.GET_FILE_REQUESTED, fetchFile);
}

function* postFile(action) {
  const { payload } = action;
  const { fileEncoded, practiceId, fileType, fileName } = payload;
  try {
    const params = {
      practiceId: practiceId,
      isBase64Encoded: true,
      fileName: fileName
    };
    const requestBody = createRequestBody("post", `/fileUpload`, params, fileEncoded);
    const request = signatureV4CreateCanonicalRequest(
      requestBody,
      getTemporaryCredentials(),
      fileType
    );
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.POST_FILE_SUCCESS,
      payload: response.data,
      successMsg: "file send successfully"
    });
  } catch (err) {
    yield put({
      type: actionType.POST_FILE_FAILED,
      errorMsg: err.response
    });
  }
}

function* postFileSaga() {
  yield takeEvery(actionType.POST_FILE_REQUESTED, postFile);
}

export default function* messagesSaga() {
  yield all([
    getListOfMessagesSaga(),
    createNewMessageSaga(),
    updateMessageIsReadSaga(),
    deleteMessageSaga(),
    updateMultipleMessagesSaga(),
    fetchFileSaga(),
    postFileSaga()
  ]);
}
