import { Typography, Stack, Box, TextField, Button, Grid, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as actionTypes from "../../store/actions/index";
import { connect } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import RegisterModal from "../modals/RegisterModal";
import { invalidMsg, InitRegistrationTxt } from "../../shared/constants/constants";
import { getQueryString } from "../../shared/constants";
import { url, config } from "../../shared/constants/cognitoConstants";
// import Header from "./../../shared/components/Header";
// import Footer from "./../../shared/components/Footer";

const Registration1 = (props) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(invalidMsg);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isMessageValidate, setIsMessageValidate] = useState(false);
  const { actions, patientInformation = [], error, loading, statusCode } = props;
  const loginUrl = window.location.href;
  let tokenId = loginUrl.split("/#")[1];
  const succMessage = "Account already exists.Click Ok to login";
  const handleClose = () => {
    if (message && !isMessageValidate) {
      actions.resetMessage();
      setMessage(invalidMsg);
      setIsMessageModal(false);
    } else {
      actions.resetMessage();
      setMessage("");
      setIsMessageModal(false);
      tokenId =
        localStorage.getItem("tokenId") !== "undefined" && localStorage.getItem("tokenId")
          ? localStorage.getItem("tokenId")
          : tokenId;
      if (!localStorage.getItem("email") && !tokenId && statusCode === 500) {
        //window.location.href = url + "login" + getQueryString(config);
        console.log("===>", url + "login" + getQueryString(config));
        navigate("/", { replace: true });
      }
      navigate("/", { replace: true });
    }
  };
  //const dateToday = new Date();
  //const formatDateToday = moment(dateToday, "MM/DD/YYYY");
  const [values, setValues] = useState({
    pNumber: "",
    dateOfBirth: null
  });
  const payload = {
    pNumber: values.pNumber
  };
  const verifyUser = () => {
    actions.getRegistrationsPatientKey(payload);
  };
  useEffect(() => {
    if (
      patientInformation.birthDate === moment(values.dateOfBirth).format("MM/DD/YYYY") &&
      patientInformation.personKey === values.pNumber
    ) {
      // Set Practice ID and fetch details
      if (patientInformation && patientInformation.practiceId) {
        localStorage.setItem("practiceId", patientInformation.practiceId);
        //actions.getPracticeData({ practiceId: patientInformation.practiceId });
      }

      setMessage("");
      setIsMessageModal(false);
      navigate("/password-registration", { state: { pNumber: patientInformation.personKey } });
    } else {
      let patientDOB = (patientInformation && patientInformation.birthDate) || "";
      let inputDOB = moment(values.dateOfBirth).format("MM/DD/YYYY");

      let patientDOBTime = new Date(patientDOB).getTime();
      let inputDOBTime = new Date(inputDOB).getTime();

      console.log("==>donbcheck", patientDOBTime !== inputDOBTime);
      console.log("==>donbcheck", patientDOBTime, patientDOB);
      console.log("==>donbcheck", inputDOBTime, inputDOB);

      if (
        values.dateOfBirth &&
        patientInformation &&
        patientInformation.birthDate &&
        patientDOBTime !== inputDOBTime &&
        statusCode !== 404 &&
        statusCode !== 500
      ) {
        console.log("statusCode", statusCode);
        setMessage("Patient Number or Date of Birth is invalid");
        setIsMessageModal(true);
      } else if (error) {
        if (error && error.toLowerCase() === succMessage.toLowerCase()) {
          setIsMessageValidate(true);
        }
        setMessage(error || message);
        setIsMessageModal(true);
      }
    }
  }, [patientInformation, error]);

  // useEffect(() => {
  //   if (error && !isMessageModal && message === "") {
  //     setMessage(error);
  //     setIsMessageModal(true);
  //   }
  // });

  return (
    <>
      <Box className={`m-25 ${loading ? "disable-section" : ""}`}>
        <Grid container flexDirection={"column"} spacing={2}>
          <Grid item xs={12} sm={9} md={5}>
            <Typography className="f-14">FINANCIAL PORTAL REGISTRATION</Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={5}>
            <Stack className="m-t-8">
              <Typography className="f-14">{<InitRegistrationTxt />}</Typography>
            </Stack>
            <Stack className="m-t-8">
              <Typography className="mb-10">
                Patient Number<span className="span-text-color">*</span>
              </Typography>
              <TextField
                value={values.pNumber}
                onChange={(e) => setValues({ ...values, pNumber: e.target.value })}
              />
            </Stack>
            <Stack className="m-t-8">
              <Grid item xs={10} sm={6} md={3.5}>
                <Typography className="mb-10">
                  Date of Birth<span className="span-text-color">*</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disableFuture
                    size="small"
                    openTo="day"
                    value={values.dateOfBirth ? moment(values.dateOfBirth, "MM-DD-YYYY") : null}
                    onChange={(newValue) => {
                      setValues({
                        ...values,
                        dateOfBirth: moment(newValue).format("MM-DD-YYYY")
                      });
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ flex: 6 }} {...params} size="small" fullWidth />
                    )}></DatePicker>
                </LocalizationProvider>
              </Grid>
            </Stack>
            <Stack className="m-t-16">
              <Grid flexDirection="column" container sx={{ justifyContent: "space-between" }}>
                <Grid item xs={6}>
                  {values.pNumber !== "" && values.dateOfBirth ? (
                    <Button onClick={verifyUser} variant="outlined">
                      CONTINUE
                    </Button>
                  ) : (
                    <Button variant="outlined" disabled>
                      CONTINUE
                    </Button>
                  )}
                  {loading ? (
                    <Box className="m-8" style={{ position: "absolute", top: "50%", left: "50%" }}>
                      <CircularProgress size={60} />
                    </Box>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>

        <RegisterModal open={isMessageModal} msg={message} close={handleClose} />
      </Box>
    </>
  );
};
const mapStateToProps = ({ registrationsPatientKeyReducer }) => {
  return {
    patientInformation: registrationsPatientKeyReducer.patientInformation,
    loading: registrationsPatientKeyReducer.loading,
    error: registrationsPatientKeyReducer.error,
    statusCode: registrationsPatientKeyReducer.statusCode
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getRegistrationsPatientKey: (payload) => {
      dispatch(actionTypes.getRegistrationsPatientKey(payload));
    },
    resetMessage: (payload) => {
      dispatch(actionTypes.resetMessage(payload));
    }
    // getPracticeData: (payload) => {
    //   dispatch(actionTypes.getPracticeData(payload));
    // }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration1);
