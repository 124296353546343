import { Grid, Typography, Divider, InputLabel } from "@mui/material";
import { Stack, Box } from "@mui/system";
import React from "react";
import moment from "moment";

import * as actionType from "../../store/actions";
import { connect, useSelector, useDispatch } from "react-redux";
import { useEffect /* , useState */ } from "react";
import { formatPhoneNumber } from "../../shared/constants/constants";
const InvoiceDetails = (props) => {
  const { data, estimateCards, actions, programFees } = props;
  // const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState({});
  const caseList = useSelector((state) => state.homeReducer.caseStatusList);
  const caseListLoading = useSelector((state) => state.homeReducer.loading);
  const patientId = localStorage.getItem("patientId");
  const practiceId = localStorage.getItem("practiceId");
  const disaptch = useDispatch();
  useEffect(() => {
    if (caseList?.finEstimateId) {
      if (!estimateCards.length) {
        let payload = {
          practiceId,
          estimateId: caseList.finEstimateId
        };
        actions.getEstimateCard(payload);
      }
    } else {
      disaptch(
        actionType.getCaseStatusesData({
          practiceId,
          patientId
        })
      );
    }
  }, [caseListLoading]);
  useEffect(() => {
    // let res = estimateCards.filter((item) => {
    //   return item.optionSelected != null;
    // });
    // setSelectedTreatmentPlan(res[0]);
  }, [estimateCards]);
  return (
    <Box>
      <Stack className="remove-bottom-padding">
        <Typography>Please Review Below invoice</Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          className="p-8">
          <Box>
            <img alt="" src="/Assets/fap.png" className="fap-icon" />
          </Box>
          <Typography variant="body2">
            Invoice Date: {moment(data.invoiceDate).format("ll")}
          </Typography>
        </Stack>
        <Stack className="add-to-practice p-4">
          <Box>
            <Stack my={1}>
              <Typography>Invoice No | {data.invoiceNo}</Typography>
            </Stack>

            <Grid container className="row-gap-8 p-8 financial-directory" direction="row">
              <Grid item xs={6} lg={3}>
                <InputLabel>Patient</InputLabel>
                <Typography>{data.patientName}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>Account #</InputLabel>
                <Typography>{data.personNo}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>Medcase</InputLabel>
                <Typography>#{data.medcaseId}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>Patient Address</InputLabel>
                <Typography>{data.patientAddress}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>Patient Phone</InputLabel>
                <Typography>{formatPhoneNumber(data.patientPhoneNo)}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>Fertility Practice </InputLabel>
                <Typography>{data.practiceName}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>Fertility Location</InputLabel>
                <Typography>{data.locationName}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ background: "#1BA3AD", height: 1, my: 1 }} />
            <Grid container className="row-gap-8 p-8 financial-directory" direction="row">
              <Grid item xs={6} lg={3}>
                <InputLabel>Program Selected</InputLabel>
                <Typography>{data.programName}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>Treatment Selected</InputLabel>
                <Typography>
                  {data?.treatmentPlanCustomName != null
                    ? data?.treatmentPlanCustomName
                    : data?.treatmentName}
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>No of Cycles</InputLabel>
                <Typography>{data.noOfCycles}</Typography>
              </Grid>
              <Grid item xs={6} lg={3}>
                <InputLabel>Program Fees</InputLabel>
                <Typography>
                  ${""}
                  {
                    (programFees && programFees.toLocaleString()) || 0 /*  ||
                    (selectedTreatmentPlan &&
                      selectedTreatmentPlan.tpPrice &&
                      selectedTreatmentPlan.tpPrice.toLocaleString()) */
                  }
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

const mapStateToProps = ({ paybillReducer, estimateReducer }) => {
  return {
    estimateCards: estimateReducer.estimateCards,
    loading: paybillReducer.estimateCardLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    getCaseStatusesData: (payload) => {
      dispatch(actionType.getCaseStatusesData(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
