import React, { useEffect } from "react";
import { Box, Stack, CircularProgress } from "@mui/material";
// import {
//   termsConditions,
//   acknowledge,
//   requirements,
//   risksInclude,
//   securityConfidentiality,
//   usage,
//   discontinuingUse
// } from "../../shared/constants";

import * as actionTypes from "../../store/actions/index";
import { connect } from "react-redux";
const TermsAndConditions = (props) => {
  const { actions, error, success, loading, pageData } = props;
  console.log("==>", error, success, loading);
  useEffect(() => {
    actions.getPagesData();
  }, []);
  console.log("props", props);
  return (
    <Box className="container" sx={{ margin: 3 }}>
      <Stack direction="column" spacing={0.5}>
        {loading ? (
          <Box className="m-8 loader-position">
            <CircularProgress size={60} />
          </Box>
        ) : (
          (pageData && pageData.content && (
            <div
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{ __html: pageData.content }}></div>
          )) || <div>Content Not Available</div>
        )}
        {/* <Typography variant="body2">PATIENT TERMS AND CONDITIONS - May 2017</Typography>
        <Divider className="h-divider" />
        <Typography component="span" className="ml-8">
          {termsConditions.split("\n\n").map((paragraph, key) => (
            <p key={key}>
              {paragraph
                .split("\n")
                .reduce((total, line, index) => [total, <br key={index} />, line])}
            </p>
          ))}
        </Typography>
        <Typography variant="body2" component="span" className="ml-8">
          Your Acknowledgements
        </Typography>
        <Typography component="span" className="ml-8">
          By agreeing to these Terms and conditions, you acknowledge that:
        </Typography>
        {acknowledge.map((item, index) => {
          return (
            <Stack key={index} direction="row" spacing={1} py={1}>
              <Typography>{item.id}.</Typography>
              <Typography>{item.info}</Typography>
            </Stack>
          );
        })}
        <Typography variant="body2" component="span" className="ml-8">
          What You Need to Access the US Fertility Patient Portal
        </Typography>
        <Typography component="span" className="ml-8">
          To access the US Fertility Patient Portal your computer should meet the following
          requirements:
        </Typography>
        {requirements.map((item, index) => {
          return (
            <Stack key={index} direction="column" spacing={1} py={1}>
              <Typography>
                {item.id}. {item.title}
              </Typography>
              <Typography>{item.info}</Typography>
            </Stack>
          );
        })}
        <Typography variant="body2" component="span" className="ml-8">
          You Are Assuming Certain Risks
        </Typography>
        <Typography component="span" className="ml-8">
          By using the US Fertility Patient Portal, you are assuming certain risks that include (but
          are not limited to) the following:
        </Typography>
        <Typography component="span" className="ml-8">
          {risksInclude.split("\n\n").map((paragraph, key) => (
            <p key={key}>
              {key + 1}.{" "}
              {paragraph
                .split("\n\n")
                .reduce((total, line, index) => [total, <br key={index} />, line])}
            </p>
          ))}
        </Typography>
        <Typography variant="body2" component="span" className="ml-8">
          Security and Confidentiality
        </Typography>
        <Typography component="span" className="ml-8">
          The US Fertility Patient Portal has a number of security measures to keep your protected
          health information confidential. In addition, your US Fertility network practice has
          policies and procedures in place to help protect your information. It is critical that you
          also take measures to protect your security.
        </Typography>
        {securityConfidentiality.map((item, index) => {
          return (
            <Stack key={index} direction="column" spacing={1} py={1}>
              <Typography>
                {item.id}. {item.title}
              </Typography>
              <Typography>{item.info}</Typography>
            </Stack>
          );
        })}
        <Typography variant="body2" component="span" className="ml-8">
          Usage
        </Typography>
        <Typography component="span" className="ml-8">
          Your usage of the US Fertility Patient Portal requires that you comply with the following
          guidelines:
        </Typography>
        {usage.map((item, index) => {
          return (
            <Stack key={index} direction="column" spacing={1} py={1}>
              <Typography>
                {item.id}. {item.title}
              </Typography>
              <Typography>{item.info}</Typography>
            </Stack>
          );
        })}
        <Typography component="span" className="ml-8">
          {discontinuingUse.split("\n\n").map((paragraph, key) => (
            <p key={key}>
              {paragraph
                .split("\n")
                .reduce((total, line, index) => [total, <br key={index} />, line])}
            </p>
          ))}
        </Typography> */}
      </Stack>
    </Box>
  );
};

const mapStateToProps = ({ pagesReducer }) => {
  return {
    pageData: pagesReducer.pageData,
    loading: pagesReducer.loading,
    error: pagesReducer.error,
    success: pagesReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPagesData: (payload) => {
      dispatch(actionTypes.getPagesData(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
