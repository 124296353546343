import { combineReducers } from "redux";
import documentReducer from "./documentReducer";
import estimateReducer from "./estimateReducer";
import homeReducer from "./homeReducer";
import messageReducer from "./messageReducer";
import patientPasswordIdReducer from "./patientPasswordIdReducer";
import paybillReducer from "./paybillReducer";
import profileReducer from "./profileReducer";
import registrationsPatientKeyReducer from "./registrationsPatientKeyReducer";
import pagesReducer from "./pagesReducer";
import logsReducer from "./logsReducer";

const rootReducer = combineReducers({
  documentReducer,
  estimateReducer,
  homeReducer,
  messageReducer,
  paybillReducer,
  patientPasswordIdReducer,
  profileReducer,
  registrationsPatientKeyReducer,
  pagesReducer,
  logsReducer
});

export default rootReducer;
