import "./messages.scss";
import { Box, Button, Stack, Typography, IconButton, Grid } from "@mui/material";
import { toUSAFormat } from "../../shared/constants";
import RefreshIcon from "@mui/icons-material/Refresh";
import MessageConfiguration from "./MessageConfiguration";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../../store/actions";

import { useEffect } from "react";

const Messages = (props) => {
  const navigate = useNavigate();
  const composeClickHandler = () => {
    navigate("/messages/compose");
  };
  const location = useLocation();
  const refreshButtonClickHandler = () => {
    navigate(location.pathname);
  };

  const { actions, practiceInfoData } = props;
  const practiceId = localStorage.getItem("practiceId");
  useEffect(() => {
    if (!Object.keys(practiceInfoData).length) {
      let payload = {
        practiceId
      };
      actions.getPracticeInfoData(payload);
    }
  }, [practiceId]);

  return (
    <>
      <Box className="message-header">
        <Stack direction="row" className="flex-between">
          <Typography variant="body1" className="font-24">
            CONTACT US
          </Typography>
          <Stack direction="row" gap={1}>
            <IconButton onClick={refreshButtonClickHandler}>
              <RefreshIcon />
            </IconButton>
            <Button variant="outlined" onClick={composeClickHandler} style={{ display: "none" }}>
              COMPOSE
            </Button>
          </Stack>
        </Stack>
        <Stack direction="column" className="static-text">
          <Typography className="p-4">
            {" "}
            If you are having a medical emergency, please dial 911.
          </Typography>
        </Stack>
        <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "15px" }}>
          <Grid item xs={12} lg={4} style={{ textAlign: "center" }}>
            <Typography className="p-4">
              {"Have a non-emergency question regarding your treatment,"}
            </Typography>
            <Typography className="p-4">
              {"medications, appointment or other clinical need?"}
            </Typography>
            <Typography className="p-4">{"Contact " + practiceInfoData?.name || ""}</Typography>
            <Typography className="p-4">
              {(practiceInfoData &&
                practiceInfoData?.phoneNumber &&
                toUSAFormat(practiceInfoData?.phoneNumber)) ||
                ""}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} style={{ textAlign: "center" }}>
            <Typography className="p-4">
              {"Have a question regarding Fertility Access multi-cycle programs?"}
            </Typography>
            <Typography className="p-4">{"Contact our Patient Concierge team"}</Typography>
            <Typography className="p-4">{"800-873-9203"}</Typography>
            <Typography className="p-4">{"or"}</Typography>
            <Typography className="p-4">
              {"Email patientconcierge@fertilityaccessprogram.com"}
            </Typography>
            {/* <Typography className="p-4">{"or"}</Typography>
            <Typography className="p-4">{"Send a message below"}</Typography> */}
          </Grid>
          <Grid item xs={12} lg={4} style={{ textAlign: "center" }}>
            <Typography className="p-4">
              {"Have a question about your Treatment Estimate?"}
            </Typography>
            <Typography className="p-4">{"Contact your Financial Counselor"}</Typography>
            <Typography className="p-4">
              {(practiceInfoData &&
                practiceInfoData?.phoneNumber &&
                toUSAFormat(practiceInfoData?.phoneNumber)) ||
                ""}
            </Typography>
            {/* <Typography className="p-4">{"or"}</Typography>
            <Typography className="p-4">{"Send a message below"}</Typography> */}
          </Grid>
        </Grid>
        {/* <Stack direction="column" className="static-text">
          <Typography className="p-4"> For medical emergencies, please dial 911.</Typography>
          <Typography className="p-4">
            Have a non-emergency question regarding your treatment, medications, appointment or
            other clinical need?
          </Typography>
          <Typography>Contact:</Typography>
          <Typography>{practiceInfoData?.name}</Typography>
          <Typography>{practiceInfoData?.phoneNumber}</Typography>
          <Typography className="p-4" style={{ marginTop: 8 }}>
            Have a question regarding your financial estimate?
          </Typography>
          <Typography className="p-4">
            Contact your Financial Counselor at {practiceInfoData?.phoneNumber}
          </Typography>
          <Typography className="p-4">or send a message below.</Typography>
          <Typography className="p-4" style={{ marginTop: 8 }}>
            Have a questions regarding the Fertility Access program?
          </Typography>
          <Typography className="p-4">
            Contact the Fertility Access Patient Concierge team at 800-873-9203
          </Typography>
          <Typography className="p-4">
            or email us at patientconcierge@fertilityaccessprogram.com
          </Typography>
          <Typography className="p-4">or send us a message below.</Typography>
          <Typography className="p-4" style={{ marginTop: 8 }}>
            Messaging in this portal should be used for financial matters only. Messages are
            monitored regularly and will be responded to within 2 business days.{" "}
          </Typography>
        </Stack> */}
      </Box>
      <Grid container>
        <Grid item xs={12} lg={1.6} sx={{ background: "#818294" }}>
          <MessageConfiguration />
        </Grid>
        <Grid item xs={12} lg={10.4}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ profileReducer }) => {
  return {
    practiceInfoData: profileReducer.practiceInfoData,
    loading: profileReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPracticeInfoData: (payload) => {
      dispatch(actionType.getPracticeInfoData(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
