import * as actionType from "../actions/ActionTypes";
import { put, call, takeEvery, all } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* getDocumentsSaga(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const url = `/patients/${payload.patientId}/documents`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.DOCUMENT_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.DOCUMENT_LIST_FAILED,
      message: error.message
    });
  }
}

function* fetchDocumentsList() {
  yield takeEvery(actionType.DOCUMENT_LIST_REQUESTED, getDocumentsSaga);
}

function* getContractDocumentSaga(action) {
  const { payload } = action;
  const { contractId } = payload;

  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const url = `/contract/${contractId}/documentUrl`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.CONTRACT_DOCUMENT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.CONTRACT_DOCUMENT_FAILED,
      message: error.response.data
    });
  }
}

function* fetchContractDocumentSaga() {
  yield takeEvery(actionType.CONTRACT_DOCUMENT_REQUESTED, getContractDocumentSaga);
}

export default function* documentSaga() {
  yield all([fetchDocumentsList(), fetchContractDocumentSaga()]);
}
