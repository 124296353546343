import { ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { PropTypes } from "prop-types";
import { forwardRef, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";

const ListItemLink = (props) => {
  const { icon, primary, onClick, selected, to, ListOfMessages } = props;

  const renderLink = useMemo(
    () =>
      forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem
      selected={selected}
      button
      component={renderLink}
      disablePadding
      sx={{
        minHeight: "70px"
      }}
      onClick={onClick}>
      <Stack direction="row" ml={2}>
        {icon ? <ListItemIcon sx={{ ml: 2, minWidth: 0 }}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
        {primary == "INBOX" && ListOfMessages ? (
          <Typography sx={{ mt: 0.4 }}>({ListOfMessages.unreadMessageCount})</Typography>
        ) : (
          ""
        )}
      </Stack>
    </ListItem>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onclick: PropTypes.func,
  selected: PropTypes.bool.isRequired
};

const mapStateToProps = ({ messageReducer }) => {
  return {
    ListOfMessages: messageReducer.ListOfMessages
  };
};
export default connect(mapStateToProps)(ListItemLink);
