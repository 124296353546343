import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  Stepper,
  StepLabel,
  Step,
  Button,
  Card
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import * as actionType from "../../store/actions";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import "./home.scss";
import { tasks } from "../../shared/constants";

const HomeCards = (props) => {
  const { actions, activeStep } = props;
  const ListOfMessages = useSelector((state) => state.messageReducer.ListOfMessages);
  const [messageList, setMessageList] = useState([]);
  const [msgLength, setMsgLength] = useState(0);
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    if (!ListOfMessages && userId) {
      actions.getMessagesList({ userId, messagesStatus: "Inbox" });
    }
  }, [userId]);

  useEffect(() => {
    if (ListOfMessages) {
      let msgList = ListOfMessages.messageList
        .slice(0, 10)
        .sort(
          (first, second) => first.messageRecipient[0].isRead - second.messageRecipient[0].isRead
        );
      setMessageList(msgList);
      setMsgLength(msgList.length);
    }
  }, [ListOfMessages]);

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Card className="border-style">
            <Box className="card-style scrollbar" style={{ height: 400 }}>
              <Stack direction="row" className="mb-10 flex-between">
                <Typography variant="body2"> Messages ({msgLength})</Typography>
                <Link to="/messages/inbox">
                  <Button variant="outlined" size="small">
                    Compose
                  </Button>
                </Link>
              </Stack>
              <Divider className="divider-style" />
              <Stack
                direction="column"
                className="mt-8 "
                divider={<Divider orientation="horizontal" />}>
                {ListOfMessages?.messageList.length > 0 ? (
                  messageList.map((item, index) => {
                    return (
                      <Stack key={index} direction="row" className="msg-row flex-between" p={1}>
                        <Stack direction="row" spacing={1} className="align-center">
                          <CircleIcon
                            className={
                              item.messageRecipient[0].isRead == true ? "color-blue" : "color-pink"
                            }
                            sx={{ fontSize: "10px" }}
                          />
                          <span>
                            <Typography>{item.type}</Typography>
                            <Typography>{item.subject}</Typography>
                          </span>
                        </Stack>
                        <Typography>
                          {item.sentDate && moment(new Date(item.sentDate)).format("MMMM D")}
                        </Typography>
                      </Stack>
                    );
                  })
                ) : (
                  <Stack alignItems="center" className="pad-40">
                    <Typography>No messages found</Typography>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card className="card-style border-style" style={{ height: 400 }}>
            <Stack spacing={2} pt={1}>
              <Typography variant="body2">My Tasks</Typography>
              <Divider className="divider-style" />
            </Stack>
            <Stack direction="column" className="mt-24">
              <Stepper orientation="vertical" activeStep={activeStep}>
                {tasks.map((item, index) => {
                  let status = index < activeStep ? "completed" : "pending";
                  return (
                    <Step index={index} key={index}>
                      <StepLabel sx={{ marginTop: -1, marginBottom: -1 }}>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography>{item.label}</Typography>
                          <Typography
                            style={{
                              color: status == "completed" ? "green" : "orange"
                            }}>
                            {status}
                          </Typography>
                        </Stack>
                        {index != 4 && <Divider sx={{ mt: 2, mb: -2 }} />}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
const mapStateToProps = ({ messageReducer }) => {
  return {
    ListOfMessages: messageReducer.ListOfMessages
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMessagesList: (payload) => {
      dispatch(actionType.getMessagesList(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(HomeCards);
