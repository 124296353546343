import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import { footerItems, footerTerms } from "../../shared/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as actionType from "../../store/actions";
import { connect } from "react-redux";

import "./Footer.css";

const Footer = (props) => {
  const { actions, practiceData, patientInformation = null } = props;
  const location = useLocation();
  const practiceId = localStorage.getItem("practiceId");

  useEffect(() => {
    let appRouteName = ["/initial-registration", "/password-registration"];
    let pageAccess = appRouteName.includes(location.pathname);

    if (practiceId && pageAccess === false) {
      actions.getPracticeData({ practiceId });
    }
  }, []);

  const isBigScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return (
    <footer className="footer">
      <Grid container spacing={2} justifyContent="space-between" style={{ padding: "8px" }}>
        <Grid item xs={12} sm={6} style={{ textAlign: isBigScreen ? "left" : "center" }}>
          {practiceData?.name || patientInformation?.practiceName ? (
            <Typography variant="caption" className="footer-links">
              {footerItems.map((item, index) => (
                <Link key={index} to={item.link} style={{ padding: 2 }}>
                  {index === 0 ? item.name : ` | ${item.name}`}
                </Link>
              ))}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: isBigScreen ? "right" : "center" }}>
          <Typography variant="caption" className="footer-links">
            {footerTerms.map((item, index) => (
              <Link key={index} to={item.link}>
                {index === 0 ? item.name : ` | ${item.name}`}
              </Link>
            ))}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: isBigScreen ? "left" : "center" }}>
          <Typography variant="caption" className="footer-links">
            ©{" "}
            {practiceData?.longName
              ? `${practiceData.longName} & `
              : patientInformation?.practiceLongName
              ? `${patientInformation.practiceLongName} & `
              : null}
            Fertility Access All Rights Reserved
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: isBigScreen ? "right" : "center" }}>
          <Typography variant="caption" className="footer-links">
            Powered by Next Gen Clarity
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

const mapStateToProps = ({ homeReducer, profileReducer, registrationsPatientKeyReducer }) => {
  return {
    caseStatusList: homeReducer.caseStatusList,
    loading: homeReducer.loading,
    error: homeReducer.error,
    practiceData: homeReducer.practiceData,
    practiceDataLoading: homeReducer.practiceDataLoading,
    profileData: profileReducer.profileData,
    personData: homeReducer.personData,
    userData: homeReducer.userList,
    patientInformation: registrationsPatientKeyReducer.patientInformation
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getCaseStatusesData: (payload) => {
      dispatch(actionType.getCaseStatusesData(payload));
    },
    getPracticeData: (payload) => {
      dispatch(actionType.getPracticeData(payload));
    },
    getProfileData: (payload) => {
      dispatch(actionType.getProfileData(payload));
    },
    getPersonData: (payload) => {
      dispatch(actionType.getPersonData(payload));
    },
    resetEstimateCards: () => {
      dispatch(actionType.resetEstimateCards());
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    },
    getUserList: (payload) => {
      dispatch(actionType.getUserList(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
