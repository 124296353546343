import * as actionType from "../actions/ActionTypes";
import { put, call, takeEvery, all } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* createPassword(action) {
  const { payload } = action;
  const PatientRegistrationRequest = {
    personKey: payload.personKey,
    password: payload.password
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/registrations/patients`,
      "",
      PatientRegistrationRequest
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.PATIENT_PASSWORD_ID_SUCCESS,
      payload: response.data
    });
  } catch (err) {
    yield put({
      type: actionType.PATIENT_PASSWORD_ID_FAILED,
      errorMsg: err.response.data
    });
  }
}

function* getPatientPasswordIdSaga() {
  yield takeEvery(actionType.PATIENT_PASSWORD_ID_REQUESTED, createPassword);
}

export default function* patientPasswordIdSaga() {
  yield all([getPatientPasswordIdSaga()]);
}
