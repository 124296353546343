import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import { Menu, MenuItem, Stack, Grid, Typography, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import UserProfileIcon from "@mui/icons-material/AccountCircle";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as actionType from "../../store/actions";
import { connect } from "react-redux";

const Header = (props) => {
  const { actions, practiceData, patientInformation = null } = props;
  const location = useLocation();

  const navigate = useNavigate();
  const navItems = [
    {
      name: "Home",
      link: "/home"
    },
    {
      name: "Treatment Estimate",
      link: "/treatment-estimate"
    },
    {
      name: "Document Management",
      link: "/document-management"
    },

    {
      name: "Pay Your Bill",
      link: "/pay-your-bill"
    },
    {
      name: "Contact Us",
      link: "/messages/inbox"
    },
    {
      name: "Clinical Portal",
      link: "/clinical-portal"
    }
  ];

  const practiceId = localStorage.getItem("practiceId");

  useEffect(() => {
    let appRouteName = ["/initial-registration", "/password-registration"];
    let pageAccess = appRouteName.includes(location.pathname);

    if (practiceId && pageAccess === false) {
      actions.getPracticeData({ practiceId });
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/logout");
  };

  const handleProfile = () => {
    setAnchorEl(null);
    navigate("/profile/info");
  };

  const renderNavLinks = () => {
    return navItems.map((item, index) => (
      <MenuItem key={index} onClick={handleClose}>
        <Link to={item.link} style={{ textDecoration: "none", color: "inherit" }}>
          {item.name}
        </Link>
      </MenuItem>
    ));
  };

  return (
    <Grid container item xs={12} className="nav-global">
      <Grid item xs={6}>
        {patientInformation?.practiceLogo || practiceData?.practiceLogo ? (
          <Link to="/home">
            <img
              src={`data:image/svg+xml;base64,${
                patientInformation?.practiceLogo || practiceData?.practiceLogo
              }`}
              alt="Felix"
              height={50}
            />
          </Link>
        ) : null}
      </Grid>
      <Grid item xs={6}>
        {isSmallScreen ? (
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={handleMenu} color="inherit">
              <MenuIcon style={{ color: "white" }} />
            </IconButton>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-end">
            {navItems.map((item, index) => {
              let active = "";
              console.log("location.pathname:", location.pathname);
              console.log("item.name:", item.name);
              if (location.pathname.replaceAll("-", " ").indexOf(item.name.toLowerCase()) !== -1) {
                active = "active";
              }
              return (
                <Stack direction="column" key={index} className={"nav-item " + active}>
                  <Link
                    to={item.link}
                    className={index === 3 ? "center width-60" : "width-min-content"}>
                    <CircleOutlinedIcon fontSize="small" />
                    <Typography align="center">{item.name}</Typography>
                  </Link>
                </Stack>
              );
            })}
            <Stack direction="column">
              <Link
                className={`nav-item ${
                  location.pathname.includes("/profile/info") ? "active" : ""
                }`}
                onClick={handleMenu}
                to="#">
                <UserProfileIcon fontSize="small" />
                <Typography>Profile</Typography>
              </Link>
            </Stack>
          </Stack>
        )}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {isSmallScreen ? renderNavLinks() : null}
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = ({ homeReducer, profileReducer, registrationsPatientKeyReducer }) => {
  return {
    caseStatusList: homeReducer.caseStatusList,
    loading: homeReducer.loading,
    error: homeReducer.error,
    practiceData: homeReducer.practiceData,
    practiceDataLoading: homeReducer.practiceDataLoading,
    profileData: profileReducer.profileData,
    personData: homeReducer.personData,
    userData: homeReducer.userList,
    patientInformation: registrationsPatientKeyReducer.patientInformation
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getCaseStatusesData: (payload) => {
      dispatch(actionType.getCaseStatusesData(payload));
    },
    getPracticeData: (payload) => {
      dispatch(actionType.getPracticeData(payload));
    },
    getProfileData: (payload) => {
      dispatch(actionType.getProfileData(payload));
    },
    getPersonData: (payload) => {
      dispatch(actionType.getPersonData(payload));
    },
    resetEstimateCards: () => {
      dispatch(actionType.resetEstimateCards());
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    },
    getUserList: (payload) => {
      dispatch(actionType.getUserList(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
