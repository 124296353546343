import * as actionType from "../actions/ActionTypes";
import { put, call, takeEvery, all } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { pathComponent, getURLEndpoint } from "../../shared/constants/constants";
import { parseTemplate } from "url-template";
import { parseParametersToObject } from "../../shared/components/sigv4Utility";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* getCaseStatusesSaga(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/patients/${payload.patientId}/caseStatuses`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.CASE_STATUSES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.CASE_STATUSES_FAILED,
      message: error.message
    });
  }
}

function* fetchCaseStatuses() {
  yield takeEvery(actionType.CASE_STATUSES_REQUESTED, getCaseStatusesSaga);
}

function* getPracticeDataSaga(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/${payload.practiceId}`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.PRACTICE_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.PRACTICE_DATA_FAILED,
      message: error.message
    });
  }
}

function* fetchPracticeData() {
  yield takeEvery(actionType.PRACTICE_DATA_REQUESTED, getPracticeDataSaga);
}

function* getPersonDataSaga(action) {
  const { payload } = action;
  const { email } = payload;

  try {
    const params = {
      email: email
    };

    const parsedPath =
      pathComponent(apis.getUri()) +
      parseTemplate(`/person/email{/email*}`).expand(parseParametersToObject(params, ["email"]));

    const requestBody = {
      method: "get".toUpperCase(), //should be always upper case
      path: parsedPath,
      headers: parseParametersToObject(params, []),
      queryParams: parseParametersToObject(params, []),
      endpoint: getURLEndpoint(apis.getUri())
    };
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.PERSON_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    let errMessage = (error && error.response && error.response.data) || error.message;
    yield put({
      type: actionType.PERSON_DATA_FAILED,
      message: errMessage
    });
  }
}

function* fetchPersonData() {
  yield takeEvery(actionType.PERSON_DATA_REQUESTED, getPersonDataSaga);
}

// function* getUserListSaga(action) {
//   const { payload } = action;
//   const { email, practiceId, primaryRole } = payload;
//   try {
//     const response = yield call(() =>
//       apis.get(`/users?email=${email}&practiceId=${practiceId}&primaryRole=${primaryRole}`, {
//         method: "GET",
//         params: {
//           offset: "",
//           limit: "10000"
//         },
//         headers: {
//           "Content-type": "application/json",
//           Authorization: token.token
//         }
//       })
//     );
//     yield put({
//       type: actionType.USER_LIST_SUCCESS,
//       payload: response.data
//     });
//   } catch (error) {
//     yield put({
//       type: actionType.USER_LIST_FAILED,
//       message: error.message
//     });
//   }
// }

function* getUserListSaga(action) {
  const { payload } = action;
  const { email, practiceId, primaryRole } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      primaryRole: primaryRole,
      email: email,
      practiceId: practiceId
    };
    const requestBody = createRequestBody("get", `/users`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.USER_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.USER_LIST_FAILED,
      message: error.message
    });
  }
}

function* fetchUserList() {
  yield takeEvery(actionType.USER_LIST_REQUESTED, getUserListSaga);
}
export default function* homeSaga() {
  yield all([fetchCaseStatuses(), fetchPracticeData(), fetchPersonData(), fetchUserList()]);
}
