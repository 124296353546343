import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export default function RegisteredPatientDetails(props) {
  return (
    <>
      <Stack>
        <Typography sx={{ fontSize: "18px", marginBottom: "15px" }}>
          FINANCIAL PORTAL REGISTRATION
        </Typography>
        <Typography className="m-t-20">This is the information we found:</Typography>
      </Stack>
      <Box className="box m-t-20 m-b-20 m-r-500">
        <Stack className="m-10" gap={2}>
          <Stack direction="row" className="m-5">
            <Typography>Name: &nbsp;&nbsp;</Typography>
            <Typography>{props.name}</Typography>
          </Stack>
          <Stack direction="row" className="m-5">
            <Typography>Patient Number:&nbsp;&nbsp;</Typography>
            <Typography>{props.pNumber}</Typography>
          </Stack>
          <Stack direction="row" className="m-5">
            <Typography>Date of Birth:&nbsp;&nbsp;</Typography>
            <Typography>{props.dob}</Typography>
          </Stack>
          <Stack direction="row" className="m-5">
            <Typography>Email Address:&nbsp;&nbsp;</Typography>
            <Typography>{props.email}</Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
