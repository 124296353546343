import React from "react";
import { Route, Routes } from "react-router-dom";
import DocumentManagement from "../components/documents";
import EstimateTreatment from "../components/estimateTreatment";
import PayBill from "../components/payBill";
import Messages from "../components/messages";
import ClinicalPortal from "../components/clinicalPortal";
import Header from "../shared/components/Header";
import Footer from "..//shared/components/Footer";
import Home from "../components/home";
import Inbox from "../components/messages/Inbox";
import Draft from "../components/messages/Draft";
import Sent from "../components/messages/Sent";
import Deleted from "../components/messages/Deleted";
import Profile from "../components/profile";
import Reply from "../components/messages/Reply";
import ComposeMessage from "../components/messages/ComposeMessage";
import PatientInformation from "../components/profile/PatientInformation";
import PracticeInfo from "../components/profile/PracticeInfo";
import ConfirmSelection from "../components/estimateTreatment/ConfirmSelection";
import InitialRegistration from "../components/registration/InitialRegistration";
import RegisteredPatientConfirmation from "../components/registration/RegisteredPatientConfirmation";
// import Login from "../components/login/Login";
import PrivacyPolicy from "../shared/components/PrivacyPolicy";
import TermsAndConditions from "../shared/components/TermsAndConditions";
import LearnMore from "../components/payBill/LearnMore";
import ProtectedRoute from "./ProtectedRoute";
import Logout from "../components/login/Logout";
import useMediaQuery from "@mui/material/useMediaQuery";

const AppRoute = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
      <Header />
      <Routes>
        <Route
          exact
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
          key={0}
        />
        <Route
          path="/treatment-estimate"
          element={
            <ProtectedRoute>
              <EstimateTreatment />
            </ProtectedRoute>
          }
          key={1}
        />
        <Route
          exact
          path="/document-management"
          element={
            <ProtectedRoute>
              <DocumentManagement />
            </ProtectedRoute>
          }
          key={2}
        />
        <Route
          path="/treatment-estimate/confirm"
          element={
            <ProtectedRoute>
              <ConfirmSelection />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/pay-your-bill"
          element={
            <ProtectedRoute>
              <PayBill />
            </ProtectedRoute>
          }
          key={3}
        />
        <Route
          exact
          path="/clinical-portal"
          element={
            <ProtectedRoute>
              <ClinicalPortal />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/messages"
          element={
            <ProtectedRoute>
              <Messages />
            </ProtectedRoute>
          }
          key={4}>
          <Route
            path="/messages/inbox"
            element={
              <ProtectedRoute>
                <Inbox />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages/drafts"
            element={
              <ProtectedRoute>
                <Draft />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages/sent"
            element={
              <ProtectedRoute>
                <Sent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages/deleted"
            element={
              <ProtectedRoute>
                <Deleted />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/messages/:messageId/reply"
          element={
            <ProtectedRoute>
              <Reply />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messages/compose"
          element={
            <ProtectedRoute>
              <ComposeMessage />
            </ProtectedRoute>
          }
        />

        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
          key={6}
        />
        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
          key={6}>
          <Route
            exact
            path="/profile/info"
            element={
              <ProtectedRoute>
                <PatientInformation />
              </ProtectedRoute>
            }
            key={6}
          />
          <Route
            exact
            path="/profile/timings"
            element={
              <ProtectedRoute>
                <PracticeInfo />
              </ProtectedRoute>
            }
            key={6}
          />
        </Route>
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/initial-registration" element={<InitialRegistration />} />
        <Route exact path="/password-registration" element={<RegisteredPatientConfirmation />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/notice-and-practices" element={<PrivacyPolicy />} />
        <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route exact path="/learn-more" element={<LearnMore />} />
      </Routes>
      <div style={{ marginTop: isSmallScreen ? "160px" : "" }}>
        <Footer />
      </div>
    </>
  );
};
export default AppRoute;
