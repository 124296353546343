import * as actionType from "../actions/ActionTypes";
import { put, call, takeEvery, all } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* getInvoiceDetails(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/patients/${payload.patientId}/invoices`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.INVOICE_DETAILS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.INVOICE_DETAILS_FAILED,
      message: error.message
    });
  }
}
function* fetchInvoiceDetails() {
  yield takeEvery(actionType.INVOICE_DETAILS_REQUESTED, getInvoiceDetails);
}
function* getEstimateCard(action) {
  const { payload } = action;
  const { practiceId, estimateId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/estimates/${estimateId}/treatment-plans`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.ESTIMATE_CARDS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.ESTIMATE_CARDS_FAILED,
      message: error.message
    });
  }
}

function* getEstimateCardSaga() {
  yield takeEvery(actionType.ESTIMATE_CARDS_REQUESTED, getEstimateCard);
}

function* getStages(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000",
      category: "Included"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.GET_STAGES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.GET_STAGES_FAILED,
      message: error.message
    });
  }
}

function* getStagesSaga() {
  yield takeEvery(actionType.GET_STAGES_REQUESTED, getStages);
}

//adjustment list
function* fetchAdjustment(action) {
  const { payload } = action;
  const { estimateId, estimateTpId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/adjustments`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.ADJUSTMENT_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionType.ADJUSTMENT_LIST_FAILED, message: error.message });
  }
}

function* fetchAdjustmentSaga() {
  yield takeEvery(actionType.ADJUSTMENT_LIST_REQUESTED, fetchAdjustment);
}

//get optional
function* fetchOptional(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000",
      category: "Optional"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages-service-items`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.OPTIONAL_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionType.OPTIONAL_LIST_FAILED, message: error.message });
  }
}

function* fetchOptionalSaga() {
  yield takeEvery(actionType.OPTIONAL_LIST_REQUESTED, fetchOptional);
}
//get necessary
function* fetchNecessary(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000",
      category: "Necessary"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages-service-items`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.NECESSARY_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionType.NECESSARY_LIST_FAILED, message: error.message });
  }
}

function* fetchNecessarySaga() {
  yield takeEvery(actionType.NECESSARY_LIST_REQUESTED, fetchNecessary);
}

export default function* paybillSaga() {
  yield all([
    fetchInvoiceDetails(),
    getEstimateCardSaga(),
    getStagesSaga(),
    fetchAdjustmentSaga(),
    fetchOptionalSaga(),
    fetchNecessarySaga()
  ]);
}
