import { fork } from "redux-saga/effects";
import paybillSaga from "./paybillSaga";
import homeSaga from "./homeSaga";
import estimateSaga from "./estimateSaga";
import documentSaga from "./documentSaga";
import messagesSaga from "./messageSaga";
import patientPasswordIdSaga from "./patientPasswordIdSaga";
import profileSaga from "./profileSaga";
import registrationsPatientKeySaga from "./registrationsPatientKeySaga";
import pagesSaga from "./pagesSaga";
import logsSaga from "./logsSaga";

export default function* rootSaga() {
  yield fork(documentSaga);
  yield fork(estimateSaga);
  yield fork(homeSaga);
  yield fork(messagesSaga);
  yield fork(paybillSaga);
  yield fork(profileSaga);
  yield fork(patientPasswordIdSaga);
  yield fork(registrationsPatientKeySaga);
  yield fork(pagesSaga);
  yield fork(logsSaga);
}
