import React from "react";
import { Navigate } from "react-router-dom";
function ProtectedRoute({ children }) {
  const isSignedIn = localStorage.getItem("tokenId") ? true : false;
  if (!isSignedIn) {
    return <Navigate to="/" replace />;
  }
  return children;
}
export default ProtectedRoute;
