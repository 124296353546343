import * as actionType from "../actions/ActionTypes";

const initialState = {
  patientInformation: [],
  loading: false,
  error: null,
  statusCode: null
};

const registrationsPatientKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REGISTRATIONS_PATIENT_KEY_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.REGISTRATIONS_PATIENT_KEY_SUCCESS: {
      return {
        ...state,
        patientInformation: action.payload,
        loading: false,
        statusCode: action.statusCode
      };
    }

    case actionType.REGISTRATIONS_PATIENT_KEY_FAILED: {
      return {
        ...state,
        error: action.error,
        loading: false,
        success: false,
        statusCode: action.statusCode
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default registrationsPatientKeyReducer;
