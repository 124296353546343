import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const MessageModal = ({ open, message, error, closeModal }) => {
  return (
    <Modal open={open} backdrop="static" aria-labelledby="modal-add-title">
      <Box className="alert-modal">
        <Stack direction="row" className="modal-stack">
          <Typography>Alert</Typography>
          <IconButton onClick={closeModal}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
          {message ? (
            <Typography variant="h5" color="#1BA3AD">
              {message}
            </Typography>
          ) : null}
          {error ? (
            <Typography variant="h5" color="#A6315C">
              {error}
            </Typography>
          ) : null}
        </Stack>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
          <Button variant="contained" className="btn-primary" onClick={closeModal}>
            OK
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default MessageModal;
