import * as actionType from "../actions/ActionTypes";

const initialState = {
  impersonateLog: "",
  loading: false,
  error: null,
  success: false
};

const logsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADD_IMPERSONATE_LOGS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_IMPERSONATE_LOGS_SUCCESS: {
      return {
        ...state,
        impersonateLog: action.payload,
        success: action.success,
        loading: false
      };
    }
    case actionType.ADD_IMPERSONATE_LOGS_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default logsReducer;
