import { Box, Typography, Grid, Divider, Stack, Button, CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DoubleArrowOutlinedIcon from "@mui/icons-material/DoubleArrowOutlined";
import "./home.scss";
import { goalTxt, secureMsgTxt, treatmentTxt, welcomeTxt, goalTxt1 } from "../../shared/constants";

const HomeModal = (props) => {
  const { open, close, practiceData, loading } = props;
  const [openPracticeModal, setOpenPracticeModal] = useState(false);

  const handleNavigate = () => {
    setOpenPracticeModal(true);
  };

  const handleClose = () => {
    close();
  };

  return (
    <Fragment>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static">
        <Box className="modal-style">
          <Stack direction="column" spacing={1}>
            <Typography>WELCOME</Typography>
            <Divider className="divider-style" orientation="horizontal" />
          </Stack>
          {!openPracticeModal ? (
            <Stack spacing={2} py={2}>
              <Typography>{welcomeTxt}</Typography>
              <Typography>{goalTxt}</Typography>
              <Stack direction="row" spacing={3}>
                <Stack alignItems="center" spacing={1}>
                  <Link to="/messages/inbox" className="center primary-one">
                    <MessageOutlinedIcon color="primary" fontSize="large" />{" "}
                    <Typography>Secure Messaging</Typography>
                  </Link>
                  <Typography pt={1}>{secureMsgTxt}</Typography>
                </Stack>
                <Stack alignItems="center" spacing={1}>
                  <Link to="/treatment-estimate" className="center primary-one">
                    <CalendarTodayOutlinedIcon color="primary" fontSize="large" />{" "}
                    <Typography>Treatment Estimate</Typography>
                  </Link>
                  <Typography pt={1}>{treatmentTxt}</Typography>
                </Stack>
              </Stack>
              <Stack direction="column" alignItems="flex-end" pt={1}>
                <Button>
                  <DoubleArrowOutlinedIcon
                    color="primary"
                    fontSize="large"
                    onClick={handleNavigate}
                  />
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Box pt={1}>
              {loading ? (
                <Stack className="align-center" p={18}>
                  <CircularProgress size={50} />
                </Stack>
              ) : (
                <Grid container>
                  <Grid item xs={12} className="bg-image" sx={{ height: "200px" }}></Grid>
                  <Grid item xs={12} py={2}>
                    <Typography variant="h5">
                      WELCOME TO {practiceData.name?.toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>{welcomeTxt}</Typography>
                    <Typography py={2}>{goalTxt1}</Typography>
                  </Grid>
                  <Grid item xs={5} pl={3}>
                    <Typography py={2}>CONTACT INFORMATION</Typography>
                    <Typography>{practiceData.name?.toUpperCase()}</Typography>
                    <Stack direction="row" spacing={2} py={2} alignItems="center">
                      <LanguageIcon color="primary" />
                      <Typography>
                        <Link
                          to={{ pathname: "https://" + practiceData?.website }}
                          target="_blank"
                          className="primary-one">
                          {practiceData.website}
                        </Link>
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <PhoneOutlinedIcon color="primary" />
                      <Typography>
                        <Link
                          to={"tel://" + practiceData.phoneNumber}
                          target="_blank"
                          className="primary-one">
                          {practiceData.phoneNumber}
                        </Link>
                      </Typography>
                    </Stack>
                    <Stack alignItems="flex-end" pt={1}>
                      <Button variant="outlined" onClick={handleClose}>
                        Continue
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </Fragment>
  );
};

export default HomeModal;
