import { Box, Button, Grid, Stack, TextField, Typography, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Checkbox from "@mui/material/Checkbox";
import RegisterModal from "../modals/RegisterModal";
import * as actionTypes from "../../store/actions/index";
import { connect } from "react-redux";
import {
  AcceptAndRegister,
  registerSuccessMsg,
  registerErrorMsg
  // continueReg
  /* usernameText */
} from "../../shared/constants/constants";
import { useNavigate } from "react-router-dom";
// import { PATH_COGNITO_SIGNIN } from "../../shared/constants";

const PasswordRegistration = (props) => {
  const { email, personKey, name, actions, error, success, loading } = props;

  const [formDetails, setFormDetails] = useState({
    username: email,
    password: null,
    confirmPassword: null,
    showPassword: "password"
  });
  const [modalMessage, setModalMessage] = useState(registerSuccessMsg);
  const [validLength, setValidLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [threeOfFour, setThreeOfFour] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    // let path = PATH_COGNITO_SIGNIN;
    // navigate(path);
    navigate("/", { replace: true });
    //window.location.reload();
  };

  const handleShowPassword = () => {
    if (formDetails.showPassword == "password") {
      setFormDetails({ ...formDetails, showPassword: "text" });
    } else {
      setFormDetails({ ...formDetails, showPassword: "password" });
    }
  };

  const handleInputChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      handlePasswordChange(e);
    }
  };
  useEffect(() => {
    const arr = [hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar];
    let countCondition = arr.filter((value) => value).length;
    setThreeOfFour(countCondition >= 3 ? true : false);
    handleFormValidation();
  }, [formDetails, hasUpperCase]);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setValidLength(value.length >= 8);
    setHasUpperCase(/[A-Z]/.test(value));
    setHasLowerCase(/[a-z]/.test(value));
    setHasNumber(/\d/.test(value));
    setHasSpecialChar(/[!@#$%^&*]/.test(value));
  };

  const handleButtonClick = (e) => {
    setEnable(false);
    e.preventDefault();
    actions.postPatientPasswordId({ personKey: personKey, password: formDetails.password });
  };

  useEffect(() => {
    if (
      formDetails.password !== null &&
      formDetails.username !== null &&
      formDetails.password === formDetails.confirmPassword
    ) {
      setOpen(true);
    }
  }, [success, error]);
  useEffect(() => {
    if (error) {
      setModalMessage(registerErrorMsg);
    } else if (success) {
      setModalMessage(registerSuccessMsg);
    }
  }, [open]);
  const handleFormValidation = () => {
    if (formDetails.password === null) {
      setEnable(false);
    } else if (formDetails.confirmPassword !== formDetails.password) {
      setEnable(false);
      setMsg("Passwords do not match");
    } else {
      setMsg("");
      validLength && threeOfFour ? setEnable(true) : setEnable(false);
    }
  };

  return (
    <Box className={`m-25 ${loading ? "disable-section" : ""}`}>
      <Grid container spacing={3}>
        <Grid item>
          <Stack>
            <Typography>Welcome to your Financial Patient Portal, {name},</Typography>
            {/* <Typography className="m-8">{continueReg}</Typography> */}
          </Stack>
          <Stack className="m-t-16">
            <Typography>
              The Username to log into your Financial Portal will be your email address. To complete
              your registration, please create a password.
            </Typography>
            {/* <Typography sx={{ color: "grey" }}>{usernameText}</Typography> */}
          </Stack>
          <Stack className="m-t-16">
            <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid xs={12} className="left m-r-8">
                <Grid item xs={12}>
                  <TextField
                    sx={{ width: "100%", marginBottom: "8px" }}
                    name="username"
                    value={formDetails.username}
                    placeholder="User ID"
                    onChange={handleInputChange}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} className="mt-8 mb-10 input-lables">
                  <Typography>Set your password</Typography>
                  <item>
                    <Typography>
                      <Checkbox
                        sx={{ height: "8px", color: "lightgray" }}
                        checked={formDetails.showPassword == "text" ? "checked" : ""}
                        onChange={handleShowPassword}
                      />
                      Show Password
                    </Typography>
                  </item>
                </Grid>
                <Grid item xs={12} className="textfield-container">
                  <TextField
                    className="textfield-style"
                    sx={{ marginBottom: "8px", marginTop: "8px" }}
                    name="password"
                    value={formDetails.password}
                    type={formDetails.showPassword}
                    onChange={handleInputChange}
                    placeholder="Enter your password"
                  />
                </Grid>
                <Grid item xs={12} className="mt-8 input-lables">
                  <Typography>Confirm your password</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "8px" }}>
                  <TextField
                    className="textfield-style"
                    name="confirmPassword"
                    value={formDetails.confirmPassword}
                    type={formDetails.showPassword}
                    onChange={handleInputChange}
                    placeholder="Confirm your password"
                  />
                </Grid>
                <Grid item xs={12} className="password-match-text">
                  {msg}
                </Grid>
              </Grid>
              <Grid item xs={12} className="right">
                <Grid item>Password Policy</Grid>
                <Grid item>
                  <Typography>
                    {validLength ? (
                      <DoneIcon className="done-icon" />
                    ) : (
                      <CloseIcon className="close-icon" />
                    )}
                    At least 8 characters in length
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {threeOfFour ? (
                      <DoneIcon className="done-icon" />
                    ) : (
                      <CloseIcon className="close-icon" />
                    )}
                    Contains at least 3 of the 4 following characters
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {hasLowerCase ? (
                      <DoneIcon className="done-icon" />
                    ) : (
                      <CloseIcon className="close-icon" />
                    )}
                    Lower case letters (a-z)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {hasUpperCase === true ? (
                      <DoneIcon className="done-icon" />
                    ) : (
                      <CloseIcon className="close-icon" />
                    )}
                    Upper case letters (A-Z)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {hasNumber ? (
                      <DoneIcon className="done-icon" />
                    ) : (
                      <CloseIcon className="close-icon" />
                    )}
                    Number(i.e., 0-9)
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {hasSpecialChar ? (
                      <DoneIcon className="done-icon" />
                    ) : (
                      <CloseIcon className="close-icon" />
                    )}
                    Special characters(e.g. ;@#$%^&*)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={7.2}>
          <AcceptAndRegister />
        </Grid>
        <Grid item xs={7.2} sx={{ display: "flex" }}>
          <Button variant="outlined" onClick={handleButtonClick} disabled={!enable}>
            Accept and Register
          </Button>
        </Grid>
      </Grid>
      {loading ? (
        <Box className="m-8 loader-position">
          <CircularProgress size={60} />
        </Box>
      ) : (
        ""
      )}
      <RegisterModal open={open} close={handleClose} msg={modalMessage} />
    </Box>
  );
};

const mapStateToProps = ({ patientPasswordIdReducer }) => {
  return {
    patientPasswordId: patientPasswordIdReducer.patientPasswordId,
    loading: patientPasswordIdReducer.loading,
    error: patientPasswordIdReducer.error,
    success: patientPasswordIdReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    postPatientPasswordId: (payload) => {
      dispatch(actionTypes.postPatientPasswordId(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PasswordRegistration);
