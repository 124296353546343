import "./estimateTreatment.scss";
import { Box, Stack, Typography, Link, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { APPLY_LOAN } from "../../shared/constants";
import EstimateCards from "./EstimateCards";
import * as actionType from "../../store/actions";
import { connect, useSelector } from "react-redux";
import ConfirmSelection from "./ConfirmSelection";
import { getLocaleDateTime } from "../../shared/constants/common";

const EstimateTreatment = (props) => {
  const { actions, loading } = props;
  const caseList = useSelector((state) => state.homeReducer.caseStatusList);
  // const caseListLoading = useSelector((state) => state.homeReducer.loading);
  const practiceId = localStorage.getItem("practiceId");
  const patientId = localStorage.getItem("patientId");
  const impersonatorId = localStorage.getItem("impersonatorId");
  const cognitoUserId = localStorage.getItem("cognitoUserId");

  useEffect(() => {
    if (!caseList?.finEstimateId) {
      actions.getCaseStatusesData({ practiceId, patientId });
    }

    if (impersonatorId) {
      actions.createImpersonateLog({
        impersonatorId: impersonatorId,
        eventName: "Login In",
        screenName: "Treatment Estimate",
        eventType: "impersonate",
        ipAddress: "192.168.1.1",
        patientId: cognitoUserId
      });
    }
  }, []);

  // const convertDate = (publishDate) => {
  //   var date = new Date(publishDate);
  //   var year = date.toLocaleString("default", { year: "numeric" });
  //   var month = date.toLocaleString("default", { month: "2-digit" });
  //   var day = date.toLocaleString("default", { day: "2-digit" }); // Generate yyyy-mm-dd date string

  //   var formattedDate = month + "/" + day + "/" + year;
  //   return formattedDate;
  // };

  //console.log("caseList", caseList);
  return (
    <>
      {caseList && caseList?.selectProgramStatus !== "completed" ? (
        <Box>
          <Stack m={1} direction="row">
            <Typography xs={12}>
              Treatment Estimate:
              {caseList?.estimateType == "D" ? " " : " PRILIMNARY ESTIMATE "}
            </Typography>
            <Typography xs={12}>
              {caseList?.estimateStatus} on
              {
                " " +
                  getLocaleDateTime(
                    caseList?.publishedDate,
                    "L",
                    true
                  ) /* convertDate(caseList?.publishedDate) */
              }
            </Typography>
          </Stack>
          <Box className="loan-section">
            <Typography>
              Interested in applying for a loan?{" "}
              <Link underline="none" target="_blank" href={APPLY_LOAN}>
                Apply Now
              </Link>
            </Typography>
          </Box>

          {loading ? (
            <Stack justifyContent="center" p={5} alignItems="center">
              <CircularProgress size={50} />
            </Stack>
          ) : (
            <>
              <EstimateCards />
            </>
          )}
        </Box>
      ) : caseList?.selectProgramStatus == "completed" ? (
        <ConfirmSelection />
      ) : (
        <Box className="message-header">
          <Stack direction="row" className="flex-between">
            <Typography variant="body1" className="font-24" sx={{ marginTop: 5 }}>
              TREATMENT ESTIMATE
            </Typography>
            <Stack direction="row" gap={1}></Stack>
          </Stack>
          <Stack direction="column" className="static-text">
            <Typography className="p-4" sx={{ marginTop: 3 }}>
              Your treatment estimate is not yet available.
            </Typography>
            <Typography className="p-4" sx={{ marginTop: 2 }}>
              Once you have completed diagnostic testing and your provider has recommended a course
              of treatment, your Financial Counselor will prepare an estimate of cost and you will
              be able to view it here.
            </Typography>
          </Stack>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ estimateReducer }) => {
  return {
    estimateCards: estimateReducer.estimateCards,
    loading: estimateReducer.estimateCardLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    getCaseStatusesData: (payload) => {
      dispatch(actionType.getCaseStatusesData(payload));
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EstimateTreatment);
