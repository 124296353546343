import { Box, Button, Modal, Stack, Typography, Divider } from "@mui/material";

const RegisterModal = ({ open, close, msg }) => {
  return (
    <Modal open={open}>
      <Box className="small-modal-style">
        <Stack>
          <Typography sx={{ fontSize: "20px", fontWeight: "400" }}>{msg.heading}</Typography>
        </Stack>
        <Divider
          className="divider-style"
          sx={{
            width: "100%",
            height: "1px",
            color: "#1BA3AD",
            marginTop: "10px",
            marginBottom: "10px"
          }}
        />
        <Stack className="align-center">
          <Typography
            sx={{ fontSize: "14px", fontWeight: "400", color: "black", marginBottom: "10px" }}>
            {msg.msgBody || msg}
          </Typography>
          <Button onClick={close} variant="contained">
            Ok
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default RegisterModal;
