import * as actionType from "../actions/ActionTypes";
import { put, call, all, takeEvery } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
// import { token } from "../../shared/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* getProfileData(action) {
  const { payload } = action;
  const { practiceId, patientId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/person/${patientId}/`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.PROFILE_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.PROFILE_DATA_FAILED,
      message: error.message
    });
  }
}

function* getPracticeInfoData(action) {
  const { payload } = action;
  const { practiceId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/${practiceId}`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionType.PRACTICE_INFO_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.PRACTICE_INFO_FAILED,
      message: error.message
    });
  }
}

function* getProfileDataSaga() {
  yield takeEvery(actionType.PROFILE_DATA_REQUESTED, getProfileData);
}

function* getPracticeInfoDataSaga() {
  yield takeEvery(actionType.PRACTICE_INFO_REQUESTED, getPracticeInfoData);
}

export default function* estimateSaga() {
  yield all([getProfileDataSaga(), getPracticeInfoDataSaga()]);
}
