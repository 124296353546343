import * as actionType from "../actions/ActionTypes";

const initialState = {
  patientPasswordId: [],
  loading: false,
  success: false,
  error: false
};

const patientPasswordIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PATIENT_PASSWORD_ID_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.PATIENT_PASSWORD_ID_SUCCESS: {
      return {
        ...state,
        registrationsPatientKey: action.payload,
        success: action.success,
        loading: false,
        error: false
      };
    }

    case actionType.PATIENT_PASSWORD_ID_FAILED: {
      return {
        ...state,
        error: true,
        loading: false,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default patientPasswordIdReducer;
