const url = "https://fac-prod-felix-ui.auth.us-east-1.amazoncognito.com/";
// const url = "https://abac-dev.auth.us-east-2.amazoncognito.com/";

const redirect_uri = "https://patient-portal.nextgenclarity.com/";
// const redirect_uri = "http://localhost:3000";
const client_id = "28hso2r0tdv1j78n76a1n5f08v"; //demo
// const client_id = "45ekg9718vhvhet0eqbjgu7oi5"; //abac
const response_type = "token";
const scope = "openid+profile+aws.cognito.signin.user.admin";

const config = {
  response_type: response_type,
  client_id: client_id,
  redirect_uri: redirect_uri,
  scope: scope
};

/* AWS Region where the bucket/gateway api is located */
const AWS_EXECUTE_API = "execute-api";

/* AWS Region where the bucket/gateway api is located */
const AWS_REGION = "us-east-1";

/* AWS identity pool id of a federated pool created for cognito access */
// const AWS_IDENTITY_POOL_ID = `${AWS_REGION}:39048ad2-1466-488c-8e1b-dd9876ee7cf7`; //abac
const AWS_IDENTITY_POOL_ID = `${AWS_REGION}:beacdf26-9af9-49a1-936a-d5eb41a3a75d`; //demo

/* AWS user pool id */
// const AWS_USER_POOL_ID = "us-east-2_nLIH6BefQ"; //abac
const AWS_USER_POOL_ID = "us-east-1_In8ojdWRn"; //demo

/* AWS login key required for getting temporary credentials */
const AWS_LOGINS_KEY = `cognito-idp.${AWS_REGION}.amazonaws.com/${AWS_USER_POOL_ID}`;

const AWS_CREDENTIALS = "credentials";

const AWS_USER_LOGIN_CLIENT_ID = "5buoinmrc93cqp34l5oqg7m0ur";

const LOGINS = {};
LOGINS[AWS_LOGINS_KEY] = "";

export {
  url,
  config,
  LOGINS,
  AWS_REGION,
  AWS_IDENTITY_POOL_ID,
  AWS_LOGINS_KEY,
  AWS_EXECUTE_API,
  AWS_CREDENTIALS,
  AWS_USER_LOGIN_CLIENT_ID,
  AWS_USER_POOL_ID
};
