import "./profile.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PatientInformation from "./PatientInformation";
import PracticeInfo from "./PracticeInfo";
import useMediaQuery from "@mui/material/useMediaQuery";

const Profile = () => {
  const [value, setValue] = useState("info");
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/profile/${newValue}`);
  };
  return (
    <>
      <Box flexDirection="row" className="p-1">
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} className="primary-one">
              <Tab
                label="Patient Information"
                value="info"
                style={{ minWidth: isSmallScreen && "50%" }}
              />
              <Tab
                label="Practice Info"
                value="timings"
                style={{ minWidth: isSmallScreen && "50%" }}
              />
            </TabList>
          </Box>
          <TabPanel value="info" className="p-0">
            <PatientInformation />
          </TabPanel>
          <TabPanel value="timings" className="p-0">
            <PracticeInfo />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};
export default Profile;
