import "./payBill.css";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Stack, Card, Grid, Link, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionType from "../../store/actions";
import InvoiceDetails from "./InvoiceDetails";
import Pay from "./Pay";
// import NextSteps from "../../shared/components/NextStep";
import SelectedProgramPayBill from "../../shared/components/SelecctedProgramPayBill";
import moment from "moment";

// import { useMediaQuery } from "@mui/material";

const PayBill = (props) => {
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.5,
      minWidth: 50,
      renderCell: ({ row }) => <Typography>{row.id + 1}</Typography>
    },
    {
      field: "finEstimateDesc",
      headerName: "Detailed Estimate",
      flex: 1,
      minWidth: 130,
      renderCell: ({ row }) => (
        <Typography>{row.finEstimateDesc ? row.finEstimateDesc : "--"}</Typography>
      )
    },
    {
      field: "publishedOn",
      headerName: "Published On",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => (
        <Typography> {new Date(row.publishedOn).toLocaleDateString()}</Typography>
      )
    },
    {
      field: "treatmentName",
      headerName: "Treatment And Program",
      flex: 2,
      minWidth: 210,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row">
            <Typography>
              {row?.treatmentPlanCustomName !== null
                ? row?.treatmentPlanCustomName + " " + " with " + row.programName
                : row?.treatmentName + " " + " with " + row.programName}{" "}
            </Typography>
            {/* <Typography> {" " + " with " + row.programName}</Typography> */}
          </Stack>
        );
      }
    },
    {
      field: "programSelectedDate",
      headerName: "Selected On",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => (
        <Typography>
          {/* new Date(row.programSelectedDate).toLocaleDateString() */}
          {moment(row.programSelectedDate, "YYYY-MM-DD").format("MM/DD/YYYY")}
        </Typography>
      )
    },
    {
      field: "medcaseId",
      headerName: "Medcase No",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => <Typography> {row.medcaseId ? row.medcaseId : "--"}</Typography>
    },
    {
      field: "invoiceNo",
      headerName: "Invoice No",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => (
        <Link
          style={{ textDecoration: "none" }}
          onClick={() => {
            console.log("row", row);
            let payload = {
              practiceId,
              estimateId: row.finEstimateId
            };
            actions.getEstimateCard(payload);
            setRowClicked(false);
            //actions.resetCostCoverage();
            setRowClicked(true);
            setSelectedInvoiceId(row.invoiceNo);
            setselectedInvoice(row);
          }}>
          <Typography>{row.invoiceNo ? row.invoiceNo : "--"}</Typography>
        </Link>
      )
    }
  ];

  const {
    actions,
    invoiceDetails = [],
    loading,
    practiceInfoData,
    estimateCards,
    estimateCardLoading
  } = props;
  const patientId = localStorage.getItem("patientId");
  const practiceId = localStorage.getItem("practiceId");
  const impersonatorId = localStorage.getItem("impersonatorId");
  const cognitoUserId = localStorage.getItem("cognitoUserId");
  // const estimateCards = useSelector((state) => state.estimateReducer.estimateCards);

  const rows = invoiceDetails.map((item, index) => {
    item.id = index;
    return item;
  });

  console.log("zhr rows", rows);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(0);
  const [selectedInvoice, setselectedInvoice] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [rowClicked, setRowClicked] = useState(false);
  const [programFees, setProgramFees] = useState();

  useEffect(() => {
    if (impersonatorId) {
      actions.createImpersonateLog({
        impersonatorId: impersonatorId,
        eventName: "Login In",
        screenName: "Pay Bill",
        eventType: "impersonate",
        ipAddress: "192.168.1.1",
        patientId: cognitoUserId
      });
    }

    actions.getPracticeInfoData({ practiceId });
  }, []);

  useEffect(() => {
    if (invoiceDetails.length === 0) {
      actions.getInvoiceDetails({ patientId });
    }
  }, [patientId]);

  const handleProgramFees = (programCost) => {
    if (programCost) setProgramFees(programCost);
  };

  return (
    <Box className="container" sx={{ margin: 1 }}>
      {loading ? (
        <Stack justifyContent="center" alignItems="center" p={20}>
          <CircularProgress size={50} />
        </Stack>
      ) : invoiceDetails && invoiceDetails.length > 0 ? (
        <>
          <Box>
            <Typography>
              Below is your invoice for the selected treatment plan. Click on the Invoice No to view
              and pay your bill.
              {/* <span className="font-w-500">THANK YOU! </span> Since you have confirmed your
              treatment plan, please review the invoice for appropriate program */}
            </Typography>
          </Box>
          <Box>
            <Card variant="outlined" className="pad-16 mt-8">
              <Typography variant="body2">Please Select Invoice for appropriate program</Typography>
              <DataGrid
                className="mt-16"
                loading={loading}
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[5, 10, 25]}
                pageSize={pageSize}
                onPageSizeChange={(newSize) => setPageSize(newSize)}
                pagination
                autoHeight
              />
              <Grid item xs={3} className={selectedInvoiceId !== 0 ? "p-4 mt-8" : "display-none"}>
                <Pay data={selectedInvoice} />
                <InvoiceDetails data={selectedInvoice} programFees={programFees} />

                {rowClicked && estimateCards && !estimateCardLoading ? (
                  <SelectedProgramPayBill
                    estimateCards={estimateCards}
                    finEstimateId={selectedInvoice?.finEstimateId}
                    selectedInvoice={selectedInvoice}
                    handleProgramFees={handleProgramFees}
                    payProgramFees={programFees}
                    payProgramName={selectedInvoice?.programName}
                  />
                ) : null}
              </Grid>
            </Card>
          </Box>

          {/* <Box>
            <Card className="p-4 mt-8" variant="outlined">
              <NextSteps practiceInfo={practiceInfoData} />
            </Card>
          </Box> */}
        </>
      ) : (
        <Box className="message-header">
          <Stack direction="row" className="flex-between">
            <Typography variant="body1" className="font-24" sx={{ marginTop: 5 }}>
              PAY YOUR BILL
            </Typography>
            <Stack direction="row" gap={1}></Stack>
          </Stack>
          <Stack direction="column" className="static-text">
            <Typography className="p-4" sx={{ marginTop: 3 }}>
              You do not yet have a bill for a future course of treatment at this time. To view and
              pay existing bills due to your practice, please{" "}
              {/* <Link underline="none" target="_blank" href={"https://ivfflorida.com/pay-your-bill/"}> */}
              <Link
                underline="none"
                target="_blank"
                href={`${practiceInfoData?.payYourBillLink ?? "/"}`}>
                click here
              </Link>
              .
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
const mapStateToProps = ({ paybillReducer, profileReducer, estimateReducer }) => {
  return {
    invoiceDetails: paybillReducer.invoiceDetails,
    practiceInfoData: profileReducer.practiceInfoData,
    loading: paybillReducer.loading,
    error: paybillReducer.error,
    estimateCards: estimateReducer.estimateCards,
    estimateCardLoading: estimateReducer.estimateCardLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getInvoiceDetails: (payload) => {
      dispatch(actionType.getInvoiceDetails(payload));
    },
    resetInvoiceDetailsList: () => {
      dispatch(actionType.resetInvoiceDetailsList());
    },
    resetCostCoverage: () => {
      dispatch(actionType.resetCostCoverage());
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    },
    getPracticeInfoData: (payload) => {
      dispatch(actionType.getPracticeInfoData(payload));
    },
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PayBill);
