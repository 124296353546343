import { Card, InputLabel, Divider, Typography, Grid, Stack, Skeleton } from "@mui/material";
// import StageServiceItemList from "../../components/estimateTreatment/StageServiceItemList";
import { useEffect, useState } from "react";
import * as actionType from "../../store/actions";
import { connect, useSelector } from "react-redux";

const SelectedProgram = (props) => {
  const {
    actions,
    stages = [],
    necessary,
    optional,
    estimateCards,
    fapList,
    // cardLoading,
    // stageLoading,
    necessaryLoading,
    // optionalLoading,
    fapLoading,
    optionalLoaded,
    stagesloaded,
    necessaryLoaded,
    selectedInvoice,
    handleProgramFees,
    prgramFeesNotSet,
    // adjustmentList,
    // notes,
    stPlan,
    setTreatmentChange
    // noteLoading
  } = props;
  const caseList = useSelector((state) => state.homeReducer.caseStatusList);
  const practiceId = localStorage.getItem("practiceId");
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState({});
  const [programPrice, setProgramPrice] = useState(0);
  const [programName, setProgramName] = useState("");
  // const [totalEstimate, setTotalEstimate] = useState(0);
  // const [totalEstimateMax, setTotalEstimateMax] = useState(0);

  useEffect(() => {
    if (estimateCards.length) {
      let res = estimateCards.filter((item) => {
        return item.isSelected == "1";
      });
      setSelectedTreatmentPlan(res[0]);

      const payload = {
        practiceId,
        gdmId: res[0]?.gdmId,
        treatmentPlanId: res[0]?.treatmentPlanId,
        estimateId: caseList.finEstimateId,
        estimateTpId: res[0]?.finEstimateTpId
      };
      if (stages.length == 0 && !stagesloaded) {
        actions.getStages(payload);
      }
      if (necessary.length == 0 && !necessaryLoaded) {
        actions.getNecessary(payload);
      }
      if (optional.length == 0 && !optionalLoaded) {
        actions.getOptional(payload);
      }
      if (res[0]?.treatmentType.indexOf("IVF") != -1) {
        // if (fapList?.programVisualization == undefined) {
        actions.getFertilityAccessPrograms({
          ...payload,
          estimateId: caseList.finEstimateId,
          estimateTpId: res[0]?.finEstimateTpId
        });
        // }
      }

      actions.getNotes(payload);
      actions.getAdjustments(payload);
    }
  }, [selectedInvoice]);

  useEffect(() => {
    if (estimateCards.length != 0 && necessary != null) {
      let res = estimateCards.filter((item) => {
        return item.isSelected == "1";
      });
      console.log("res", res);

      if (res[0]?.treatmentType.indexOf("IVF") != -1) {
        if (
          fapList.programVisualization != undefined &&
          fapList.programVisualization.length > 0 &&
          selectedInvoice
        ) {
          let programs = fapList.programVisualization
            .map((item) => item.programs)
            .flat()
            .filter((item) => item.programName.trim() == selectedInvoice.optionSelected);
          // .filter((item) => item.programId == caseList.programId);

          console.log("zhr programs:", programs);

          setProgramName((programs && programs[0] && programs[0].programName) || "");
          if (res[0]?.linkedEstimateTreatmentPlan) {
            if (res[0]?.linkedEstimateTreatmentPlan.isSelected == 1) {
              // setProgramPrice(
              //   (programs &&
              //     programs[0] &&
              //     programs[0].programPrice + res[0]?.linkedEstimateTreatmentPlan.tpPrice) ||
              //     0
              // );
              setProgramPrice((programs && programs[0] && programs[0].programPrice) || 0);
            } else {
              setProgramPrice((programs && programs[0] && programs[0].programPrice) || 0);
            }
          } else {
            setProgramPrice((programs && programs[0] && programs[0].programPrice) || 0);
          }
        }
      } else {
        // other than IVF
        let normalPrice = necessary.map(({ tpStage, serviceItems }) => {
          if (!tpStage.range) {
            return serviceItems.map((item) => item.grossPrice).reduce((a, b) => a + b, 0);
          }
        });
        let rangeMinPrice = necessary.map(({ tpStage, serviceItems }) => {
          if (tpStage.range) {
            return serviceItems.map((item) => item.grossPrice)[0];
          }
        });
        const minPrice = rangeMinPrice.filter((item) => item).reduce((a, b) => a + b, 0);

        console.log("zhr res[0]?.optionSelected", res[0]?.optionSelected);

        setProgramName(res[0]?.optionSelected);
        if (res && res[0] && res[0].treatmentType === "PGT-A Analysis") {
          setProgramPrice(
            res[0]?.originalTpPrice +
              minPrice +
              normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
          );
        } else {
          setProgramPrice(
            res[0]?.tpPrice +
              minPrice +
              normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
          );
        }
      }
    }
  }, [fapLoading, necessaryLoading, selectedInvoice]);

  useEffect(() => {
    if (programPrice && !prgramFeesNotSet) {
      handleProgramFees(programPrice);
    }
  }, [programPrice]);

  // useEffect(() => {
  //   if (selectedTreatmentPlan) {
  //     if (necessary.length) {
  //       let normalPrice = necessary.map(({ tpStage, serviceItems }) => {
  //         if (!tpStage.range) {
  //           return serviceItems.map((item) => item.grossPrice).reduce((a, b) => a + b, 0);
  //         }
  //       });
  //       let rangeMinPrice = necessary.map(({ tpStage, serviceItems }) => {
  //         if (tpStage.range) {
  //           return serviceItems.map((item) => item.grossPrice)[0];
  //         }
  //       });
  //       let rangeMaxPrice = necessary.map(({ tpStage, serviceItems }) => {
  //         if (tpStage.range) {
  //           return serviceItems.map((item) => item.grossPrice)[serviceItems.length - 1];
  //         }
  //       });
  //       // const minPrice = rangeMinPrice.filter((item) => item).reduce((a, b) => a + b, 0);
  //       // const maxPrice = rangeMaxPrice.filter((item) => item).reduce((a, b) => a + b, 0);
  //       // setTotalEstimate(
  //       //   selectedTreatmentPlan?.tpPrice +
  //       //     minPrice +
  //       //     normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
  //       // );
  //       // setTotalEstimateMax(
  //       //   selectedTreatmentPlan?.tpPrice +
  //       //     maxPrice +
  //       //     normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
  //       // );
  //     } else {
  //       // setTotalEstimate(selectedTreatmentPlan?.tpPrice);
  //       // setTotalEstimateMax(selectedTreatmentPlan?.tpPrice);
  //     }
  //   }
  // }, [necessary, selectedTreatmentPlan]);

  return (
    <Card variant="outlined" sx={{ p: 1, mt: 1 }}>
      <Stack mt={3}>
        <Grid container>
          <Grid item xs={3}>
            <InputLabel>SELECTED PROGRAM</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <InputLabel>TREATMENT PLAN</InputLabel>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <InputLabel>REFUND</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" alignItems="center" justifyContent="right">
              {stPlan?.optionSelected ? (
                <Typography variant="body2">TOTAL COST OF PROGRAM</Typography>
              ) : (
                <Typography variant="body2">AMOUNT DUE TO PRACTICE</Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Divider className="modal-divider" sx={{ mt: 1 }} />
        <Grid container sx={{ mt: 1 }}>
          <Grid item xs={3}>
            {fapLoading ? (
              <Skeleton width={50} />
            ) : (
              <Stack>
                {console.log("zhr stPlan", stPlan)}
                {console.log("zhr selectedTreatmentPlan", selectedTreatmentPlan)}
                <InputLabel>{stPlan?.optionSelected ? programName : "Pay Per Cycle"}</InputLabel>
                <InputLabel>
                  {selectedTreatmentPlan?.linkedEstimateTreatmentPlan?.optionSelected ||
                    selectedTreatmentPlan?.linkedEstimateTreatmentPlan?.linkProgram}
                </InputLabel>
              </Stack>
            )}
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <InputLabel onClick={() => setTreatmentChange(1)} sx={{ color: "blue" }}>
                  {selectedTreatmentPlan?.treatmentPlanCustomName
                    ? selectedTreatmentPlan?.treatmentPlanCustomName
                    : selectedTreatmentPlan?.treatmentPlanName}
                </InputLabel>
                <InputLabel onClick={() => setTreatmentChange(2)} sx={{ color: "blue" }}>
                  {selectedTreatmentPlan?.linkedEstimateTreatmentPlan?.treatmentPlanCustomName
                    ? selectedTreatmentPlan?.linkedEstimateTreatmentPlan?.treatmentPlanCustomName
                    : selectedTreatmentPlan?.linkedEstimateTreatmentPlan?.treatmentPlanName}
                </InputLabel>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <InputLabel>
              {selectedTreatmentPlan?.refundPercentage !== null
                ? " " + selectedTreatmentPlan?.refundPercentage + "%"
                : "--"}
            </InputLabel>
            <InputLabel>
              {selectedTreatmentPlan &&
              selectedTreatmentPlan.linkedEstimateTreatmentPlan &&
              selectedTreatmentPlan.linkedEstimateTreatmentPlan.refundPercentage !== null
                ? " " + selectedTreatmentPlan.linkedEstimateTreatmentPlan.refundPercentage + "%"
                : ""}
            </InputLabel>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" alignItems="center" justifyContent="right">
              {fapLoading ? (
                <Skeleton width={50} />
              ) : (
                <Stack>
                  <Typography variant="body2">
                    $
                    {/* {stPlan?.optionSelected
                    ? programPrice.toLocaleString()
                    : selectedTreatmentPlan?.payProgramCost
                    ? selectedTreatmentPlan?.payProgramCost.toLocaleString()
                    : selectedTreatmentPlan?.originalTpPrice &&
                      selectedTreatmentPlan?.originalTpPrice.toLocaleString()} */}
                    {stPlan?.optionSelected && stPlan?.treatmentType !== "PGT-A Analysis"
                      ? programPrice.toLocaleString()
                      : selectedTreatmentPlan?.tpPrice &&
                        selectedTreatmentPlan?.tpPrice.toLocaleString()}
                    {/* {(Math.round(programPrice / 100) * 100).toLocaleString()} */}
                  </Typography>
                  <Typography variant="body2">
                    {selectedTreatmentPlan &&
                      selectedTreatmentPlan.linkedEstimateTreatmentPlan &&
                      "$" +
                        selectedTreatmentPlan.linkedEstimateTreatmentPlan.tpPrice.toLocaleString()}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};
const mapStateToProps = ({ estimateReducer }) => {
  return {
    stages: estimateReducer.stages,
    necessary: estimateReducer.necessary,
    optional: estimateReducer.optional,
    loading: estimateReducer.loading,
    error: estimateReducer.error,
    fapLoading: estimateReducer.fapLoading,
    fapList: estimateReducer.fapList,
    stageLoading: estimateReducer.stageLoading,
    optionalLoading: estimateReducer.optionalLoading,
    necessaryLoading: estimateReducer.necessaryLoading,
    stagesloaded: estimateReducer.stagesloaded,
    necessaryLoaded: estimateReducer.necessaryLoaded,
    optionalLoaded: estimateReducer.optionalLoaded,
    adjustmentList: estimateReducer.adjustmentList,
    notes: estimateReducer.notes
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    getNecessary: (payload) => {
      dispatch(actionType.getNecessary(payload));
    },
    getOptional: (payload) => {
      dispatch(actionType.getOptional(payload));
    },
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    getFertilityAccessPrograms: (payload) => {
      dispatch(actionType.getFertilityAccessPrograms(payload));
    },
    getAdjustments: (payload) => {
      dispatch(actionType.getAdjustments(payload));
    },
    getNotes: (payload) => {
      dispatch(actionType.getNotes(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedProgram);
