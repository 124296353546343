import React, { useState } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import RegisteredPatientDetails from "./RegisteredPatientDetails";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/index";
import PasswordRegistration from "./PasswordRegistration";

const Registration = (props) => {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <Box className="m-25">
        <Grid container spacing={3}>
          <Grid item sx={{ flexDirection: "column" }}>
            <Grid item xs={12}>
              {props?.patientInformation ? (
                <RegisteredPatientDetails
                  name={props?.patientInformation?.firstName}
                  dob={props?.patientInformation?.birthDate}
                  email={props?.patientInformation?.email}
                  pNumber={props?.patientInformation?.personKey}
                />
              ) : (
                <Grid item>Loading.....</Grid>
              )}
            </Grid>
            <Grid item sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Typography>Is this information correct?</Typography>
            </Grid>
            <Grid item sx={{ flexDirection: "column" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setShowForm(true);
                }}
                className="btn-primary">
                Yes
              </Button>
              <Link to="/initial-registration">
                <Button className="btn-primary">No</Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {showForm ? (
        <PasswordRegistration
          email={props?.patientInformation?.email}
          personKey={props?.patientInformation?.personKey}
          name={props?.patientInformation?.firstName}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = ({ registrationsPatientKeyReducer }) => {
  return {
    patientInformation: registrationsPatientKeyReducer.patientInformation,
    loading: registrationsPatientKeyReducer.loading,
    error: registrationsPatientKeyReducer.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getRegistrationsPatientKey: (payload) => {
      dispatch(actionTypes.getRegistrationsPatientKey(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
