import * as actionType from "../actions/ActionTypes";

const initialState = {
  estimateCards: [],
  stages: [],
  adjustmentList: [],
  necessary: [],
  optional: [],
  fapList: {},
  practiceData: {},
  estimateCardLoading: false,
  stageLoading: false,
  adjustmentLoading: false,
  necessaryLoading: false,
  optionalLoading: false,
  fapLoading: false,
  error: null,
  success: null,
  programSelection: null,
  programLoading: false,
  loading: false,
  reviewEstimate: {},
  reviewEstimateLoading: false,
  programError: false,
  programSuccess: false,
  stagesloaded: false,
  necessaryLoaded: false,
  optionalLoaded: false,
  cardLoaded: false,
  noteLoading: false,
  notes: null
};

const estimateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ESTIMATE_CARDS_REQUESTED: {
      return {
        ...state,
        estimateCardLoading: true,
        estimateCards: []
      };
    }
    case actionType.ESTIMATE_CARDS_SUCCESS: {
      return {
        ...state,
        estimateCards: action.payload,
        estimateCardLoading: false,
        cardLoaded: true
      };
    }
    case actionType.ESTIMATE_CARDS_FAILED: {
      return {
        ...state,
        error: action.message,
        estimateCardLoading: false
      };
    }

    case actionType.GET_STAGES_REQUESTED: {
      return {
        ...state,
        stageLoading: true
      };
    }
    case actionType.GET_STAGES_SUCCESS: {
      return {
        ...state,
        stages: action.payload,
        stageLoading: false,
        stagesloaded: true
      };
    }
    case actionType.GET_STAGES_FAILED: {
      return {
        ...state,
        error: action.message,
        stageLoading: false
      };
    }

    case actionType.ADJUSTMENT_LIST_REQUESTED: {
      return {
        ...state,
        adjustmentLoading: true
      };
    }
    case actionType.ADJUSTMENT_LIST_SUCCESS: {
      return {
        ...state,
        adjustmentList: action.payload,
        adjustmentLoading: false
      };
    }
    case actionType.ADJUSTMENT_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        adjustmentLoading: false
      };
    }

    case actionType.NECESSARY_LIST_REQUESTED: {
      return {
        ...state,
        necessaryLoading: true
      };
    }
    case actionType.NECESSARY_LIST_SUCCESS: {
      return {
        ...state,
        necessary: action.payload,
        necessaryLoading: false,
        necessaryLoaded: true
      };
    }
    case actionType.NECESSARY_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        necessaryLoading: false
      };
    }
    case actionType.OPTIONAL_LIST_REQUESTED: {
      return {
        ...state,
        optionalLoading: true
      };
    }
    case actionType.OPTIONAL_LIST_SUCCESS: {
      return {
        ...state,
        optional: action.payload,
        optionalLoading: false,
        optionalLoaded: true
      };
    }
    case actionType.OPTIONAL_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        optionalLoading: false
      };
    }

    case actionType.FERTILTY_ACCESS_PROGRAMS_REQUESTED: {
      return {
        ...state,
        fapLoading: true
      };
    }
    case actionType.FERTILTY_ACCESS_PROGRAMS_SUCCESS: {
      return {
        ...state,
        fapList: action.payload,
        fapLoading: false
      };
    }
    case actionType.FERTILTY_ACCESS_PROGRAMS_FAILED: {
      return {
        ...state,
        error: action.message,
        fapLoading: false
      };
    }

    case actionType.REVIEW_ESTIMATE_REQUESTED: {
      return {
        ...state,
        reviewEstimateLoading: true
      };
    }
    case actionType.REVIEW_ESTIMATE_SUCCESS: {
      return {
        ...state,
        reviewEstimate: action.payload,
        reviewEstimateLoading: false
      };
    }
    case actionType.REVIEW_ESTIMATE_FAILED: {
      return {
        ...state,
        error: action.message,
        reviewEstimateLoading: false
      };
    }

    case actionType.RESET_ESTIMATE_CARDS: {
      return {
        ...state,
        estimateCards: [],
        estimateCardLoading: false
      };
    }

    case actionType.RESET_COST_COVERAGE_DATA: {
      return {
        ...state,
        stages: [],
        adjustmentList: [],
        necessary: [],
        optional: []
      };
    }
    case actionType.UPADATE_PROGRAM_SELECTIONS_REQUESTED: {
      return {
        ...state,
        programLoading: true
      };
    }
    case actionType.UPADATE_PROGRAM_SELECTIONS_SUCCESS: {
      return {
        ...state,
        programSelection: action.payload,
        programSuccess: action.successMsg,
        programLoading: false
      };
    }
    case actionType.UPADATE_PROGRAM_SELECTIONS_FAILED: {
      return {
        ...state,
        programError: action.errorMsg,
        programLoading: false
      };
    }
    //practice data
    case actionType.PRACTICE_PGTA_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PRACTICE_PGTA_DATA_SUCCESS: {
      return {
        ...state,
        practiceData: action.payload,
        loading: false
      };
    }
    case actionType.PRACTICE_PGTA_DATA_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_MSGS: {
      return {
        ...state,
        programSelection: null,
        programSuccess: false,
        programError: false,
        programLoading: false
      };
    }

    case actionType.FINANCIAL_ESTIMATE_NOTES_REQUESTED: {
      return {
        ...state,
        noteLoading: true
      };
    }
    case actionType.FINANCIAL_ESTIMATE_NOTES_SUCCESS: {
      return {
        ...state,
        notes: action.payload,
        noteLoading: false
      };
    }
    case actionType.FINANCIAL_ESTIMATE_NOTES_FAILED: {
      return {
        ...state,
        error: action.message,
        noteLoading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default estimateReducer;
