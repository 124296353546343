import { Box, Button, Typography, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionType from "../../store/actions";
import { Stack } from "@mui/system";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getLocaleDateTime } from "../../shared/constants/common";

const Sent = (props) => {
  const columns = [
    {
      field: "category",
      headerName: "CATEGORY",
      width: 200,
      renderCell: ({ row }) => {
        return <Typography className="word-wrap">{row.type}</Typography>;
      }
    },
    {
      field: "sentDate",
      headerName: "DATE",
      type: "date",
      width: 200,
      /* renderCell: ({ row }) => {
        return <Typography>{row.sentDate}</Typography>;
      } */
      renderCell: ({ row }) => {
        return (
          <Typography>{getLocaleDateTime(row?.sentDate, "MM/DD/YYYY hh:mm A z", true)}</Typography>
        );
      }
    },
    {
      field: "subject",
      headerName: "SUBJECT",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.subject}</Typography>;
      }
    }
  ];
  const { actions, ListOfSentMessages, ListOfMessagesLoading, downloadedFileUrl } = props;
  const [rows, setRows] = useState([]);
  const [openSelectedMessage, setOpenSelectedMessage] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const userId = localStorage.getItem("userId");
  const updateSelectedMessage = ({ row }) => {
    setOpenSelectedMessage(row);
  };
  const messageBackClickHandler = () => {
    setOpenSelectedMessage({});
  };
  const downloadHandler = () => {
    selectedMessages[0].messageAttachment.map((item) => {
      let payload = {
        filePath: item.attachmentUrl
      };
      actions.getFile(payload);
    });
    setSelectionModel([]);
  };
  useEffect(() => {
    if (downloadedFileUrl) {
      let a = document.createElement("a");
      a.href = downloadedFileUrl;
      a.download = "document.pdf";
      a.click();
      actions.resetMessageExecuteStatus();
    }
  }, [downloadedFileUrl]);

  useEffect(() => {
    if (userId) {
      let payload = {
        userId: userId,
        messagesStatus: "Sent"
      };
      actions.getMessagesList(payload);
    }
  }, [userId]);

  useEffect(() => {
    if (ListOfSentMessages) {
      let rows = ListOfSentMessages.messageList.map((item, index) => {
        item.id = index;
        return item;
      });
      setRows(rows);
    }
  }, [ListOfSentMessages]);

  return (
    <Box className="mt-15">
      <Stack direction="row">
        <Button
          onClick={messageBackClickHandler}
          disabled={Object.keys(openSelectedMessage).length == 0}>
          <KeyboardBackspaceIcon />
        </Button>
        <Button
          variant="outlined"
          onClick={downloadHandler}
          disabled={selectedMessages.length != 1 || !selectedMessages[0]?.messageAttachment?.length}
          sx={{ ml: 2 }}>
          Download Attachment
        </Button>
      </Stack>
      {Object.keys(openSelectedMessage).length != 0 ? (
        <Box className="message-body">
          <Stack>
            <Stack gap={1}>
              <Typography>{openSelectedMessage.type}</Typography>
              <Typography>{openSelectedMessage.subject}</Typography>
              <Typography>{openSelectedMessage.sentDate}</Typography>
            </Stack>

            <Divider fitted="true" sx={{ mt: 2, mb: 2 }} />
            <Box
              dangerouslySetInnerHTML={{
                __html: openSelectedMessage?.body
              }}
            />
          </Stack>
        </Box>
      ) : (
        <Box className="grid-style">
          <DataGrid
            sx={{ mx: 1 }}
            loading={ListOfMessagesLoading}
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            rows={rows}
            columns={columns}
            onRowClick={(e) => {
              updateSelectedMessage(e);
            }}
            checkboxSelection={true}
            onSelectionModelChange={(ids) => {
              setSelectionModel(ids);
              const selectedIds = new Set(ids);
              setSelectedMessages(rows.filter((row) => selectedIds.has(row.id)));
            }}
            selectionModel={selectionModel}
          />
        </Box>
      )}
    </Box>
  );
};
const mapStateToProps = ({ messageReducer }) => {
  return {
    ListOfSentMessages: messageReducer.ListOfSentMessages,
    ListOfMessagesLoading: messageReducer.ListOfMessagesLoading,
    downloadedFileUrl: messageReducer.downloadedFileUrl
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMessagesList: (payload) => {
      dispatch(actionType.getMessagesList(payload));
    },
    getFile: (payload) => {
      dispatch(actionType.getFile(payload));
    },
    resetMessageExecuteStatus: () => {
      dispatch(actionType.resetMessageExecuteStatus());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Sent);
