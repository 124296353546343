import * as actionType from "../actions/ActionTypes";
import { put, call, takeEvery, all } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* getPagesDataSaga(action) {
  const { payload } = action;
  console.log("payload", payload);
  try {
    const params = {};
    //https://devapi.nextgenclarity.tech/practices/system-configuration/patientPortalTermsRequest
    const url = `/registrations/patients/patientPortalTerms`;
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionType.PAGES_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: actionType.PAGES_DATA_FAILED,
      message: error.message
    });
  }
}

function* fetchPagesList() {
  yield takeEvery(actionType.PAGES_DATA_REQUESTED, getPagesDataSaga);
}

export default function* pagesSaga() {
  yield all([fetchPagesList()]);
}
