import CryptoJS from "crypto-js";
import { AWS_EXECUTE_API, AWS_REGION } from "../constants/cognitoConstants";
import apis from "../../shared/constants/api-url";
import { pathComponent, getURLEndpoint } from "../../shared/constants/constants";
import { parseParametersToObject } from "../../shared/components/sigv4Utility";
import { parseTemplate } from "url-template";

const AWS_SHA_256 = "AWS4-HMAC-SHA256";
const AWS4_REQUEST = "aws4_request";
const AWS4 = "AWS4";
const X_AMZ_DATE = "x-amz-date";
const X_AMZ_SECURITY_TOKEN = "x-amz-security-token";
const HOST = "host";
const AUTHORIZATION = "Authorization";

const hash = (value) => {
  return CryptoJS.SHA256(value);
};

const hexEncode = (value) => {
  return value.toString(CryptoJS.enc.Hex);
};

const hmac = (secret, value) => {
  return CryptoJS.HmacSHA256(value, secret, { asBytes: true });
};

const buildCanonicalRequest = (method, path, queryParams, headers, payload) => {
  return (
    method +
    "\n" +
    buildCanonicalUri(path) +
    "\n" +
    buildCanonicalQueryString(queryParams) +
    "\n" +
    buildCanonicalHeaders(headers) +
    "\n" +
    buildCanonicalSignedHeaders(headers) +
    "\n" +
    hexEncode(hash(payload))
  );
};

const hashCanonicalRequest = (request) => {
  return hexEncode(hash(request));
};

const buildCanonicalUri = (uri) => {
  return encodeURI(uri);
};

const buildCanonicalQueryString = (queryParams) => {
  if (Object.keys(queryParams).length < 1) {
    return "";
  }

  let sortedQueryParams = [];
  for (let property in queryParams) {
    // eslint-disable-next-line
    if (queryParams.hasOwnProperty(property)) {
      sortedQueryParams.push(property);
    }
  }
  sortedQueryParams.sort();

  let canonicalQueryString = "";
  for (let i = 0; i < sortedQueryParams.length; i++) {
    canonicalQueryString +=
      sortedQueryParams[i] + "=" + fixedEncodeURIComponent(queryParams[sortedQueryParams[i]]) + "&";
  }
  return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
};

const fixedEncodeURIComponent = (str) => {
  // return str; // No need to encode uri, as it will automatically be taken care by browser.
  // TODO Must write logic if any special character is used in query params

  // Do not remove below commented code.
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
    return "%" + c.charCodeAt(0).toString(16).toUpperCase();
  });
};

const buildCanonicalHeaders = (headers) => {
  let canonicalHeaders = "";
  let sortedKeys = [];
  for (let property in headers) {
    // eslint-disable-next-line
    if (headers.hasOwnProperty(property)) {
      sortedKeys.push(property);
    }
  }
  sortedKeys.sort();

  for (let i = 0; i < sortedKeys.length; i++) {
    canonicalHeaders += sortedKeys[i].toLowerCase() + ":" + headers[sortedKeys[i]] + "\n";
  }
  return canonicalHeaders;
};

const buildCanonicalSignedHeaders = (headers) => {
  let sortedKeys = [];
  for (let property in headers) {
    sortedKeys.push(property.toLowerCase());
  }
  sortedKeys.sort();

  return sortedKeys.join(";");
};

const buildStringToSign = (datetime, credentialScope, hashedCanonicalRequest) => {
  return AWS_SHA_256 + "\n" + datetime + "\n" + credentialScope + "\n" + hashedCanonicalRequest;
};

const buildCredentialScope = (datetime, region, service) => {
  return datetime.substr(0, 8) + "/" + region + "/" + service + "/" + AWS4_REQUEST;
};

const calculateSigningKey = (secretKey, datetime, region, service) => {
  return hmac(
    hmac(hmac(hmac(AWS4 + secretKey, datetime.substr(0, 8)), region), service),
    AWS4_REQUEST
  );
};

const calculateSignature = (key, stringToSign) => {
  return hexEncode(hmac(key, stringToSign));
};

const buildAuthorizationHeader = (accessKey, credentialScope, headers, signature) => {
  return (
    AWS_SHA_256 +
    " Credential=" +
    accessKey +
    "/" +
    credentialScope +
    ", SignedHeaders=" +
    buildCanonicalSignedHeaders(headers) +
    ", Signature=" +
    signature
  );
};

const createRequestBody = (method, p_path, params, payload) => {
  return {
    method: method.toUpperCase(), //should be always upper case
    path:
      pathComponent(apis.getUri()) +
      parseTemplate(p_path).expand(parseParametersToObject(params, [])),
    headers: parseParametersToObject(params, []),
    queryParams: parseParametersToObject(params, Object.keys(params)),
    body: payload,
    endpoint: getURLEndpoint(apis.getUri())
  };
};

const signatureV4CreateCanonicalRequest = (request, config) => {
  config = {
    ...config,
    serviceName: AWS_EXECUTE_API,
    endpoint: request.endpoint,
    region: AWS_REGION,
    defaultContentType: "application/json",
    defaultAcceptType: "application/json"
  };

  let methodType = request.method.toUpperCase();
  let path = request.path || request.url;
  // let queryParams = copy(request.queryParams);
  let queryParams = request.queryParams;
  if (queryParams === undefined) {
    queryParams = {};
  }
  // let headers = copy(request.headers);
  let headers = request.headers;
  if (headers === undefined) {
    headers = {};
  }

  //If the user has not specified an override for Content type the use default
  if (headers["Content-Type"] === undefined) {
    headers["Content-Type"] = config.defaultContentType;
  }

  //If the user has not specified an override for Accept type the use default
  if (headers["Accept"] === undefined) {
    headers["Accept"] = config.defaultAcceptType;
  }

  // let body = apiGateway.core.utils.copy(request.body);
  let body = request.body;
  if (body === undefined || methodType === "GET") {
    // override request body and set to empty when signing GET requests
    body = "";
  } else {
    body = JSON.stringify(body);
  }

  //If there is no body remove the content-type header so it is not included in SigV4 calculation
  if (body === "" || body === undefined || body === null) {
    delete headers["Content-Type"];
  }

  let datetime = new Date()
    .toISOString()
    .replace(/\.\d{3}Z$/, "Z")
    .replace(/[:\\-]|\.\d{3}/g, "");
  headers[X_AMZ_DATE] = datetime;
  let parser = document.createElement("a");
  parser.href = config.endpoint;
  headers[HOST] = parser.hostname;

  const canonicalRequest = buildCanonicalRequest(methodType, path, queryParams, headers, body);
  const hashedCanonicalRequest = hashCanonicalRequest(canonicalRequest);
  const credentialScope = buildCredentialScope(datetime, config.region, config.serviceName);
  const stringToSign = buildStringToSign(datetime, credentialScope, hashedCanonicalRequest);
  const signingKey = calculateSigningKey(
    config.secretAccessKey,
    datetime,
    config.region,
    config.serviceName
  );
  const signature = calculateSignature(signingKey, stringToSign);
  headers[AUTHORIZATION] = buildAuthorizationHeader(
    config.accessKeyId,
    credentialScope,
    headers,
    signature
  );
  if (config.sessionToken !== undefined && config.sessionToken !== "") {
    headers[X_AMZ_SECURITY_TOKEN] = config.sessionToken;
  }
  delete headers[HOST];

  let url = config.endpoint + path;
  const queryString = buildCanonicalQueryString(queryParams);
  if (queryString != "") {
    url += "?" + queryString;
  }

  //Need to re-attach Content-Type if it is not specified at this point
  if (headers["Content-Type"] === undefined) {
    headers["Content-Type"] = config.defaultContentType;
  }

  const signedRequest = {
    method: methodType,
    url: url,
    headers: headers,
    data: body
  };
  return signedRequest;
};

export { createRequestBody };

export default signatureV4CreateCanonicalRequest;
