import { Fragment } from "react";
import {
  Typography,
  Divider,
  Stack
  // Grid
} from "@mui/material";
import { Link } from "react-router-dom";
// import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import {
  // fertilityMsg,
  // fertilityAccessInfo,
  fertilityReasons,
  // fAQs,
  toUSAFormat
} from "../../shared/constants";
// import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const NextSteps = (props) => {
  const { practiceInfo, estimatePublishdate } = props;
  // const [isShown, setIsShown] = useState(false);
  // const [isShownFaq, setIsShownFaq] = useState(false);

  console.log("practiceInfo", practiceInfo);

  // const handleClick = () => {
  //   setIsShown((current) => !current);
  // };
  // const handleClickFaq = () => {
  //   setIsShownFaq((current) => !current);
  // };
  // const style = {
  //   fontWeight: 500,
  //   marginBottom: "30px"
  // };

  const nextdate = (monthnumber) => {
    let dt = new Date();
    if (estimatePublishdate) {
      dt = new Date(estimatePublishdate);
    }
    // console.log("estimatePublishdate", estimatePublishdate);
    let no_of_months = monthnumber || 1;
    //dt.setMonth(dt.getMonth() + no_of_months);
    dt.setDate(dt.getDate() + no_of_months);
    return dt.toLocaleDateString();
  };
  return (
    <Stack orientation="column" className="m-16">
      <Typography variant="body2">NEXT STEPS</Typography>
      <Divider className="h-divider" />

      {/* .
   accepted. For information about our medical financing provider, Capex MD, please click here. */}
      <Typography sx={{ marginBottom: "10px" }}>
        1. Visit{" "}
        <span id="color-primary">
          <Link to={"/pay-your-bill"} target="_blank" className="primary-one">
            Pay your bill
          </Link>
        </span>{" "}
        to review your Invoice(s) and understand your payment options.
      </Typography>

      <Typography sx={{ marginBottom: "10px" }}>
        2. Contact Fertility Access, the providers of the Multi-Cycle Discount and Refund programs,
        regarding any questions you might have regarding the programs.
      </Typography>
      <Typography sx={{ marginBottom: "10px" }}>
        Fertility Access may be contacted at 1-800-873-9203 or at{" "}
        <span id="color-primary">
          <Link
            to={"mailto:PatientConcierge@FertilityAccessProgram.com"}
            target="_blank"
            className="primary-one">
            PatientConcierge@FertilityAccessProgram.com
          </Link>
          {". "}
        </span>{" "}
        Learn more at{" "}
        <span id="color-primary">
          <Link
            to={"http://www.FertilityAccessProgram.com"}
            target="_blank"
            className="primary-one">
            http://www.FertilityAccessProgram.com
          </Link>
          .{" "}
        </span>
      </Typography>

      <Typography sx={{ marginBottom: "20px" }}>
        3. Determine your finance method. Many forms of payment are accepted. For information about
        our medical financing provider, Capex MD, please{" "}
        <Link
          to={"https://secure1.capexmd.com:4443/LoanApplicationSystem/usfapp000.php"}
          target="_blank"
          className="primary-one">
          click here
        </Link>
        .
      </Typography>
      <Typography sx={{ marginBottom: "10px" }}>
        4. Payment is due prior to starting stimulation medications for your cycle.
      </Typography>
      <Typography style={{ marginTop: "10px" }}>
        Please Note: If you select a Fertility Access program, you will receive an email from Adobe
        Acrobat Sign with a link to review and sign your contract. If you opt for single cycle
        treatment, you will pay {(practiceInfo && practiceInfo?.longName) || ""} directly.
      </Typography>
      <Typography style={{ marginTop: "5px" }}>
        {" "}
        If you have any questions, please call {(practiceInfo && practiceInfo?.longName) ||
          ""} at{" "}
        {(practiceInfo && practiceInfo?.phoneNumber && toUSAFormat(practiceInfo?.phoneNumber)) ||
          "-"}{" "}
        or Fertility Access at 800-873-9203.
      </Typography>
      {/* <Typography className="primary-one" style={{ marginTop: "18px", fontSize: "15px" }}>
        Reasons to Choose Fertility Access
      </Typography> */}
      {/* <Stack direction="row" onClick={handleClick} className="my-8 flex-start pointer">
        <TipsAndUpdatesOutlinedIcon color="primary" className="mr-8" />

        <Typography color="primary">
          WHY CHOOSE A FERTILITY ACCESS MULTI-CYCLE DISCOUNT OR REFUND PROGRAM?
        </Typography>

        {isShown ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
      </Stack> */}
      {/* {isShown ? ( */}
      {/* <Typography component="span" className="ml-8">
        {fertilityAccessInfo.split("\n\n").map((paragraph, key) => (
          <p key={key}>
            {paragraph
              .split("\n")
              .reduce((total, line, index) => [total, <br key={index} />, line])}
          </p>
        ))} */}
      {/* {fertilityAccessInfo} */}
      {/* </Typography> */}
      {/* ) : null} */}
      {fertilityReasons.map((item, index) => {
        return (
          <Fragment key={index}>
            <Stack direction="row" spacing={1} py={1}>
              <Typography>{item.id}.</Typography>
              <Typography>
                <span style={{ fontWeight: 500 }}>{item.title + " "}</span>
                {item.info}
              </Typography>
            </Stack>
          </Fragment>
        );
      })}
      {/* <Typography>{fertilityMsg}</Typography> */}
      {/* <Typography sx={{ marginBottom: "10px" }}> */}
      {/* {console.log("callNo", callNo)} */}
      {/* If you have any questions,{" "}
        <Link to="/messages/inbox" className="color-primary">
          send us a message
        </Link>{" "}
        or call the practice at{" "}
        <Link to={"tel://" + callNo} target="_blank" className="color-primary">
          {callNo}
        </Link>{" "} */}
      {/* If you have any questions, please call {(practiceInfo && practiceInfo?.longName) || ""} at{" "}
        {(practiceInfo && practiceInfo?.phoneNumber && toUSAFormat(practiceInfo?.phoneNumber)) ||
          "-"}{" "}
        or Fertility Access at 800-873-9203.
      </Typography> */}

      {/* <Stack direction="row" onClick={handleClickFaq} className="my-8 flex-start pointer">
        <QuestionMarkIcon className="primary-one" />
        <Typography className="primary-one">FERTILITY ACCESS FAQS</Typography>
        {isShownFaq ? (
          <KeyboardArrowUpIcon className="primary-one" />
        ) : (
          <KeyboardArrowDownIcon className="primary-one" />
        )}
      </Stack>
      {isShownFaq
        ? fAQs.map((item, index) => {
            return (
              <Grid key={index} container>
                <Grid xs={8} item py={1}>
                  <Stack className="ml-8">
                    <Typography variant="body2">{item.question}</Typography>
                    <Typography className="my-8">{item.answer}</Typography>
                    <br />
                  </Stack>
                </Grid>
              </Grid>
            );
          })
        : null} */}
      <Stack>
        <Typography
          variant="h8"
          sx={{ marginBottom: "10px", marginTop: "10px" }}
          className="primary-one">
          DISCLAIMERS
        </Typography>
        <Typography>
          This estimate is valid until {nextdate(90)}. Only fees paid to {practiceInfo?.name} for
          services rendered by {practiceInfo?.name} are guaranteed for this time period.{" "}
          {practiceInfo?.name} is not responsible for fee increases related to services or items
          supplied from outside providers, such as medications or anesthesia.
        </Typography>
        {/* <Typography>This estimate is valid until {nextdate(6)}</Typography> */}
        <Typography sx={{ marginBottom: "10px" }}>
          For an updated quote after this date, please call your financial counselor at{" "}
          <Link to={"tel://" + practiceInfo?.phoneNumber} target="_blank" className="primary-one">
            {(practiceInfo && practiceInfo.phoneNumber && toUSAFormat(practiceInfo?.phoneNumber)) ||
              ""}
          </Link>{" "}
          .
        </Typography>
        <Typography sx={{ color: "grey" }}>
          {"  "} * Program success rates are an average of patients who completed a Multi-Cycle
          Discount or Refund program including many who required multiple IVF attempts to have a
          baby.
        </Typography>
      </Stack>
    </Stack>
  );
};
export default NextSteps;
