import * as actionType from "../actions/ActionTypes";

const initialState = {
  // ListOfMessages: {}
  postedFilePath: ""
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LIST_OF_MESSAGES_REQUESTED: {
      return {
        ...state,
        ListOfMessagesLoading: true
      };
    }
    case actionType.LIST_OF_MESSAGES_SUCCESS: {
      if (action.payload.messagesStatus === "Inbox") {
        return {
          ...state,
          ListOfMessages: action.payload.list,
          ListOfMessagesLoading: false
        };
      } else if (action.payload.messagesStatus === "Draft") {
        return {
          ...state,
          ListOfDraftMessages: action.payload.list,
          ListOfMessagesLoading: false
        };
      } else if (action.payload.messagesStatus === "Sent") {
        return {
          ...state,
          ListOfSentMessages: action.payload.list,
          ListOfMessagesLoading: false
        };
      } else if (action.payload.messagesStatus === "Deleted") {
        return {
          ...state,
          ListOfDeletedMessages: action.payload.list,
          ListOfMessagesLoading: false
        };
      }
      break;
    }
    case actionType.LIST_OF_MESSAGES_FAILED: {
      return {
        ...state,
        error: action.message,
        ListOfMessagesLoading: false
      };
    }
    case actionType.CREATE_NEW_MESSAGE_REQUESTED: {
      return {
        ...state,
        sentMessageLoading: true
      };
    }
    case actionType.CREATE_NEW_MESSAGES_SUCCESS: {
      return {
        ...state,
        createMessage: action.payload,
        sentMessageLoading: false,
        successCreateMessage: action.successMsg
      };
    }
    case actionType.CREATE_NEW_MESSAGES_FAILED: {
      return {
        ...state,
        errorCreateMessage: action.errorMsg,
        sentMessageLoading: false
      };
    }
    case actionType.TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED: {
      return {
        ...state,
        toggleLoading: true
      };
    }
    case actionType.TOGGLE_MESSAGE_ISREAD_STATUS_SUCCESS: {
      return {
        ...state,
        toggleLoading: false,
        toggleSuccess: action.successMsg
      };
    }
    case actionType.TOGGLE_MESSAGE_ISREAD_STATUS_FAILED: {
      return {
        ...state,
        toggleError: action.errorMsg,
        toggleLoading: false
      };
    }
    case actionType.DELETE_MESSAGE_REQUESTED: {
      return {
        ...state,
        deletedMessageLoading: true
      };
    }
    case actionType.DELETE_MESSAGE_SUCCESS: {
      return {
        ...state,
        deletedMessage: action.payload,
        deletedMessageLoading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_MESSAGE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        deletedMessageLoading: false
      };
    }

    case actionType.UPDATE_MULTIPLE_MESSAGES_REQUESTED: {
      return {
        ...state,
        updateMessagesLoading: true
      };
    }
    case actionType.UPDATE_MULTIPLE_MESSAGES_SUCCESS: {
      return {
        ...state,
        updateMessagesLoading: false,
        updatedSuccessMsg: action.successMsg
      };
    }
    case actionType.UPDATE_MULTIPLE_MESSAGES_FAILED: {
      return {
        ...state,
        updatedErrorMsg: action.errorMsg,
        updateMessagesLoading: false
      };
    }
    case actionType.GET_FILE_REQUESTED: {
      return {
        ...state,
        fileLoading: true
      };
    }
    case actionType.GET_FILE_SUCCESS: {
      return {
        ...state,
        fileLoading: false,
        downloadedFileUrl: action.payload
      };
    }
    case actionType.GET_FILE_FAILED: {
      return {
        ...state,
        fileLoading: false
      };
    }
    case actionType.POST_FILE_REQUESTED: {
      return {
        ...state,
        postFileLoading: true
      };
    }
    case actionType.POST_FILE_SUCCESS: {
      return {
        ...state,
        postFileLoading: false,
        postedFilePath: action.payload
      };
    }
    case actionType.POST_FILE_FAILED: {
      return {
        ...state,
        postFileLoading: false
      };
    }
    case actionType.RESET_MESSAGES_EXECUTE_STATUS: {
      return {
        ...state,
        updatedSuccessMsg: "",
        successCreateMessage: "",
        errorCreateMessage: "",
        updatedErrorMsg: "",
        sentMessageLoading: false,
        updateMessagesLoading: false,
        postedFilePath: "",
        downloadedFileUrl: ""
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default messageReducer;
