import { Box, Stack, Typography, CircularProgress, Link } from "@mui/material";
import * as actionType from "../../store/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getGengerFullName, toPhoneFormat } from "../../shared/constants";
const PatientInformation = (props) => {
  const { actions, profileData, loading, practiceInfoData } = props;
  const patientId = localStorage.getItem("patientId");
  const practiceId = localStorage.getItem("practiceId");
  const impersonatorId = localStorage.getItem("impersonatorId");
  const cognitoUserId = localStorage.getItem("cognitoUserId");
  useEffect(() => {
    if (!Object.keys(profileData).length) {
      let payload = {
        practiceId,
        patientId
      };
      actions.getProfileData(payload);
    }
    actions.getPracticeInfoData({ practiceId });
  }, []);

  useEffect(() => {
    if (impersonatorId) {
      actions.createImpersonateLog({
        impersonatorId: impersonatorId,
        eventName: "Login In",
        screenName: "Profile Screen",
        eventType: "impersonate",
        ipAddress: "192.168.1.1",
        patientId: cognitoUserId
      });
    }
  }, []);

  return (
    <>
      {loading && (
        <Box>
          <Stack justifyContent="center" p={5} alignItems="center">
            <CircularProgress size={50} />
          </Stack>
        </Box>
      )}

      {!loading && (
        <Box className="p-8">
          <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Name
            </Typography>
            <Stack direction="row">
              <Typography variant="h2">
                {profileData.firstName ? `${profileData.firstName}` : `-`}{" "}
                {profileData.lastName ? `${profileData.lastName}` : `-`}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Date of Birth
            </Typography>
            <Typography variant="body1">
              {profileData.birthDate ? `${profileData.birthDate} ` : `-`}
            </Typography>
          </Stack>
          <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Gender
            </Typography>
            {profileData?.gender && (
              <Typography variant="body1">{getGengerFullName(profileData.gender)}</Typography>
            )}
          </Stack>
          <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Address
            </Typography>
            <Stack direction="row">
              <Typography variant="body1">
                {profileData.mailingAddrLine1 ? `${profileData.mailingAddrLine1}` : ``}{" "}
                {profileData.mailingAddrLine2 ? `${profileData.mailingAddrLine2}` : ``}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body1">
                {profileData.mailingCity ? `${profileData.mailingCity},` : ``}
                {profileData.mailingState ? ` ${profileData.mailingState}` : ``}
                {profileData.mailingZip ? ` ${profileData.mailingZip}` : ``}
              </Typography>
              {/* <Typography variant="body1">
                {profileData.mailingCountry ? `${profileData.mailingCountry}` : ``}
              </Typography> */}
            </Stack>
          </Stack>
          <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Cell Phone
            </Typography>
            <Typography variant="body1">
              {profileData.cellPhone ? toPhoneFormat(profileData.cellPhone) : "-"}
            </Typography>
          </Stack>
          <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Home Phone
            </Typography>
            <Typography variant="body1" className="profile-blue">
              {profileData.homePhone ? toPhoneFormat(profileData.homePhone) : "-"}
            </Typography>
          </Stack>
          <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Other Phone
            </Typography>
            <Typography variant="body1">
              {profileData.workPhone ? toPhoneFormat(profileData.workPhone) : "-"}
            </Typography>
          </Stack>
          <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Email Address
            </Typography>
            <Typography variant="body1">{profileData.email ? profileData.email : "-"}</Typography>
          </Stack>
          {/* <Stack direction="column" className="p-8">
            <Typography variant="caption" className="profile-header">
              Time Zone
            </Typography>
            <Typography variant="body1">
              Appointments are displayed in time zone of your practice:
            </Typography>
            <Typography variant="body1">(UTC -05:00 Eastern Time | USA & Canada)</Typography>
          </Stack> */}
          <Stack direction="column" className="p-8">
            <Typography variant="body1">
              Please contact{" "}
              <span className="profile-blue">
                <Link
                  href={`tel:${practiceInfoData?.phoneNumber}`}
                  target="_blank"
                  underline="hover">
                  <Typography variant="caption" className="profile-blue">
                    {(practiceInfoData &&
                      practiceInfoData?.phoneNumber &&
                      toPhoneFormat(practiceInfoData?.phoneNumber)) ||
                      "-"}
                  </Typography>
                </Link>
              </span>{" "}
              if any of the information above is incorrect.
            </Typography>
          </Stack>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ profileReducer }) => {
  return {
    profileData: profileReducer.profileData,
    practiceInfoData: profileReducer.practiceInfoData,
    loading: profileReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getProfileData: (payload) => {
      dispatch(actionType.getProfileData(payload));
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    },
    getPracticeInfoData: (payload) => {
      dispatch(actionType.getPracticeInfoData(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientInformation);
