import React, { useEffect } from "react";
import { Box, Stack, Typography, Link, Divider, CircularProgress } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import LinkIcon from "@mui/icons-material/Link";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import FaxIcon from "@mui/icons-material/Fax";
import { connect } from "react-redux";
import * as actionType from "../../store/actions";

const PracticeInfo = (props) => {
  const { actions, loading, practiceInfoData } = props;
  const practiceId = localStorage.getItem("practiceId");
  const impersonatorId = localStorage.getItem("impersonatorId");
  const cognitoUserId = localStorage.getItem("cognitoUserId");
  useEffect(() => {
    if (!Object.keys(practiceInfoData).length) {
      let payload = {
        practiceId
      };
      actions.getPracticeInfoData(payload);
    }
  }, [practiceId]);
  useEffect(() => {
    if (impersonatorId) {
      actions.createImpersonateLog({
        impersonatorId: impersonatorId,
        eventName: "Login In",
        screenName: "Practice Profile Screen",
        eventType: "impersonate",
        ipAddress: "192.168.1.1",
        patientId: cognitoUserId
      });
    }
  }, []);
  return (
    <>
      {loading && (
        <Box>
          <Stack justifyContent="center" p={5} alignItems="center">
            <CircularProgress size={50} />
          </Stack>
        </Box>
      )}
      {!loading && (
        <Box className="p-8">
          <Stack direction="column" className="p-8">
            <Typography variant="body1">{practiceInfoData?.longName}</Typography>
            <Divider className="horizontal-row" />
            <Stack direction="row" mt={1}>
              <CallIcon fontSize="small" />
              <Link href={`tel:${practiceInfoData?.phoneNumber}`} target="_blank" underline="hover">
                <Typography variant="caption" ml={2} className="profile-blue">
                  {practiceInfoData?.phoneNumber}
                </Typography>
              </Link>
            </Stack>
            <Stack direction="row" mt={1}>
              <LinkIcon fontSize="small" />
              <Link href={"https://" + practiceInfoData?.website} target="_blank" underline="hover">
                <Typography variant="caption" ml={2} className="profile-blue">
                  {practiceInfoData?.website}
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ profileReducer }) => {
  return {
    practiceInfoData: profileReducer.practiceInfoData,
    loading: profileReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPracticeInfoData: (payload) => {
      dispatch(actionType.getPracticeInfoData(payload));
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticeInfo);
