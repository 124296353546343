import { Box } from "@mui/material";
import "./App.scss";
import AppRoute from "./routes/AppRoute";
import SignUpRoute from "./routes/SignUpRoute";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  // const isSignedIn = localStorage.getItem("tokenId") ? true : false;
  // console.log("isSignedIn", isSignedIn);
  //return <Box className="App">{!isSignedIn ? <SignUpRoute /> : <AppRoute />}</Box>;
  return (
    <Box className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<SignUpRoute />} />
          <Route exact path="*" element={<AppRoute />} />
        </Routes>
      </Router>
    </Box>
  );
};

export default App;
