import * as actionType from "../actions/ActionTypes";

const initialState = {
  pageData: null,

  loading: false,
  error: null
};

const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PAGES_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.PAGES_DATA_SUCCESS: {
      return {
        ...state,
        pageData: action.payload,
        loading: false
      };
    }

    case actionType.PAGES_DATA_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default pagesReducer;
