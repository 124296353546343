import * as actionType from "../actions/ActionTypes";

const initialState = {
  documentList: {},
  contractDocument: {},
  contractLoading: false,
  loading: false,
  error: null,
  contractError: null
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.DOCUMENT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.DOCUMENT_LIST_SUCCESS: {
      return {
        ...state,
        documentList: action.payload,
        loading: false
      };
    }

    case actionType.DOCUMENT_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.CONTRACT_DOCUMENT_REQUESTED: {
      return {
        ...state,
        contractLoading: true
      };
    }
    case actionType.CONTRACT_DOCUMENT_SUCCESS: {
      return {
        ...state,
        contractDocument: action.payload,
        contractLoading: false
      };
    }
    case actionType.CONTRACT_DOCUMENT_FAILED: {
      return {
        ...state,
        contractLoading: false,
        contractError: action.message
      };
    }
    case actionType.RESET_CONTRACT_DATA_REQUESTED: {
      return {
        ...state,
        contractDocument: {},
        contractError: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default documentReducer;
