import * as actionType from "../actions/ActionTypes";

const initialState = {
  caseStatusList: null,
  practiceData: {},
  personData: {},
  userList: {},
  personDataLoading: false,
  loading: false,
  practiceDataLoading: false,
  userListLoading: false,
  error: null
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CASE_STATUSES_REQUESTED: {
      return {
        ...state,
        loading: true,
        error: ""
      };
    }

    case actionType.CASE_STATUSES_SUCCESS: {
      return {
        ...state,
        caseStatusList: action.payload,
        loading: false,
        error: ""
      };
    }

    case actionType.CASE_STATUSES_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.PRACTICE_DATA_REQUESTED: {
      return {
        ...state,
        practiceDataLoading: true,
        error: ""
      };
    }
    case actionType.PRACTICE_DATA_SUCCESS: {
      return {
        ...state,
        practiceData: action.payload,
        practiceDataLoading: false,
        error: ""
      };
    }
    case actionType.PRACTICE_DATA_FAILED: {
      return {
        ...state,
        error: action.message,
        practiceDataLoading: false
      };
    }
    case actionType.PERSON_DATA_REQUESTED: {
      return {
        ...state,
        personDataLoading: true,
        error: ""
      };
    }
    case actionType.PERSON_DATA_SUCCESS: {
      return {
        ...state,
        personData: action.payload,
        personDataLoading: false,
        error: ""
      };
    }
    case actionType.PERSON_DATA_FAILED: {
      return {
        ...state,
        personDataLoading: false,
        error: action.message
      };
    }
    case actionType.USER_LIST_REQUESTED: {
      return {
        ...state,
        userListLoading: true
      };
    }
    case actionType.USER_LIST_SUCCESS: {
      return {
        ...state,
        userList: action.payload,
        userListLoading: false
      };
    }
    case actionType.USER_LIST_FAILED: {
      return {
        ...state,
        userListLoading: false
      };
    }

    case actionType.RESET_CASE_STATUS: {
      return {
        ...state,
        caseStatusList: {},
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default homeReducer;
