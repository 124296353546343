import { Typography } from "@mui/material";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

export const token =
  "eyJraWQiOiJITUhkS2FoQSt0NnU0S3BtZjVNaFwvWGg4R1BUKyt4YllFRlhPNmY0N0pBST0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI5N2FjODM5NS1lNTllLTRlODAtODRkMC00YTU5MGVhMmRkYmEiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMl9ZUExzVWkweFUiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiI3bG80YzVsbmk5czVkdjlocW1iZDlyc2dlaiIsImV2ZW50X2lkIjoiOWY3MTg2ZjQtMTFkYS00MWExLTg3MjEtMTExM2Y1MmMzNjA3IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBwaG9uZSBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTY1NDY3OTA5NywiZXhwIjoxNjU0NjgyNjk3LCJpYXQiOjE2NTQ2NzkwOTgsImp0aSI6Ijc4NDlmY2MzLTI5M2YtNDI5ZC1hOTIzLTExZWFjMGZhN2M2MSIsInVzZXJuYW1lIjoibmlraGlsc3A4NTUifQ.enY9E7o5AT0ueUIebFsZVKgUbfm9K0jZ_2-QehrkAqO6ihv1xk020xXmKZQTQdAT74iPLqEgve8JO_LEqyos473UE7TR2_mZZeV4Jp8QMD9L-t_OsAWsLyLqF9aj3mE3ncwcYi8U4B2VchCz-2KnOmWUp-fLAKBRuVDXjQUDuOijjVCNACW32zmYIndvKAFO4Bh1VFsOyBXLMedTV5TtMALG85rJsqi9IbDf_DgDkWt5iYld3EIwcWF4XPKm-GhrkMxMqCaAvON5Kz0szaWC5lQFUp2Q-InMCkefiWJMIWoNIxp-xMCPm223GGBtHHrNg1eIX4Z2H7OBYczGJiQqHw";

export const loanDisclaimer = `Qualified applicants may be approved for a 24 to 84 month
term with varying APR ranges based upon credit profile. Eligibility for a loan is not
guaranteed. All loan applications are subject to credit review and approval and offered
loan terms depend upon the applicant's credit score, requested amount, requested loan
term, and credit usage and history. Not all borrowers receive the lowest interest rate.
Rates and terms are subject to change at any time without prior notice.`;
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const capitalizeString = (string) => {
  return string.toUpperCase();
};

export const isEmailValid = (email) => {
  //eslint-disable-next-line
  let emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let res = { status: false, msg: "Invalid Email!" };
  if (emailRegEx.test(email)) {
    res.status = true;
    res.msg = "";
  }
  return res;
};
export const isPhoneNumberValid = (phone) => {
  let phoneRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  let res = { status: false, msg: "Invalid phone number." };
  if (phoneRegEx.test(phone)) {
    res.status = true;
    res.msg = "";
  }
  return res;
};

export const calculateAge = (birthDate) => {
  let dob = new Date(birthDate);
  let month_diff = Date.now() - dob.getTime();
  let age_dt = new Date(month_diff);
  let year = age_dt.getUTCFullYear();
  let age = Math.abs(year - 1970);
  return age;
};
export const MSG_TXT1 = `If you need immediate assistance, call  &lt;b&gt;954-247-6200&lt;/b&gt;  or if this is a medical emergency, please call 911.`;
export const MSG_TXT2 =
  "Messaging should be used for rotuine matters only and not for urgent concerns, Messages are monitored and will be responded to by 2 business days.";
export const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];
export const enumUsStates = [
  { value: "AK", name: "Alaska" },
  { value: "TX", name: "Texas" },
  { value: "AL", name: "Alabama" },
  { value: "AR", name: "Arkansas" },
  { value: "AZ", name: "Arizona" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DC", name: "DistrictofColumbia" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "IA", name: "Iowa" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "MA", name: "Massachusetts" },
  { value: "MD", name: "Maryland" },
  { value: "ME", name: "Maine" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MO", name: "Missouri" },
  { value: "MS", name: "Mississippi" },
  { value: "MT", name: "Montana" },
  { value: "NC", name: "NorthCarolina" },
  { value: "ND", name: "NorthDakota" },
  { value: "NE", name: "Nebraska" },
  { value: "NH", name: "NewHampshire" },
  { value: "NJ", name: "NewJersey" },
  { value: "NM", name: "NewMexico" },
  { value: "NV", name: "Nevada" },
  { value: "NY", name: "NewYork" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "RI", name: "RhodeIsland" },
  { value: "SC", name: "SouthCarolina" },
  { value: "SD", name: "SouthDakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VA", name: "Virginia" },
  { value: "VT", name: "Vermont" },
  { value: "WA", name: "Washington" },
  { value: "WI", name: "Wisconsin" },
  { value: "WV", name: "WestVirginia" },
  { value: "WY", name: "Wyoming" }
];

export const MSG = {
  SAVED_SUCCESSFULLY: "Saved Successfully",
  UPDATED_SUCCESSFULLY: "Updated Successfully",
  DELETED_SUCCESSFULLY: "Deleted Successfully",
  APPROVED_SUCCESSFULLY: "Application Approved",
  DENIED_SUCCESSFULLY: "Application Denied",
  NOTE_SAVED: "Note Saved Successfully"
};
export const ACKNOWLEDGE_TEXT =
  "***This Information being sumbitted for the above patient is accurate and complete to the best of my knowlegde.***";
export const externalIdoptions = ["NextGen", "Fert"];
export const prsWarning =
  "You will not able to edit the data entered above once saved, do you still want to save this data";

export const pgtaOptions = ["Voluntary", "Imposed", "Optout", "NA"];

export const DATE_RANGE = ["15", "30", "45", "60", "90", "120", "150", "180"];

export const ESTIMATE_STATUS = ["In-Progress", "Published", "Expired", "Withdraw"];
export const ESTIMATE_TYPE = [
  { label: "Not Estimated", value: "No Estimates" },
  { label: "Detailed Estimate", value: "D" },
  { label: "Preliminary Estimate", value: "P" }
];
export const REFUND_APPLICATION_STATUS = [
  "Incomplete",
  "Withdrawn",
  "Submitted Approved",
  "Submitted Denied",
  "Submitted Need Clinical Review"
];
export const MEDCASE_STATUS = [
  "All",
  "Application Withdrawn",
  "Clinical Review",
  "Contingency Approval",
  "Enrolled",
  "Incomplete",
  "Inquiry",
  "US Fertility Withdraw",
  "Medically Cleared",
  "Not Enrolled",
  "Online Application",
  "Patient Withdraw",
  "Program Complete Successful",
  "Program Complete Unsuccessful",
  "Re-Enrolled",
  "Rejected Incomplete",
  "Rejected not medically appropriate"
];
export const getQueryString = (obj) => {
  let str = "";
  for (var key in obj) {
    if (obj[key] != "" && obj[key] != "default") {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + obj[key];
    }
  }
  return "?" + str;
};

export const GLOBAL_DATA_FORM = {
  category: "G",
  type: "",
  item: "",
  amountType: "$",
  amount: "",
  comment: ""
};

export const LINE_ITEM_FORM = {
  category: "LI",
  type: "",
  item: "",
  amountType: "$",
  amount: "",
  comment: ""
};

export const SUPPLEMENT_FORM = {
  category: "S",
  type: "",
  item: "",
  amountType: "$",
  amount: "",
  comment: ""
};

export const fapCardsStore = {
  programVisualization: [
    {
      boxNumber: "PC-9",
      boxName: "Refund Program",
      createdBy: null,
      programs: [
        {
          programId: "PI-27",
          programName: "1 cycle refund",
          noOfCycle: 2,
          refund: false,
          effectiveDate: null,
          programPrice: 9000
        },
        {
          programId: "PI-28",
          programName: "2 cycle refund",
          noOfCycle: 2,
          refund: false,
          effectiveDate: null,
          programPrice: 9455
        },
        {
          programId: "PI-28",
          programName: "2 cycle refund",
          noOfCycle: 2,
          refund: false,
          effectiveDate: null,
          programPrice: 11100
        }
      ]
    },
    {
      boxNumber: "PC-5",
      boxName: "Non-Refund Program",
      createdBy: null,
      programs: [
        {
          programId: "PI-12",
          programName: "1 cycle  refund",
          noOfCycle: 1,
          refund: false,
          effectiveDate: null,
          programPrice: 6167
        },
        {
          programId: "PI-12",
          programName: "2 cycle  refund",
          noOfCycle: 1,
          refund: false,
          effectiveDate: null,
          programPrice: 7500
        }
      ]
    },

    {
      boxNumber: null,
      boxName: "Single cycle",
      createdBy: null,
      programs: [
        {
          programId: null,
          programName: "1 cycle",
          noOfCycle: 1,
          refund: false,
          effectiveDate: null,
          programPrice: 18100
        },
        {
          programId: null,
          programName: "2 cycle",
          noOfCycle: 3,
          refund: false,
          effectiveDate: null,
          programPrice: 36100
        },
        {
          programId: null,
          programName: "3 cycle",
          noOfCycle: 3,
          refund: false,
          effectiveDate: null,
          programPrice: 54100
        }
      ]
    }
  ],
  globalPriceOfFet: 1600,
  discountedGlobalTpPrice: 4000
};

export const withdrawOptions = [
  "Pay Per Cycle Selected",
  "No Refund MCP Selected",
  "Insurance Coverage",
  "Does not Meet Criteria",
  "Patient Opted out"
];

export const fapViewProgram = {
  "Refund Program": {
    programSummary: "Bring home a baby or receive a refund",
    discountStr: "Significantly discounted bundled pricing",
    programLabel: "Unlimited Frozen Transfers"
  },
  "Non-Refund Program": {
    programSummary: "Embryo banking allowed",
    discountStr: "Discounted bundled pricing",
    programLabel: "Unlimited Frozen Transfers"
  },
  "Single cycle": {
    programSummary: "Pay for treatments as needed",
    discountStr: "Discounted bundled pricing unavailable",
    programLabel: "1 Frozen Transfer Per Cycle"
  },
  "Pay Per Cycle": {
    programSummary: "Pay for treatments as needed",
    discountStr: "Discounted bundled pricing unavailable",
    programLabel: "1 Frozen Transfer Per Cycle"
  }
};
export const APPLY_LOAN =
  "https://secure1.capexmd.com:4443/LoanApplicationSystem/usf-prequalification-request.php";

export const programBoxName = {
  SINGLE_CYCLE: "Single cycle",
  REFUND_PROGRAM: "Refund Program",
  NON_REFUND_PROGRAM: "Non-Refund Program"
};

const boxContainerData = [
  { programCount: 4, minHeight: 230 },
  { programCount: 5, minHeight: 280 },
  { programCount: 6, minHeight: 330 }
];
export const getBarContainerHeight = (programLength) => {
  let height;
  boxContainerData.map((item) => {
    if (item.programCount == programLength) {
      height = item.minHeight;
    }
  });
  return height;
};

export const publishToPatientConstants = {
  REFUND_MISSING: "Refund Application Missing",
  PRICE_CHANGE:
    "Price Change Alert! Prices have changed in the database after you have created this estimate. Please review the new price before publishing this estimate"
};

export const editProgramConstants = {
  question: "Do you plan to discuss the refund options for this treatment plan with the patient?*",
  reasonLabel: "Reason for not discussing refund options for this treatment*",
  noteForNo:
    "Selecting this option will disable the ability to submit a refund application for this treatment plan. You can always change your selection later or submit a refund application for another plan.",
  radioOptions: [
    { label: "Yes, Show all Fertility Access Programs", value: "Yes" },
    { label: "No, Hide Fertility Access Programs", value: "No" }
  ],
  reasonOptions: ["Do not meet clinical requirements", "Patient opted out", "Other"]
};

export const OUTCOME_OPTION_DATA = {
  Pregnant: ["Single", "Twins", "Triplet", "Other"],
  "Non Pregnant": ["Miscarriage", "Biochemical", "Ectopic", "Freeze All"],
  "Cycle Cancelled": [
    "Patient paid for monitoring or IUI",
    "Fertility Access paying for monitoring or IUI",
    "Services were performed",
    "No services were performed"
  ]
};

export const OUTCOME_DATA = ["Pregnant", "Non Pregnant", "Cycle Cancelled"];
export const ADITIONAL_SERVICES = [
  { name: "pgt", label: "PGT" },
  { name: "icsi", label: "ICSI" }
];
export const REQUESTED_SERVICES = [
  { value: "Retrieval", label: "Retrieval" },
  { value: "FET", label: "FET" }
];
export const refundApplicationConstants = {
  status: {
    name: "Refund Application Status",
    values: ["Incomplete", "Submitted Need clinical review"]
  },
  daysSinceCreation: {
    name: "Results within last",
    values: ["10", "30", "90", "180", "All"]
  }
};

export const medCasePatientForm = {
  personKey: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  defaultPhone: "",
  phoneNumber: "",
  email: "",
  defaultAddress: "mailing",
  addressLine1: "",
  city: "",
  addressLine2: "",
  state: "",
  zip: "",
  mailingCity: "",
  mailingState: "",
  mailingZip: "",
  mailingAddrLine1: "",
  mailingAddrLine2: "",
  billingAddrLine1: "",
  billingAddrLine2: "",
  billingCity: "",
  billingState: "",
  billingZip: "",
  cellPhone: "",
  homePhone: "",
  workPhone: ""
};
export const medCasePartnerForm = {
  firstName: "",
  lastName: "",
  birthDate: "",
  defaultPhone: "",
  phoneNumber: "",
  email: "",
  defaultAddress: "mailing",
  addressLine1: "",
  city: "",
  addressLine2: "",
  state: "",
  zip: "",
  mailingCity: "",
  mailingState: "",
  mailingZip: "",
  mailingAddrLine1: "",
  mailingAddrLine2: "",
  billingAddrLine1: "",
  billingAddrLine2: "",
  billingCity: "",
  billingState: "",
  billingZip: "",
  cellPhone: "",
  homePhone: "",
  workPhone: ""
};

export const formattedDate = (str) => {
  let a = str;
  const year = a.slice(0, 4);
  const month = a.slice(4, 6);
  const day = a.slice(6, 8);
  return year + "/" + month + "/" + day;
};

export const convertStringToDate = (formattedDate) => {
  return new Date(formattedDate).toLocaleDateString();
};

export const handleValidityBtn = (
  values,
  partnerValues,
  isActive,
  practiceId,
  isManual,
  patientActive
) => {
  let isValid =
    values.firstName !== "" &&
    values.lastName !== "" &&
    values.birthDate !== "" &&
    values.email !== "" &&
    values.addressLine1 !== "" &&
    values.city !== "" &&
    values.state !== "" &&
    values.zip !== "";
  if (isActive) {
    let isValidPatient =
      partnerValues.firstName !== "" &&
      partnerValues.lastName !== "" &&
      partnerValues.birthDate !== "" &&
      partnerValues.email !== "" &&
      partnerValues.addressLine1 !== "" &&
      partnerValues.city !== "" &&
      partnerValues.state !== "" &&
      partnerValues.zip !== "";

    return isValid && isValidPatient && practiceId != "" && !isManual
      ? !patientActive.activePartner?.active
        ? true
        : false
      : isValid && isValidPatient && practiceId
      ? true
      : false;
  }
  return isValid && practiceId != "" && !isManual && !isActive
    ? false
    : isValid && practiceId != ""
    ? true
    : false;
};

export const patientPayload = (values) => {
  return {
    personKey: uuidv4(),
    firstName: values.firstName,
    lastName: values.lastName,
    defaultPhone: values.defaultPhone,
    email: values.email,
    defaultAddress: values.defaultAddress,
    cellPhone: values.defaultPhone == "cell" ? values.phoneNumber : null,
    homePhone: values.defaultPhone == "home" ? values.phoneNumber : null,
    workPhone: values.defaultPhone == "work" ? values.phoneNumber : null,
    mailingAddrLine1: values.defaultAddress == "mailing" ? values.addressLine1 : "",
    mailingAddrLine2: values.defaultAddress == "mailing" ? values.addressLine2 : "",
    mailingCity: values.defaultAddress == "mailing" ? values.city : "",
    mailingState: values.defaultAddress == "mailing" ? values.state : "",
    mailingZip: values.defaultAddress == "mailing" ? values.zip : "",
    billingAddrLine1: values.defaultAddress == "billing" ? values.addressLine1 : "",
    billingAddrLine2: values.defaultAddress == "billing" ? values.addressLine2 : "",
    billingCity: values.defaultAddress == "billing" ? values.city : "",
    billingState: values.defaultAddress == "billing" ? values.state : "",
    billingZip: values.defaultAddress == "billing" ? values.zip : "",
    birthDate: new Date(values.birthDate).toLocaleDateString()
  };
};

export const partnerPayload = (values) => {
  return {
    personKey: uuidv4(),
    firstName: values.firstName,
    lastName: values.lastName,
    birthDate: new Date(values.birthDate).toLocaleDateString(),
    defaultPhone: values.defaultPhone == "" ? null : values.defaultPhone,
    email: values.email,
    defaultAddress: values.defaultAddress,
    cellPhone: values.defaultPhone == "cell" ? values.phoneNumber : null,
    homePhone: values.defaultPhone == "home" ? values.phoneNumber : null,
    workPhone: values.defaultPhone == "work" ? values.phoneNumber : null,
    mailingAddrLine1: values.defaultAddress == "mailing" ? values.addressLine1 : null,
    mailingAddrLine2: values.defaultAddress == "mailing" ? values.addressLine2 : null,
    mailingCity: values.defaultAddress == "mailing" ? values.city : null,
    mailingState: values.defaultAddress == "mailing" ? values.state : null,
    mailingZip: values.defaultAddress == "mailing" ? values.zip : null,
    billingAddrLine1: values.defaultAddress == "billing" ? values.addressLine1 : null,
    billingAddrLine2: values.defaultAddress == "billing" ? values.addressLine2 : null,
    billingCity: values.defaultAddress == "billing" ? values.city : null,
    billingState: values.defaultAddress == "billing" ? values.state : null,
    billingZip: values.defaultAddress == "billing" ? values.zip : null
  };
};

export const defaultPracticeValues = {
  linkMedcaseId: "",
  pmId: "",
  tpId: "",
  contactId: "",
  physicianId: "",
  locationId: "",
  practiceId: "",
  patientId: "",
  isAutogenerate: 0,
  isAssist: false,
  isActive: 1
};
const xsValue = [
  {
    len: 3,
    value: 4
  },
  {
    len: 2,
    value: 6
  },
  {
    len: 1,
    value: 12
  }
];
export const getXsValue = (len, mcpShowEstimate, isRefundProgram) => {
  let val;
  if (!mcpShowEstimate && isRefundProgram) {
    len = len - 1;
  }
  xsValue.map((item) => {
    if (item.len == len) {
      val = item.value;
    }
  });
  return val;
};

export const removeObjParameters = (data) => {
  const clinicalStages = data.clinicalTreatmentStages;
  clinicalStages.map((ele) => {
    (ele.dateRecorded =
      ele.dateRecorded && moment(new Date(ele.dateRecorded)).format("yyyy-MM-DD")),
      delete ele.disabled;
  });
  const payload = {
    authDate: data.authDate && moment(new Date(data.authDate)).format("yyyy-MM-DD"),
    performedTp: data.performedTp,
    forecastDate: data.forecastDate && moment(new Date(data.forecastDate)).format("yyyy-MM-DD"),
    outcomeCategory: data.outcomeCategory,
    outcomeDescription: data.outcomeDescription,
    outcomeDate: data.outcomeDate && moment(new Date(data.outcomeDate)).format("yyyy-MM-DD"),
    edcDate: data.edcDate && moment(new Date(data.edcDate)).format("yyyy-MM-DD"),
    miscarrigeDate:
      data.miscarrigeDate && moment(new Date(data.miscarrigeDate)).format("yyyy-MM-DD"),
    clinicalTreatmentStages: clinicalStages,
    clinicalTreatmentAdditionalField: {
      pgt: data.clinicalTreatmentAdditionalField.pgt,
      icsi: data.clinicalTreatmentAdditionalField.icsi,
      eggFreezing: data.clinicalTreatmentAdditionalField.eggFreezing || "",
      noOfFrozonEgg:
        data.clinicalTreatmentAdditionalField.noOfFrozonEgg == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.noOfFrozonEgg,
      transferDate:
        data.clinicalTreatmentAdditionalField.transferDate &&
        moment(new Date(data.clinicalTreatmentAdditionalField.transferDate)).format("yyyy-MM-DD"),
      frozenEmbryo:
        data.clinicalTreatmentAdditionalField.frozenEmbryo == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.frozenEmbryo,
      transferedEmbryo:
        data.clinicalTreatmentAdditionalField.transferedEmbryo == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.transferedEmbryo,
      remainingEmbryo:
        data.clinicalTreatmentAdditionalField.remainingEmbryo == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.remainingEmbryo,
      normalFrozenEmbroysRemaining:
        data.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining == "-"
          ? 0
          : data.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining,
      clinicalDecision: data.clinicalTreatmentAdditionalField.clinicalDecision,
      requestedService: data.clinicalTreatmentAdditionalField.requestedService,
      cycleStartDay:
        data.clinicalTreatmentAdditionalField.cycleStartDay &&
        moment(new Date(data.clinicalTreatmentAdditionalField.cycleStartDay)).format("yyyy-MM-DD"),
      comment: data.clinicalTreatmentAdditionalField.comment
    }
  };
  return payload;
};
const gender = { F: "Female", M: "Male" };
export const getGengerFullName = (sex) => {
  return gender[sex];
};
export const isNameValid = (name) => {
  return !/\d/.test(name);
};

export const patientFormValues = {
  personId: uuidv4(),
  firstName: "",
  lastName: "",
  streetLine1: "",
  streetLine2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  cellPhone: "",
  homePhone: "",
  workPhone: "",
  additionalPhone: "",
  gender: "",
  birthDate: "",
  email: "",
  practiceId: "",
  defaultAddress: "",
  defaultPhone: "",
  phoneNumber: "",
  locationId: "",
  physicianId: "",
  contactId: "",
  activePartner: {}
};
export const patientFormPayload = (values, activePartner) => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    mailingAddrLine1: values.defaultAddress == "mailing" ? values.streetLine1 : "",
    mailingAddrLine2: values.defaultAddress == "mailing" ? values.streetLine2 : "",
    mailingCity: values.defaultAddress == "mailing" ? values.city : "",
    mailingState: values.defaultAddress == "mailing" ? values.state : "",
    mailingZip: values.defaultAddress == "mailing" ? values.zip : "",
    mailingCountry: values.defaultAddress == "mailing" ? values.country : "",
    billingAddrLine1: values.defaultAddress == "billing" ? values.streetLine1 : "",
    billingAddrLine2: values.defaultAddress == "billing" ? values.streetLine2 : "",
    billingCity: values.defaultAddress == "billing" ? values.city : "",
    billingState: values.defaultAddress == "billing" ? values.state : "",
    billingZip: values.defaultAddress == "billing" ? values.zip : "",
    billingCountry: values.defaultAddress == "billing" ? values.country : "",
    cellPhone: values.defaultPhone == "cell" ? values.cellPhone : "",
    homePhone: values.defaultPhone == "home" ? values.homePhone : "",
    workPhone: values.defaultPhone == "work" ? values.workPhone : "",
    additionalPhone: values.defaultPhone == "additional" ? values.additionalPhone : "",
    gender: values.gender,
    birthDate: values.birthDate,
    email: values.email,
    practiceId: values.practiceId,
    defaultAddress: values.defaultAddress,
    defaultPhone: values.defaultPhone,
    activePartner: {
      active: activePartner === "Yes" ? true : false
    }
  };
};

export const refundApplicationValidation = (name, value) => {
  if (!isNaN(value)) {
    if (value < 0) {
      return true;
    }
    if (name === "raMaxAMH") {
      if (value > 50) {
        return true;
      }
    }
    if (name === "raE2") {
      if (value > 2000) {
        return true;
      }
    }
    if (name === "raFSH") {
      if (value > 100) {
        return true;
      }
    }
    if (name === "raPrevMiscarriage") {
      if (value > 15) {
        return true;
      }
    }
  }
  return false;
};

export const msgConfig = [
  {
    title: "INBOX",
    url: "/messages/inbox",
    text: "Inbox",
    icon: "/Assets/payment.png"
  },
  {
    title: "DRAFTS",
    url: "/messages/drafts",
    text: "Drafts",
    icon: "/Assets/payment.png"
  },
  {
    title: "SENT",
    url: "/messages/sent",
    text: "Sent",
    icon: "/Assets/payment.png"
  },

  {
    title: "DELETED",
    url: "/messages/deleted",
    text: "Deleted",
    icon: "/Assets/payment.png"
  }
];
export const CLINICAL_DECISION = ["Approved", "Pending"];

export const getOutcomeFormData = (data) => {
  let formData = {
    clinicalTreatmentId: data?.clinicalTreatmentId || "",
    authDate: data?.authDate,
    performedTp: data?.performedTp || "",
    performedTpName: data?.performedTpName || "",
    authorizedTpName: data?.authorizedTpName || "",
    forecastDate: data?.forecastDate,
    outcomeCategory: data?.outcomeCategory,
    outcomeDescription: data?.outcomeDescription,
    outcomeDate: data?.outcomeDate,
    edcDate: data?.edcDate,
    miscarrigeDate: data?.miscarrigeDate,
    program: data?.program || "",
    authExpDate: data?.authExpDate,
    enrollmentDate: data?.enrollmentDate,
    clinicalTreatmentNo: data?.clinicalTreatmentNo || "",
    clinicalCycleNo: data?.clinicalCycleNo || "",
    requestedBy: data?.requestedBy || "",
    user: data?.user || "",
    requestedDate: data?.requestedDate,
    gdmId: data?.gdmId || "",
    clinicalTreatmentStages: data?.clinicalTreatmentStages,
    clinicalTreatmentAdditionalField: {
      clinicalTreatmentId: data?.clinicalTreatmentAdditionalField.clinicalTreatmentId || "",
      pgt: data?.clinicalTreatmentAdditionalField.pgt || "",
      icsi: data?.clinicalTreatmentAdditionalField.icsi || "",
      eggFreezing: data?.clinicalTreatmentAdditionalField.eggFreezing
        ? data?.clinicalTreatmentAdditionalField.eggFreezing
        : false,
      noOfFrozonEgg: parseInt(data?.clinicalTreatmentAdditionalField.noOfFrozonEgg),
      transferDate: data?.clinicalTreatmentAdditionalField.transferDate,
      frozenEmbryo: parseInt(data?.clinicalTreatmentAdditionalField.frozenEmbryo),
      transferedEmbryo: parseInt(data?.clinicalTreatmentAdditionalField.transferedEmbryo),
      remainingEmbryo: parseInt(data?.clinicalTreatmentAdditionalField.remainingEmbryo),
      normalFrozenEmbroysRemaining: parseInt(
        data?.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining
      ),
      clinicalDecision: data?.clinicalTreatmentAdditionalField.clinicalDecision,
      requestedService: data?.clinicalTreatmentAdditionalField.requestedService,
      cycleStartDay: data?.clinicalTreatmentAdditionalField.cycleStartDay,
      comment: data?.clinicalTreatmentAdditionalField.comment || ""
    }
  };
  return formData;
};
export const InitialValuesForMessage = {
  messageId: null,
  conversationId: null,
  subject: "",
  body: "",
  type: null,
  fromUserId: "",
  assignedTo: null,
  status: "",
  hasAttachment: false,
  notes: null,
  srcMessageId: null,
  sentDate: null,
  updationDate: null,
  messageAttachment: [],
  messageRecipient: []
};

// export const clinicalTreatmentOutcomeFormData = [
//   {
//     clinicalTreatmentId: "",
//     authDate: "",
//     performedTp: "",
//     performedTpName: "default",
//     authorizedTpName: "",
//     forecastDate: "",
//     outcomeCategory: "",
//     outcomeDescription: "",
//     outcomeDate: "",
//     edcDate: "",
//     miscarrigeDate: "",
//     program: "",
//     authExpDate: "",
//     enrollmentDate: "",
//     clinicalTreatmentNo: "",
//     clinicalCycleNo: "",
//     requestedBy: "string",
//     user: "string",
//     requestedDate: "",
//     gdmId: "",
//     clinicalTreatmentStages: [
//       {
//         stageId: "",
//         stageName: "",
//         stageCompleted: true,
//         stageCancelled: true,
//         dateRecorded: ""
//       }
//     ],
//     clinicalTreatmentAdditionalField: {
//       clinicalTreatmentId: "",
//       pgt: false,
//       icsi: false,
//       eggFreezing: false,
//       noOfFrozonEgg: 0,
//       transferDate: "",
//       frozenEmbryo: 0,
//       transferedEmbryo: 0,
//       remainingEmbryo: 0,
//       normalFrozenEmbroysRemaining: 0,
//       clinicalDecision: "",
//       requestedService: "",
//       cycleStartDay: "",
//       comment: ""
//     }
//   }
// ];

export const handleValidateSaveOutcome = (values, clinicalTreatmentId) => {
  let data = values?.filter((item) => {
    return item.clinicalTreatmentId === clinicalTreatmentId;
  })[0];
  let isValid;
  if (
    data.outcomeCategory == "Pregnant" &&
    data.outcomeDescription !== null &&
    data.outcomeDate !== null
    //data.clinicalTreatmentAdditionalField.clinicalDecision !== null
  ) {
    isValid = true;
  } else if (
    data.outcomeCategory == "Not Pregnant" &&
    data.outcomeDescription == "Miscarriage" &&
    data.miscarrigeDate !== null
    //data.clinicalTreatmentAdditionalField.clinicalDecision !== null
  ) {
    isValid = true;
  } else if (
    data.outcomeCategory !== null &&
    data.outcomeDescription !== null &&
    data.outcomeDate !== null
    //data.clinicalTreatmentAdditionalField.clinicalDecision !== null
  ) {
    isValid = true;
  } else {
    isValid = false;
  }

  let stagesChange = data.clinicalTreatmentStages.filter(
    (ele) => ele.stageCompleted != false || ele.stageCancelled != false || ele.dateRecorded != null
  );
  let transferStageChange = stagesChange.filter((ele) => ele.stageName == "Transfer");
  let validateTransferDate =
    transferStageChange?.length > 0
      ? transferStageChange[0].stageCompleted != false &&
        transferStageChange[0].stageCancelled != false &&
        data.clinicalTreatmentAdditionalField.transferDate !== null &&
        data.clinicalTreatmentAdditionalField.frozenEmbryo !== "-" &&
        data.clinicalTreatmentAdditionalField.transferedEmbryo !== "-" &&
        data.clinicalTreatmentAdditionalField.remainingEmbryo !== "-" &&
        data.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining !== "-"
      : true;

  // stagesChange?.length > 0
  //   ? stagesChange[0].stageName == "Transfer"
  //     ? data.clinicalTreatmentAdditionalField.stageCompleted != false &&
  //       data.clinicalTreatmentAdditionalField.stageCancelled != false &&
  //       data.clinicalTreatmentAdditionalField.transferDate !== null &&
  //       data.clinicalTreatmentAdditionalField.frozenEmbryo !== "-" &&
  //       data.clinicalTreatmentAdditionalField.transferedEmbryo !== "-" &&
  //       data.clinicalTreatmentAdditionalField.remainingEmbryo !== "-" &&
  //       data.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining !== "-"
  //       ? true
  //       : false
  //     : true
  //   : true;

  // let clinicalDecisionValidate =
  //   data.clinicalTreatmentAdditionalField.clinicalDecision == "Approved"
  //     ? data.clinicalTreatmentAdditionalField.cycleStartDay !== null
  //       ? true
  //       : false
  //     : data.clinicalTreatmentAdditionalField.clinicalDecision !== null
  //     ? true
  //     : false;
  //let isStageValid = true;
  //   stagesChange?.length > 0 && stagesChange[0]?.stageName == "Transfer"
  //     ? data.clinicalTreatmentAdditionalField.frozenEmbryo !== "-" &&
  //       data.clinicalTreatmentAdditionalField.transferedEmbryo !== "-" &&
  //       data.clinicalTreatmentAdditionalField.remainingEmbryo !== "-" &&
  //       data.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining !== "-"
  //     : false;
  // console.log("isStageValid", isStageValid);
  return isValid == true && stagesChange.length > 0 && validateTransferDate
    ? //clinicalDecisionValidate == true &&
      //validateTransferDate == true
      false
    : true;
};
export const handleEnableTransferDate = (values, clinicalTreatmentId) => {
  let data = values?.filter((item) => {
    return item.clinicalTreatmentId === clinicalTreatmentId;
  })[0];

  let stagesChange = data?.clinicalTreatmentStages.filter(
    (ele) =>
      ele.stageName == "Transfer" &&
      ele.stageCompleted != false &&
      ele.stageCancelled != false &&
      ele.dateRecorded != null
  );
  let validateTransferDate =
    stagesChange?.length > 0 ? (stagesChange[0]?.stageName == "Transfer" ? true : false) : false;
  return validateTransferDate == true ? false : true;
};
export const getURLEndpoint = (url) => {
  return /(^https?:\/\/[^\\/]+)/g.exec(url)[1];
};
export const pathComponent = (invokeUrl) => {
  return invokeUrl.substring(getURLEndpoint(invokeUrl).length);
};

export const formatPhoneNumber = (str) => {
  var cleaned = ("" + str).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const termsOfUse = {
  txt: "IntegraMed Patient Portal Terms of Use",
  url: "http://localhost:3000/terms-and-conditions"
};

export const privacyPolicy = {
  txt: "IntegraMed Patient Portal Privacy Policy",
  url: "http://localhost:3000/privacy-policy"
};

export const AcceptAndRegister = () => {
  return (
    <Typography>
      By clicking &quot;Accept and Register&quot; you acknowledege that you have read and agree to
      our{" "}
      <Link className="link-color" to={"/terms-and-conditions"}>
        {"Financial Portal Terms of Use and Privacy Policy"}
      </Link>
      .
      {/* <Link className="link-color" to={termsOfUse.url}>
        {termsOfUse.txt}
      </Link>{" "}
      and the{" "}
      <Link className="link-color" to={privacyPolicy.url}>
        {privacyPolicy.txt}
      </Link>
      and {privacyPolicy.txt}. */}
    </Typography>
  );
};

export const registerSuccessMsg = {
  heading: "Registered Successfully!",
  msgBody: "You have successfully created your account. Click OK to log in"
};

export const registerErrorMsg = {
  heading: "Registration Unsuccessful!",
  msgBody: "Your registration was unsuccessful "
};

export const invalidMsg = {
  heading: "Invalid Inputs",
  msgBody: "Patient Number or  Date of Birth is invalid"
};

export const continueReg =
  "Continue the registration process by filling out the information below to finish enrolling for the patient portal.";

export const usernameText =
  "Choose a username to use to login to patient portal. You can use the suggested username below or change it to something more memorable. Note that you will not be able to change this username later.";

export const InitRegistrationTxt = () => {
  return (
    <Typography>
      To create a financial portal account, please enter your patient number and date of birth and
      click {"<Continue>"}. Once you verify your data, you will be prompted to create a secure
      password. Should you have any questions, please contact your fertility center.
      {/* Enter your Patient Number and Date of Birth so we can validate your account. You should have
      received your patient number after your first visit. If you do not have your patient number,
      call <span className="span-text-color"> 954-247-6200</span> */}
    </Typography>
  );
};
