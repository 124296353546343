import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  InputLabel,
  Link,
  Stack,
  Typography
} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { CheckBoxSharp, DisabledByDefaultOutlined } from "@mui/icons-material";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  programBoxName,
  APPLY_LOAN,
  getXsValue,
  fapViewProgram,
  loanDisclaimer,
  getBarContainerHeight
} from "../../shared/constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = {
  page: {
    backgroundColor: "#FFF",
    paddingHorizontal: "30px",
    paddingVertical: "50px",
    fontFamily: "Roboto"
  },

  text_regular: {
    fontSize: "14px",
    fontWeight: 400,
    width: "100%",
    textAlign: "center"
  },

  row: {
    display: "flex",
    flexDirection: "row"
  },
  row_new: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },

  fap_header: {
    backgroundColor: "#ECF7F7",
    width: "100%",
    textAlign: "center"
  },
  fap_logo: {
    marginVertical: 20
    //width: "150px",
    //alignSelf: "center"
  },
  updatedColor: {
    color: "#1BA3AD !important"
  }
};

const FapView = (props) => {
  const fapCardsStore = useSelector((state) => state.estimateReducer.fapList);
  const { setBoxName, clearBox, cardData, /* getProgramId, */ handleProgramSelect } = props;
  const [isRefundProgram, setIsRefundProgram] = useState(false);
  const [isNonRefundProgram, setIsNonRefundProgram] = useState(false);
  const [isSingleCycle, setIsSingleCycle] = useState(false);
  const [isSingleCycleSelected, setPerCycleBoxSeleted] = useState(false);
  const [xsValue, setXsValue] = useState(4);
  const [fapData, setFapData] = useState({});
  const [barContainerHeight, setBarContainerHeight] = useState();

  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (clearBox) {
      setBoxName("");
      const updatedPrograms = fapCardsStore.programVisualization.map((item) => ({
        ...item,
        selected: false,
        programs: item.programs.map((item) => ({
          ...item,
          selected: false
        }))
      }));
      setFapData({ ...fapCardsStore, programVisualization: updatedPrograms });
    }
  }, [clearBox]);
  useEffect(() => {
    if (fapCardsStore?.programVisualization) {
      setFapData(fapCardsStore);
      fapCardsStore.programVisualization.map((item) => {
        if (item.boxName == programBoxName.PAY_PER_CYCLE && item.programs.length > 3) {
          let height = getBarContainerHeight(item.programs.length);
          setBarContainerHeight(height);
        }
        if (item.boxName == programBoxName.REFUND_PROGRAM) {
          setIsRefundProgram(true);
        }
        if (item.boxName == programBoxName.NON_REFUND_PROGRAM) {
          setIsNonRefundProgram(true);
        }
        if (item.boxName == programBoxName.PAY_PER_CYCLE) {
          setIsSingleCycle(true);
        }
        let count = getXsValue(fapCardsStore?.programVisualization.length);
        setXsValue(count);
      });
    }
  }, [fapCardsStore]);

  const abbreviateNumber = (value) => {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "m", "b", "t"];
      var suffixNum = Math.floor(Math.log10(value) / 3);
      var shortValue = newValue / Math.pow(10, 3 * suffixNum);
      // if (suffixNum > 3) {
      shortValue = shortValue.toFixed(1);
      // }
      return shortValue + suffixes[suffixNum];
    }
    return value;
  };

  const getProgramsPrice = (item) => {
    // return item.programs.length > 1 && item.boxName != programBoxName.PAY_PER_CYCLE
    //   ? "$" +
    //       Math.round((item.programs[0].programPrice / 1000) * 10) / 10 +
    //       "K" +
    //       "-" +
    //       "$" +
    //       Math.round((item.programs[item.programs.length - 1].programPrice / 1000) * 10) / 10 +
    //       "K"
    //   : "$" + Math.round((item.programs[0].programPrice / 1000) * 10) / 10 + "K";
    let programmFess = item && item.programs && item.programs[0] && item.programs[0].programPrice;
    let programRange =
      item &&
      item.programs &&
      item.programs[item.programs.length - 1] &&
      item.programs[item.programs.length - 1].programPrice;
    return item.programs.length > 1 && item.boxName != programBoxName.PAY_PER_CYCLE
      ? "$" + abbreviateNumber(programmFess) + " - " + "$" + abbreviateNumber(programRange)
      : "$" + programmFess.toLocaleString("en");
  };

  const getRetrievals = (item) => {
    return item.boxName != programBoxName.PAY_PER_CYCLE && item.programs.length > 1
      ? item.programs[0].noOfCycle + "-" + item.programs[item.programs.length - 1].noOfCycle
      : item.programs[0].noOfCycle;
  };

  const handleBarClick = (index, barIndex, boxName) => {
    if (boxName.toLocaleLowerCase() === programBoxName.PAY_PER_CYCLE.toLocaleLowerCase()) {
      setPerCycleBoxSeleted(true);
    } else {
      setPerCycleBoxSeleted(false);
    }
    setBoxName("");
    const updatedPrograms = fapCardsStore.programVisualization.map((item) => ({
      ...item,
      selected: false,
      programs: item.programs.map((item) => ({
        ...item,
        selected: false
      }))
    }));
    setFapData({ ...fapCardsStore, programVisualization: updatedPrograms });
    setFapData((prev) => {
      const programVisualization = [...prev.programVisualization];
      programVisualization[index] = { ...programVisualization[index], selected: true };
      const programs = { ...programVisualization[index] };
      programs.programs[barIndex] = { ...programs.programs[barIndex], selected: true };
      return { programVisualization };
    });
  };

  const handleProgramClick = (item, fapCardsStore) => {
    // getProgramId(item);
    // setBoxName(item);
    handleProgramSelect(item, fapCardsStore);
  };

  const renderCards = (item, barDirection, barHeight, alignSelf, index, containerHeight) => {
    return (
      <>
        <Stack className="mt-16  min-h-103">
          <Typography sx={{ my: 1, fontWeight: 500 }}>
            {item.boxName === "Non-Refund Program" ? "Multi-Cycle Discount Program" : item.boxName}
          </Typography>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Typography variant="body2">
                {item.boxName === programBoxName.PAY_PER_CYCLE
                  ? getProgramsPrice(item) + " for 3 IVF + 3 FET cycles "
                  : getProgramsPrice(item) + " for whole program"}
              </Typography>
              {item.boxName == programBoxName.PAY_PER_CYCLE && (
                <Typography variant="body2">
                  {"$" +
                    fapCardsStore.discountedGlobalTpPrice.toLocaleString("en") +
                    " per IVF cycle + " +
                    "$" +
                    fapCardsStore.globalPriceOfFet.toLocaleString("en") +
                    " per FET cycle"}
                </Typography>
              )}
            </Grid>
          </Grid>
          <div style={{ minHeight: 48 }}>
            <InputLabel sx={{ my: 1 }}>
              {(fapViewProgram &&
                fapViewProgram[item.boxName] &&
                fapViewProgram[item.boxName].programLabel1) ||
                ""}
            </InputLabel>
            <InputLabel sx={{ my: 1 }}>
              {(fapViewProgram &&
                fapViewProgram[item.boxName] &&
                fapViewProgram[item.boxName].programLabel) ||
                ""}
            </InputLabel>
          </div>
          {/* <Link
            href={APPLY_LOAN}
            underline="none"
            className="link-text"
            target="_blank"
            sx={{ fontWeight: 500, my: 1 }}>
            Apply for a loan to determine potential monthly costs.
          </Link> */}
        </Stack>
        <Box className="program-summary-container">
          <Stack
            className="program-summary"
            sx={{
              backgroundColor: "#818294",
              color: "#FFF"
            }}>
            <Typography variant="caption" className="font-w-500">
              Program Summary
            </Typography>
            <li>
              <Typography variant="caption" pl={1}>
                {fapViewProgram &&
                  fapViewProgram[item.boxName] &&
                  fapViewProgram[item.boxName].programSummary}
              </Typography>
            </li>
            <li>
              <Typography variant="caption" pl={1}>
                {fapViewProgram &&
                  fapViewProgram[item.boxName] &&
                  fapViewProgram[item.boxName].discountStr}
              </Typography>
            </li>
          </Stack>
          {/* bar-box ${alignSelf} card-border-new */}
          <Box
            className={"bar-container " + barDirection}
            sx={{
              minHeight: barContainerHeight,
              paddingBottom: "3px"
            }}>
            <div
              className={
                (item.boxName.toLowerCase() === programBoxName.PAY_PER_CYCLE.toLowerCase() &&
                  isSingleCycleSelected &&
                  "" + barDirection + " card-border-new") ||
                "bar-container " + barDirection
              }>
              {item.programs.map((barItem, barIndex) => {
                let disableRefundBars = false;
                if (item.boxName != programBoxName.PAY_PER_CYCLE && barIndex != 0) {
                  barHeight = barHeight + 25;
                  containerHeight = containerHeight + 25;
                }
                if (
                  item.boxName === programBoxName.REFUND_PROGRAM &&
                  !(
                    cardData.refundApplicationStatus == "Submitted Approved" ||
                    cardData.refundApplicationStatus == "Medically Cleared"
                  )
                  // (cardData.refundApplicationStatus == "Submitted Denied" ||
                  //   cardData.refundApplicationStatus == "Submitted Need Clinical Review")
                ) {
                  disableRefundBars = true;
                }
                let pNames = [];

                if (
                  barItem &&
                  barItem.programName &&
                  barItem.programName.toLowerCase() == "multi-cycle discount program 2+"
                ) {
                  pNames = ["2 IVF Cycles", "Unlimited FETs"];
                } else if (
                  barItem &&
                  barItem.programName &&
                  barItem.programName.toLowerCase() == "multi-cycle discount program 3+"
                ) {
                  pNames = ["3 IVF Cycles", "Unlimited FETs"];
                } else if (
                  barItem &&
                  barItem.programName &&
                  barItem.programName.toLowerCase() == "50% refund program 2+"
                ) {
                  pNames = ["2 IVF Cycles", "Unlimited FETs", "50% Refund"];
                } else if (
                  barItem &&
                  barItem.programName &&
                  barItem.programName.toLowerCase() == "100% refund program 3+"
                ) {
                  pNames = ["3 IVF Cycles", "Unlimited FETs", "100% Refund"];
                }
                return (
                  <Box
                    flexDirection={"column"}
                    className={`bar-container-box ${alignSelf}`}
                    key={barIndex}
                    sx={{
                      height: containerHeight,
                      width: barItem.programName.length >= 32 ? 85 : 65
                    }}>
                    {item.boxName != programBoxName.PAY_PER_CYCLE ? (
                      <Checkbox
                        icon={
                          <CheckCircleOutlineOutlinedIcon
                            color={barItem.selected ? "primary" : ""}
                          />
                        }
                        checkedIcon={<CheckCircleOutlineOutlinedIcon />}
                        disabled
                      />
                    ) : null}

                    <Box
                      onClick={() => {
                        if (!disableRefundBars) {
                          handleBarClick(index, barIndex, item.boxName);
                        }
                      }}
                      className={
                        disableRefundBars
                          ? `bar-box ${alignSelf} card-border-0 disabled-box`
                          : barItem.selected && !isSingleCycleSelected
                          ? `bar-box ${alignSelf} card-border-new`
                          : `bar-box ${alignSelf} card-border-0`
                      }
                      sx={{
                        height: barHeight,
                        backgroundColor: !disableRefundBars && "#1BA3AD",
                        color: !disableRefundBars && "#FFF"
                      }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ textAlign: "center", fontSize: 13 }}>
                          {"$" + Math.round((barItem.programPrice / 1000) * 10) / 10}K
                        </div>

                        <div>
                          {pNames && pNames.length ? (
                            pNames.map((pName, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    margin: 1,
                                    padding: 1,
                                    fontSize: 10,
                                    justifyContent: "space-between"
                                  }}>
                                  {pName}
                                </div>
                              );
                            })
                          ) : (
                            <div
                              style={{
                                flexDirection: "column",
                                display: "flex",
                                margin: 1,
                                padding: 1,
                                fontSize: 10,
                                justifyContent: "space-between"
                              }}>
                              {barItem.programName}
                            </div>
                          )}
                        </div>
                      </div>
                    </Box>
                  </Box>
                );
              })}
            </div>
          </Box>
          <Stack className="align-center">
            {
              <Button
                fullWidth
                onClick={() => handleProgramClick(item, fapCardsStore)}
                variant="contained"
                disabled={item.selected ? false : true}
                className="program-btn"
                sx={{ backgroundColor: "#1BA3AD", color: "#FFF" }}>
                {item.boxName == programBoxName.PAY_PER_CYCLE
                  ? "Select Single Cycle "
                  : item.boxName == programBoxName.REFUND_PROGRAM
                  ? "Select The Refund Program"
                  : "Select The Multi-Cycle Discount Program"}
              </Button>
            }
          </Stack>
        </Box>
      </>
    );
  };

  return (
    <>
      <Stack className="add-to-practice align-center p-8 mx-8">
        <Typography variant="body2">
          Next Steps: Select Program Options below prior to starting treatment
        </Typography>
      </Stack>
      <Box>
        <Box>
          <Card elevation={0} variant="outlined">
            <div style={styles.row}>
              <div style={styles.fap_header}>
                <img alt="" style={styles.fap_logo} src="/Assets/fap-removebg.png" />

                <div style={styles.fav_devider}></div>
                <div style={{ flexDirection: "row", display: "flex", marginBottom: 10 }}>
                  <Typography sx={styles.text_regular}>
                    We partner with Fertility Access to offer Multi-Cycle Discount and Refund
                    Programs
                  </Typography>
                </div>
              </div>
              {/* <Box>
                <img alt="" src="/Assets/fap.png" className="fap-icon" />
                <Typography>MULTICYCLE AND DISCOUNT PROGRAMS</Typography>
              </Box> */}
            </div>
            <Stack sx={styles.row_new}>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  fontSize: 13,
                  fontWeight: 400,
                  marginLeft: "10%",
                  marginRight: "10%",
                  width: "60%",
                  marginTop: 2
                }}>
                Even with state-of-the-art technology and the best treatment available, it often
                takes women more than one IVF cycle to be successful. You may increase your
                likelihood of having a baby when you commit to multiple cycles, so we offer Refund
                and Multi-Cycle packages for a fixed, discounted fee that can make paying for
                treatment more manageable.
              </Typography>
            </Stack>
            <Box className="p-8 mt-8">
              <Stack className="align-center">
                <img alt="" src="/Assets/group-2.png" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingTop: 10
                  }}>
                  <div style={{ fontSize: 30, paddingRight: 10 }}>80%</div>
                  <div style={{ width: 300 }}>
                    of patients who enroll in a Fertility Access Program bring home a baby*
                  </div>
                </div>
              </Stack>
            </Box>

            <Box className="p-8 mt-8">
              <Stack
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5px"
                }}>
                <Typography className="p-8">
                  Highlight the option that works best for you and click the Select button.
                </Typography>
                {/* <Link
                  href={APPLY_LOAN}
                  underline="none"
                  className="link-text"
                  target="_blank"
                  sx={{ fontWeight: 500, my: 1 }}>
                  Click here to apply for a loan and determine potential monthly costs.
                </Link> */}
              </Stack>
            </Box>

            <Grid container columnSpacing={1} p="4px" className="pad-t-0">
              {isRefundProgram ? (
                <Grid container columnSpacing={1} p="4px" className="pad-b-0">
                  <Grid item xs={12} lg={xsValue}>
                    <Stack py={3} sx={{ background: "#1BA3AD" }}>
                      <Typography className="effective-text">
                        {"Ultimate peace of mind".toUpperCase()}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={4}></Grid>
                  <Grid item xs={12} lg={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* https://secure1.capexmd.com:4443/LoanApplicationSystem/usf-prequalification-request.php */}
                    <Link
                      href={APPLY_LOAN}
                      underline="none"
                      className="link-text"
                      target="_blank"
                      style={{ paddingRight: 20, paddingTop: 24 }}>
                      Click here to apply for a loan and determine potential monthly costs.
                    </Link>
                  </Grid>
                </Grid>
              ) : null}
              {fapData?.programVisualization &&
                fapData?.programVisualization.map((item, index) => {
                  let barHeight = 95;
                  let containerHeight = 125;
                  let barDirection =
                    item.boxName == programBoxName.PAY_PER_CYCLE ? "bar-direction" : null;
                  let alignSelf =
                    item.boxName == programBoxName.PAY_PER_CYCLE ? "alignSelf height-50" : null;
                  if (item.boxName == programBoxName.PAY_PER_CYCLE) {
                    item.programs.sort((a, b) => {
                      return b.programPrice - a.programPrice;
                    });
                  }
                  return (
                    <>
                      <Grid
                        item
                        key={index}
                        xs={12}
                        lg={xsValue}
                        style={{ marginBottom: "5px", marginTop: "5px" }}>
                        <Card
                          variant="outlined"
                          className={"p-8 card-border bt-radius-0"}
                          // className={
                          //   /* item.boxName == programBoxName.REFUND_PROGRAM
                          //     ? */ `p-8  bt-radius-0 bt-l-0 bt-r-0`
                          //   /* : `p-8  bt-radius-0 mt-42` */
                          // }
                        >
                          {renderCards(
                            item,
                            barDirection,
                            barHeight,
                            alignSelf,
                            index,
                            containerHeight
                          )}
                        </Card>
                      </Grid>
                      {isMobileView ? (
                        <>
                          <Grid container columnSpacing={2}>
                            {item.boxName == programBoxName.REFUND_PROGRAM ? (
                              <Grid className="" item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <Typography variant="body2">Refund Program</Typography>
                                </Stack>
                              </Grid>
                            ) : null}
                            {item.boxName == programBoxName.NON_REFUND_PROGRAM ? (
                              <Grid className="" item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <Typography variant="body2">
                                    Multi-Cycle Discount Program
                                  </Typography>
                                </Stack>
                              </Grid>
                            ) : null}
                            {item.boxName == programBoxName.PAY_PER_CYCLE ? (
                              <Grid className="" item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <Typography variant="body2">Pay Per Cycle</Typography>
                                </Stack>
                              </Grid>
                            ) : null}
                          </Grid>
                          <Stack
                            className="program-summary align-center p-2"
                            sx={{ backgroundColor: "#818294", color: "#fff", width: "100%" }}>
                            <Typography variant="body2">Increased Chance of Success</Typography>
                          </Stack>
                          <Grid container columnSpacing={2}>
                            {item.boxName == programBoxName.REFUND_PROGRAM ? (
                              <Grid item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                                  <InputLabel sx={{ fontSize: "12px" }}>
                                    80% of enrolled patients bring home a baby
                                  </InputLabel>
                                </Stack>
                              </Grid>
                            ) : null}
                            {item.boxName == programBoxName.NON_REFUND_PROGRAM && (
                              <Grid item xs={12} lgxs={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                                  <InputLabel sx={{ fontSize: "12px", px: 3 }}>
                                    80% of enrolled patients bring home a baby
                                  </InputLabel>
                                </Stack>
                              </Grid>
                            )}
                            {item.boxName == programBoxName.PAY_PER_CYCLE && (
                              <Grid item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <DisabledByDefaultOutlined
                                    color="primary"
                                    sx={{ color: "#1BA3AD" }}
                                  />
                                  <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                                </Stack>
                              </Grid>
                            )}
                          </Grid>
                          <Stack
                            className="program-summary align-center p-2"
                            sx={{ backgroundColor: "#818294", width: "100%", color: "#fff" }}>
                            <Typography variant="body2">Refund Available</Typography>
                          </Stack>
                          <Grid container columnSpacing={2}>
                            {item.boxName == programBoxName.REFUND_PROGRAM ? (
                              <Grid item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                                  <InputLabel sx={{ fontSize: "12px" }}>
                                    Refund If you don&apos;t bring home a baby
                                  </InputLabel>
                                </Stack>
                              </Grid>
                            ) : null}
                            {item.boxName == programBoxName.NON_REFUND_PROGRAM && (
                              <Grid item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <DisabledByDefaultOutlined
                                    color="primary"
                                    sx={{ color: "#1BA3AD" }}
                                  />
                                  <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                                </Stack>
                              </Grid>
                            )}
                            {item.boxName == programBoxName.PAY_PER_CYCLE && (
                              <Grid item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <DisabledByDefaultOutlined
                                    color="primary"
                                    sx={{ color: "#1BA3AD" }}
                                  />
                                  <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                                </Stack>
                              </Grid>
                            )}
                          </Grid>
                          <Stack
                            className="program-summary align-center p-2"
                            sx={{ backgroundColor: "#818294", width: "100%", color: "#fff" }}>
                            <Typography variant="body2">Egg Retrievals</Typography>
                          </Stack>
                          <Grid container columnSpacing={2}>
                            {/* {fapCardsStore.programVisualization &&
                              fapCardsStore.programVisualization.map((item, index) => {
                                return ( */}
                            <Grid item xs={12} lg={xsValue} key={index}>
                              <Stack className="compare-details-items align-center">
                                <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                                <InputLabel sx={{ fontSize: "12px" }}>
                                  {getRetrievals(item) + " Retrievals"}
                                </InputLabel>
                              </Stack>
                            </Grid>
                            {/*  );
                              })} */}
                          </Grid>
                          <Stack
                            className="program-summary align-center p-2"
                            sx={{ backgroundColor: "#818294", width: "100%", color: "#fff" }}>
                            <Typography variant="body2">
                              Unlimited Frozen Embryo Transfers
                            </Typography>
                          </Stack>
                          <Grid container columnSpacing={2}>
                            {item.boxName == programBoxName.REFUND_PROGRAM ? (
                              <Grid item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                                  <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                                </Stack>
                              </Grid>
                            ) : null}
                            {item.boxName == programBoxName.NON_REFUND_PROGRAM && (
                              <Grid item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                                  <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                                </Stack>
                              </Grid>
                            )}
                            {item.boxName == programBoxName.PAY_PER_CYCLE && (
                              <Grid item xs={12} lg={xsValue}>
                                <Stack className="compare-details-items align-center">
                                  <DisabledByDefaultOutlined
                                    color="primary"
                                    sx={{ color: "#1BA3AD" }}
                                  />
                                  <InputLabel sx={{ fontSize: "12px" }}>
                                    Payment of{" "}
                                    {fapCardsStore &&
                                      fapCardsStore.globalPriceOfFet &&
                                      "$" +
                                        fapCardsStore.globalPriceOfFet.toLocaleString("en")}{" "}
                                    required for each FET
                                  </InputLabel>
                                </Stack>
                              </Grid>
                            )}
                          </Grid>
                        </>
                      ) : null}
                    </>
                  );
                })}
            </Grid>

            {!isMobileView ? (
              <>
                <Stack
                  className="align-center compare-details"
                  sx={{ backgroundColor: "#1BA3AD", color: "#FFF" }}>
                  <Typography>Compare the Details</Typography>
                </Stack>
                <Grid container columnSpacing={2}>
                  {isRefundProgram ? (
                    <Grid className="" item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <Typography variant="body2">Refund Program</Typography>
                      </Stack>
                    </Grid>
                  ) : null}
                  {isNonRefundProgram ? (
                    <Grid className="" item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <Typography variant="body2">Multi-Cycle Discount Program</Typography>
                      </Stack>
                    </Grid>
                  ) : null}
                  {isSingleCycle ? (
                    <Grid className="" item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <Typography variant="body2">Pay Per Cycle</Typography>
                      </Stack>
                    </Grid>
                  ) : null}
                </Grid>
                <Stack
                  className="program-summary align-center p-2"
                  sx={{ backgroundColor: "#818294", color: "#fff" }}>
                  <Typography variant="body2">Increased Chance of Success</Typography>
                </Stack>
                <Grid container columnSpacing={2}>
                  {isRefundProgram ? (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px" }}>
                          80% of enrolled patients bring home a baby
                        </InputLabel>
                      </Stack>
                    </Grid>
                  ) : null}
                  {isNonRefundProgram && (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px", px: 3 }}>
                          80% of enrolled patients bring home a baby
                        </InputLabel>
                      </Stack>
                    </Grid>
                  )}
                  {isSingleCycle && (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <DisabledByDefaultOutlined color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
                <Stack
                  className="program-summary align-center p-2"
                  sx={{ backgroundColor: "#818294", color: "#fff" }}>
                  <Typography variant="body2">Refund Available</Typography>
                </Stack>
                <Grid container columnSpacing={2}>
                  {isRefundProgram ? (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px" }}>
                          Refund If you don&apos;t bring home a baby
                        </InputLabel>
                      </Stack>
                    </Grid>
                  ) : null}
                  {isNonRefundProgram && (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <DisabledByDefaultOutlined color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                      </Stack>
                    </Grid>
                  )}
                  {isSingleCycle && (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <DisabledByDefaultOutlined color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
                <Stack
                  className="program-summary align-center p-2"
                  sx={{ backgroundColor: "#818294", color: "#fff" }}>
                  <Typography variant="body2">Egg Retrievals</Typography>
                </Stack>
                <Grid container columnSpacing={2}>
                  {fapCardsStore.programVisualization &&
                    fapCardsStore.programVisualization.map((item, index) => {
                      return (
                        <Grid item xs={12} lg={xsValue} key={index}>
                          <Stack className="compare-details-items align-center">
                            <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                            <InputLabel sx={{ fontSize: "12px" }}>
                              {getRetrievals(item) + " Retrievals"}
                            </InputLabel>
                          </Stack>
                        </Grid>
                      );
                    })}
                </Grid>
                <Stack
                  className="program-summary align-center p-2"
                  sx={{ backgroundColor: "#818294", color: "#fff" }}>
                  <Typography variant="body2">Unlimited Frozen Embryo Transfers</Typography>
                </Stack>
                <Grid container columnSpacing={2}>
                  {isRefundProgram ? (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                      </Stack>
                    </Grid>
                  ) : null}
                  {isNonRefundProgram && (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <CheckBoxSharp color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px" }}>&nbsp;</InputLabel>
                      </Stack>
                    </Grid>
                  )}
                  {isSingleCycle && (
                    <Grid item xs={12} lg={xsValue}>
                      <Stack className="compare-details-items align-center">
                        <DisabledByDefaultOutlined color="primary" sx={{ color: "#1BA3AD" }} />
                        <InputLabel sx={{ fontSize: "12px" }}>
                          Payment of{" "}
                          {fapCardsStore &&
                            fapCardsStore.globalPriceOfFet &&
                            "$" + fapCardsStore.globalPriceOfFet.toLocaleString("en")}{" "}
                          required for each FET
                        </InputLabel>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
          </Card>
          <Stack>
            <Typography py={2}>
              <b className="loan-disclaimer">Loan term Disclaimer :</b> {loanDisclaimer}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
export default FapView;
