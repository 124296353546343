/* eslint-disable react/no-unescaped-entities */
import { Box, Typography, Card } from "@mui/material";
import { bankAddress } from "../../shared/constants";
export default function LearnMore() {
  return (
    <Box className="container" sx={{ margin: 3 }}>
      <Card variant="outlined" className="pad-16 mt-8">
        <Typography variant="body2" className="ml-8">
          If you intend to use a Certified, Bank or Cashier's Check, please use a courier with
          tracking information and send to the following address.
        </Typography>
        <Typography className="ml-8">
          {bankAddress.split("\n\n").map((paragraph, key) => (
            <p key={key}>
              {paragraph
                .split("\n")
                .reduce((total, line, index) => [total, <br key={index} />, line])}
            </p>
          ))}
        </Typography>
        <Typography className="ml-8">
          If you have any questions regarding wire transfers, please contact a member of your
          Fertility Access Concierge team at 1-800-873-9203{" "}
          {/* <Link to={"tel://" + wireNo} target="_blank">
            ({wireNo})
          </Link> */}
          .
        </Typography>
      </Card>
    </Box>
  );
}
