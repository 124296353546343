import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Stack, Box, Divider, Card, Button, Modal, IconButton } from "@mui/material";
// import NextStep from "../../shared/components/NextStep";
import { useState } from "react";
import * as actionType from "../../store/actions";
import { connect } from "react-redux";
import "./documents.scss";
import ArticleIcon from "@mui/icons-material/Article";
// import moment from "moment/moment";
import moment from "moment-timezone";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import CloseIcon from "@mui/icons-material/Close";
import { getLocaleDateTime } from "../../shared/constants/common";
// import useMediaQuery from "@mui/material/useMediaQuery";

const DocumentManagement = (props) => {
  const { actions, documentList, loading, contractDocument, contractLoading, contractError } =
    props;
  const patientId = localStorage.getItem("patientId");
  const impersonatorId = localStorage.getItem("impersonatorId");
  const cognitoUserId = localStorage.getItem("cognitoUserId");
  const [pageSize, setPageSize] = useState(10);
  const [unsignedRows, setUnsignedRows] = useState([]);
  const [signedRows, setSignedRows] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const unsignedColumns = [
    {
      field: "no",
      headerName: "NO.",
      flex: 0.3,
      minWidth: 50,
      renderCell: ({ row }) => {
        return <Typography>{row.id + 1}</Typography>;
      }
    },
    {
      field: "medcaseNo",
      headerName: "MEDCASE ID",
      flex: 0.5,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography>{row.medcase_id}</Typography>;
      }
    },
    {
      field: "document",
      headerName: "DOCUMENT",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        return <Typography>{row.document_type}</Typography>;
      }
    },
    {
      field: "program",
      headerName: "PROGRAM",
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        return <Typography>{row.program_name}</Typography>;
      }
    },
    {
      field: "receivedDateTime",
      headerName: "RECEIVED DATE AND TIME",
      flex: 1,
      minWidth: 180,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {/* {row.contract_generated ? moment(new Date(row.contract_generated)).format("L") : "--"} */}
            {/* row.contract_generated
              ? moment
                  .utc(row.contract_generated)
                  .tz("America/New_York")
                  .format("MM/DD/YYYY hh:mm A z")
              : "--" */}
            {row.contract_generated
              ? getLocaleDateTime(row?.contract_generated, "MM/DD/YYYY hh:mm A z", true)
              : "--"}
          </Typography>
        );
      }
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography>{row.status}</Typography>;
      }
    },
    {
      field: "documentExpiryDate",
      headerName: "DOCUMENT EXPIRY DATE",
      flex: 1,
      minWidth: 180,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row.expiry_date ? moment(new Date(row.expiry_date)).format("L") : "--"}
          </Typography>
        );
      }
    },
    {
      field: "document1",
      headerName: "DOCUMENT",
      flex: 0.5,
      minWidth: 100,
      renderCell: ({ row }) => {
        return (
          <Button onClick={() => contractHandler(row.contract_id)}>
            <ArticleIcon color="primary" />
          </Button>
        );
      }
    }
  ];

  const signedColumns = [
    {
      field: "no",
      headerName: "NO.",
      flex: 0.3,
      minWidth: 50,
      renderCell: ({ row }) => {
        return <Typography>{row.id + 1}</Typography>;
      }
    },
    {
      field: "medcaseNo",
      headerName: "MEDCASE NO.",
      flex: 0.5,
      minWidth: 100,
      renderCell: ({ row }) => {
        return <Typography>{row.medcase_id}</Typography>;
      }
    },
    {
      field: "document",
      headerName: "DOCUMENT",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        return <Typography>{row.document_type}</Typography>;
      }
    },
    {
      field: "program",
      headerName: "PROGRAM",
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        return <Typography>{row.program_name}</Typography>;
      }
    },
    {
      field: "receivedSignedDateTime",
      headerName: "RECEIVED SIGNED DATE AND TIME",
      flex: 1,
      minWidth: 180,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {/* {row.contract_generated ? moment(new Date(row.contract_generated)).format("L") : "--"} */}
            {/* row.contract_generated
              ? moment
                  .utc(row.contract_generated)
                  .tz("America/New_York")
                  .format("MM/DD/YYYY hh:mm A z")
              : "--" */}
            {row.contract_generated
              ? getLocaleDateTime(row?.contract_generated, "MM/DD/YYYY hh:mm A z", true)
              : "--"}
          </Typography>
        );
      }
    },
    {
      field: "document1",
      headerName: "DOCUMENT",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return (
          <Button onClick={() => contractHandler(row.contract_id)}>
            <ArticleIcon color="primary" />
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    actions.getDocumentList({ patientId });

    if (impersonatorId) {
      actions.createImpersonateLog({
        impersonatorId: impersonatorId,
        eventName: "Login In",
        screenName: "Document",
        eventType: "impersonate",
        ipAddress: "192.168.1.1",
        patientId: cognitoUserId
      });
    }
  }, []);

  useEffect(() => {
    if (documentList.unsigned) {
      let rows = documentList.unsigned.map((item, index) => {
        item.id = index;
        return item;
      });
      setUnsignedRows(rows);
    } else {
      setUnsignedRows([]);
    }
    if (documentList.signed) {
      let rows = documentList.signed.map((item, index) => {
        item.id = index;
        return item;
      });
      setSignedRows(rows);
    } else {
      setSignedRows([]);
    }
  }, [loading]);

  useEffect(() => {
    if (contractDocument?.documentUrl) {
      let url = contractDocument.documentUrl;
      const a = document.createElement("a");
      a.href = url;
      a.download = "US Fertility Agreement.pdf";
      a.click();
      actions.resetContractData();
    }
    if (contractError) {
      setIsOpenModal(true);
      actions.resetContractData();
    }
  }, [contractDocument, contractError]);

  const contractHandler = (contractId) => {
    actions.getContractDocument({ contractId });
  };

  return (
    <Box className="doc-box">
      {contractLoading ? (
        <Box className="m-8">
          <LoadingSpinner top="40%" />
        </Box>
      ) : (
        ""
      )}

      <Stack spacing={2}>
        <Typography variant="h6">DOCUMENT MANAGEMENT</Typography>
        <Typography></Typography>
        <Card className="pad-16" variant="outlined">
          <Typography variant="body2">Awaiting Signature</Typography>
          <DataGrid
            className="mt-16"
            loading={loading}
            rows={unsignedRows}
            columns={unsignedColumns}
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newSize) => setPageSize(newSize)}
            pagination
            autoHeight
          />
        </Card>
        <Divider className="divider-style" />
        <Typography pt={1}></Typography>
        <Card className="pad-16" variant="outlined">
          <Typography variant="body2">Received / Signed Documents</Typography>
          <DataGrid
            className="mt-16"
            loading={loading}
            rows={signedRows}
            columns={signedColumns}
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newSize) => setPageSize(newSize)}
            pagination
            autoHeight
          />
        </Card>
        <Card className="pad-10">{/* <NextStep /> */}</Card>
      </Stack>
      <Modal
        open={isOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        backdrop="static">
        <Box className="document-modal-style">
          <Stack>
            <Stack direction="row" justifyContent="space-between" className="align-center">
              <Typography color="primary">Alert</Typography>
              <Divider sx={{ height: "1px", backgroundColor: "#1BA3AD" }} />
              <IconButton onClick={() => setIsOpenModal(false)}>
                <CloseIcon color="primary" />
              </IconButton>
            </Stack>
            <Divider />
            <Typography variant="body1" pt={1.5}>
              Contract Not Found.
            </Typography>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

const mapStateToProps = ({ documentReducer }) => {
  return {
    documentList: documentReducer.documentList,
    contractDocument: documentReducer.contractDocument,
    loading: documentReducer.loading,
    error: documentReducer.error,
    contractLoading: documentReducer.contractLoading,
    contractError: documentReducer.contractError
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getDocumentList: (payload) => {
      dispatch(actionType.getDocumentList(payload));
    },
    getContractDocument: (payload) => {
      dispatch(actionType.getContractDocument(payload));
    },
    resetContractData: () => {
      dispatch(actionType.resetContractData());
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentManagement);
