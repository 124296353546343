import * as actionType from "../actions/ActionTypes";

const initialState = {
  invoiceDetails: [],
  loading: false,
  error: null,
  invoiceRow: {},
  rowData: [],
  estimateCards: [],
  stages: [],
  adjustmentList: [],
  necessary: [],
  optional: [],
  estimateCardLoading: false,
  stageLoading: false,
  adjustmentLoading: false,
  necessaryLoading: false,
  optionalLoading: false
};

const paybillReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.INVOICE_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.INVOICE_DETAILS_SUCCESS: {
      return {
        ...state,
        invoiceDetails: action.payload,
        loading: false
      };
    }
    case actionType.INVOICE_DETAILS_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.INVOICE_ROW_DATA_REQUESTED: {
      return {
        ...state,
        invoiceRow: action.payload
      };
    }
    case actionType.RESET_INVOICE_DETAILS_LIST: {
      return {
        ...state,
        error: false,
        loading: false,
        success: false
      };
    }

    case actionType.ESTIMATE_CARDS_REQUESTED: {
      return {
        ...state,
        estimateCardLoading: true
      };
    }
    case actionType.ESTIMATE_CARDS_SUCCESS: {
      return {
        ...state,
        estimateCards: action.payload,
        estimateCardLoading: false
      };
    }
    case actionType.ESTIMATE_CARDS_FAILED: {
      return {
        ...state,
        error: action.message,
        estimateCardLoading: false
      };
    }

    case actionType.GET_STAGES_REQUESTED: {
      return {
        ...state,
        stageLoading: true
      };
    }
    case actionType.GET_STAGES_SUCCESS: {
      return {
        ...state,
        stages: action.payload,
        stageLoading: false
      };
    }
    case actionType.GET_STAGES_FAILED: {
      return {
        ...state,
        error: action.message,
        stageLoading: false
      };
    }

    case actionType.ADJUSTMENT_LIST_REQUESTED: {
      return {
        ...state,
        adjustmentLoading: true
      };
    }
    case actionType.ADJUSTMENT_LIST_SUCCESS: {
      return {
        ...state,
        adjustmentList: action.payload,
        adjustmentLoading: false
      };
    }
    case actionType.ADJUSTMENT_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        adjustmentLoading: false
      };
    }

    case actionType.NECESSARY_LIST_REQUESTED: {
      return {
        ...state,
        necessaryLoading: true
      };
    }
    case actionType.NECESSARY_LIST_SUCCESS: {
      return {
        ...state,
        necessary: action.payload,
        necessaryLoading: false
      };
    }
    case actionType.NECESSARY_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        necessaryLoading: false
      };
    }
    case actionType.OPTIONAL_LIST_REQUESTED: {
      return {
        ...state,
        optionalLoading: true
      };
    }
    case actionType.OPTIONAL_LIST_SUCCESS: {
      return {
        ...state,
        optional: action.payload,
        optionalLoading: false
      };
    }
    case actionType.OPTIONAL_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        optionalLoading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default paybillReducer;
