import "./messages.scss";
import { Box, Stack, Typography, Grid, Link } from "@mui/material";
import { connect } from "react-redux";
import * as actionType from "../../store/actions";
import { useEffect } from "react";
import { toUSAFormat } from "../../shared/constants";

const ClinicalPortal = (props) => {
  const { actions, practiceData } = props;
  const cognitoUserId = localStorage.getItem("cognitoUserId");
  const impersonatorId = localStorage.getItem("impersonatorId");
  const practiceId = localStorage.getItem("practiceId");

  useEffect(() => {
    if (impersonatorId) {
      actions.createImpersonateLog({
        impersonatorId: impersonatorId,
        eventName: "Login In",
        screenName: "Clinical Portal",
        eventType: "impersonate",
        ipAddress: "192.168.1.1",
        patientId: cognitoUserId
      });
    }
  }, []);

  useEffect(() => {
    actions.getPracticeData({ practiceId });
  }, [practiceId]);

  const redirectToAnotherPage = () => {
    if (Object.keys(practiceData).length > 0) {
      let link = (practiceData && practiceData.fertLink) || "";
      if (link) {
        window.open(link, "_blank", "noreferrer");
      } else {
        alert("link not found");
      }
    } else {
      alert("link not found");
    }
  };

  return (
    <>
      <Box className="message-header">
        <Stack direction="row" className="flex-between">
          <Typography variant="body1" className="font-24" sx={{ marginTop: 5 }}>
            CLINICAL PORTAL
          </Typography>
          <Stack direction="row" gap={1}>
            {/* <IconButton onClick={refreshButtonClickHandler}>
              <RefreshIcon />
            </IconButton>
            <Button variant="outlined" onClick={composeClickHandler}>
              COMPOSE
            </Button> */}
          </Stack>
        </Stack>
        <Stack direction="column" className="static-text">
          <Typography className="p-4" sx={{ marginTop: 3 }}>
            Your Clinical Patient Portal provides access to your medical records, treatment
            schedules, appointments scheduling and more.
          </Typography>
          <Typography className="p-4" sx={{ marginTop: 2 }}>
            If you need immediate assistance, call{" "}
            {(practiceData &&
              practiceData?.phoneNumber &&
              toUSAFormat(practiceData?.phoneNumber)) ||
              ""}
            . If this is a medical emergency, please call 911.
          </Typography>
          <Typography className="p-4" sx={{ marginTop: 2 }}>
            To access your Clinical Portal, please{" "}
            {/* <Typography onClick={() => redirectToAnotherPage()}>click here</Typography> */}
            <Link underline="none" target="_blank" onClick={() => redirectToAnotherPage()}>
              click here
            </Link>
          </Typography>
        </Stack>
      </Box>
      <Grid container>
        {/* // <Grid item xs={1.6} sx={{ background: "#1BA3AD", minHeight: "100vh" }}>
        //   <MessageConfiguration />
        // </Grid>
        // <Grid item xs={10.4}>
        //   <Outlet />
        // </Grid> */}
      </Grid>
    </>
  );
};

const mapStateToProps = ({ homeReducer, profileReducer }) => {
  return {
    practiceData: homeReducer.practiceData,
    profileData: profileReducer.profileData,
    personData: homeReducer.personData,
    userList: homeReducer.userList,

    practiceDataLoading: homeReducer.practiceDataLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    },
    getPracticeData: (payload) => {
      dispatch(actionType.getPracticeData(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalPortal);
