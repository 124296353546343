import * as actionType from "../actions/ActionTypes";

const initialState = {
  profileData: {},
  loading: false,
  practiceInfoData: {}
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PROFILE_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PROFILE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        profileData: action.payload
      };
    }
    case actionType.PROFILE_DATA_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.message
      };
    }
    case actionType.PRACTICE_INFO_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PRACTICE_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        practiceInfoData: action.payload
      };
    }
    case actionType.PRACTICE_INFO_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.message
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default profileReducer;
