import * as actionType from "../actions/ActionTypes";
import { put, call, takeEvery, all } from "redux-saga/effects";
import apis from "../../shared/constants/api-url";
import { token } from "../../shared/constants";

function* fetchRegistrationsPatientKeySaga(action) {
  const { payload } = action;

  try {
    const response = yield call(() =>
      apis.get(`/registrations/patients/${payload.pNumber}`, {
        method: "GET",
        params: {
          offset: "",
          limit: "10000"
        },
        headers: {
          "Content-type": "application/json",
          Authorization: token.token
        }
      })
    );

    yield put({
      type: actionType.REGISTRATIONS_PATIENT_KEY_SUCCESS,
      payload: response.data,
      statusCode: response && response.status
    });
  } catch (error) {
    console.log("error", error);
    yield put({
      type: actionType.REGISTRATIONS_PATIENT_KEY_FAILED,
      error: (error && error.response && error.response.data) || error.message,
      statusCode: error && error.response && error.response.status
    });
  }
}

function* getRegistrationsPatientKey() {
  yield takeEvery(actionType.REGISTRATIONS_PATIENT_KEY_REQUESTED, fetchRegistrationsPatientKeySaga);
}

export default function* registrationsPatientKeySaga() {
  yield all([getRegistrationsPatientKey()]);
}
