import { Grid, Typography, Stack, Box, Button, styled, Link } from "@mui/material";
import { useState } from "react";
import React from "react";
import AttachMoneySharpIcon from "@mui/icons-material/AttachMoneySharp";
import IconButton from "@mui/icons-material/ControlPoint";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { APPLY_LOAN, PAY_BILL } from "../../shared/constants";
import moment from "moment";

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  rowSpacing: 2,
  textAlign: "center",
  flexDirection: "column",
  margin: 3,
  borderRight: 1,
  borderColor: "black"
}));
export default function Pay(props) {
  const { data = [] } = props;
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  const dateFormatForPublishedOn = (date) => {
    if (!date || date == null || date == "") {
      return date;
    }
    let d = new Date(data.publishedOn);
    let tdate = moment(d).format("MM/DD/YYYY");
    //let ttime = moment(d).format("h:mm A");
    return tdate;
  };

  return (
    <Box className="m-r-8 m-l-8">
      <Stack m={1} className="pay-head-text" /* direction="row" */>
        <Typography mr={1} mb={1}>
          TREATMENT ESTIMATE:
          {data.finEstimateType == "D" ? " DETAILED ESTIMATE " : " PRILIMNARY ESTIMATE "}
        </Typography>
        <Typography mr={1} mb={1}>
          PUBLISHED ON
          {" " + dateFormatForPublishedOn(data.publishedOn)}
        </Typography>
        <Typography mr={1} mb={1}>
          Invoice No
          {" " + data.invoiceNo + " " + " | "}
        </Typography>
        <Typography mr={1} mb={1}>
          Medcase ID
          {" " + data.medcaseId}
        </Typography>
      </Stack>
      <Box className="p-4 mt-8">
        <Typography>You Can pay your bill by below methods</Typography>
      </Box>
      <Stack direction="row" className="pay">
        <AttachMoneySharpIcon fontSize="large" />
        <Typography fontSize="large" sx={{ marginTop: 1 }}>
          Pay
        </Typography>
      </Stack>
      <Stack className="grid-item" xs={12} sm={6}>
        <Typography>You must pay full program cost before stimulation begins</Typography>
      </Stack>
      <Grid className="grid-loan">
        <Grid onClick={handleClick}>
          Have a Loan or want to apply ?
          <IconButton className="icon-button" />
        </Grid>
        {isShown && (
          <Grid>
            <Grid container className="row " style={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={3} /* className="col-3" */>
                <Item
                  sx={{
                    margin: 3,
                    borderRight: 1,
                    borderColor: "silver"
                  }}>
                  <Grid className="grid-container" alignSelf={"center"}>
                    <Grid item>
                      <Typography className="typography2">HAVENT APPLIED FOR LOAN</Typography>
                    </Grid>

                    <Grid item>
                      <Button variant="outlined" className="btn-primary m-t-10">
                        <Link underline="none" target="_blank" href={APPLY_LOAN}>
                          Apply Now
                        </Link>
                      </Button>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3} /* className="col-3" */>
                <Item
                  sx={{
                    margin: 3,
                    borderRight: 1,
                    borderColor: "silver"
                  }}>
                  <Grid container className="grid-container">
                    <Grid item>
                      <Typography className="typography2">
                        HAVE A LOAN WITH OUR PREFERED LENDER CAPEX?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="typography">
                        Verify that payment is being made to Fertility Access and process the loan
                        documents
                      </Typography>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3} /* className="col-3" */>
                <Item
                  sx={{
                    margin: 3
                  }}>
                  <Grid container className="grid-container">
                    <Grid item>
                      <Typography className="typography2">
                        HAVE A LOAN WITH ANOTHER PROVIDER?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="typography">
                        Contact Access Patient Concierge
                      </Typography>
                      <Typography className="typography">
                        1-800-873-9203
                        <Link to={`tel:${1 - 800 - 873 - 9203}`} style={{ textDecoration: "none" }}>
                          (1-800-873-9203)
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Stack className="grid-item" xs={12} sm={6}>
        <Typography>Payment Methods :</Typography>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Item>
            <Typography variant="h6">CREDIT CARD</Typography>
            <Button variant="outlined" className="btn-primary m-t-10">
              <Link underline="none" target="_blank" href={PAY_BILL}>
                PAY BILL
              </Link>
              <OpenInNewIcon fontSize="small" />
            </Button>
          </Item>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Item>
            <Typography variant="h6">CERTIFIED/BANK/CASHIERS CHECK</Typography>
            <Button variant="outlined" className="btn-primary m-t-10">
              <Link underline="none" target="_blank" href="/learn-more">
                LEARN MORE
              </Link>
            </Button>
          </Item>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Item>
            <Typography variant="h6">WIRE TRANSFER</Typography>
            <Typography>Contact Fertility Access Patient Concierge</Typography>
            <Typography>1-800-873-9203</Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
