import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  Divider,
  IconButton,
  CircularProgress
} from "@mui/material";
import { Stack } from "@mui/system";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState, useEffect } from "react";
import { MSG_TXT1, MSG_TXT2 } from "../../shared/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../../store/actions";
import { InitialValuesForMessage, validateFileSize } from "../../shared/constants/index";
import LoadingModal from "../../shared/components/LoadingModal";

const Reply = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  if (location.state !== null) {
    var { data } = location.state;
  }
  const practiceId = localStorage.getItem("practiceId");
  const userId = localStorage.getItem("userId");
  const {
    actions,
    sentMessageLoading,
    updateMessagesLoading,
    updatedErrorMsg,
    successCreateMessage,
    errorCreateMessage,
    updatedSuccessMsg,
    postedFilePath,
    postFileLoading
  } = props;
  const [replyMessage, setReplyMessage] = useState(data.status == "Draft" ? data.body : "");
  const [alertMessageModalOpen, setAlertMessageModalOpen] = useState(false);
  const [openNavigationModal, setOpenNavigationModal] = useState(false);
  const [openSendMessagePopUp, setOpenSendMessagePopUp] = useState(false);
  const [values, setValues] = useState(InitialValuesForMessage);
  const [files, setFiles] = useState([]);
  const [closeErrorModal, setCloseErrorModal] = useState(false);
  const [invalidFileMessage, showInvalidFileMessage] = useState(false);
  const navigateToInboxHandler = () => {
    actions.resetMessageExecuteStatus();
    setCloseErrorModal(false);
    navigate("/messages/inbox");
  };
  const [prevIndex, setPreveIndex] = useState(0);

  useEffect(() => {
    if (errorCreateMessage || updatedErrorMsg) {
      setCloseErrorModal(true);
    }
  }, [errorCreateMessage, updatedErrorMsg]);

  const settingValues = (messageParams) => {
    data.status == "Draft" ? (values.messageId = data.messageId) : (values.messageId = null),
      (values.conversationId = data.conversationId),
      (values.subject = data.subject),
      (values.body = replyMessage),
      (values.type = data.type),
      (values.fromUserId = userId),
      (values.status = messageParams),
      data.srcMessageId == null
        ? (values.srcMessageId = data.messageId)
        : (values.srcMessageId = data.srcMessageId),
      data.status == "Draft"
        ? (values.messageRecipient = data.messageRecipient)
        : (values.messageRecipient = [
            { messageId: data.messageId, toUser: data.fromUserId, isRead: false }
          ]);
    values.patientFirstName = data.patientFirstName;
    values.patientLastName = data.patientLastName;
    values.notes = data.notes;

    setValues({ ...values });
  };
  const saveDraftHandler = () => {
    settingValues("Draft");
    let payload = {
      userId,
      updatedMessages: values
    };
    let payloadforDraft = {
      userId,
      updatedMessages: [values]
    };
    {
      data.status == "Draft"
        ? actions.updateMultipleMessages(payloadforDraft)
        : actions.createNewMessage(payload);
      setAlertMessageModalOpen(true);
    }
  };

  const sendButtonClickHandler = () => {
    settingValues("Sent");
    let payload = {
      userId,
      updatedMessages: values
    };
    let payloadforDraft = {
      userId,
      updatedMessages: [values]
    };
    {
      data.status == "Draft"
        ? actions.updateMultipleMessages(payloadforDraft)
        : actions.createNewMessage(payload);
    }
    setOpenSendMessagePopUp(true);
  };
  const navigationBoxCloseHandler = () => {
    setOpenNavigationModal(false);
    setAlertMessageModalOpen(false);
  };
  const handleClick = (e) => {
    const { target = {} } = e || {};
    target.value = "";
  };
  const fileHandler = (e) => {
    let filesArray = [...e.target.files];
    setFiles(filesArray);
    if (e.target.files[0]) {
      if (
        validateFileSize(filesArray[0])[1] === true
        // || validateFileType(filesArray[prevIndex])[1] === true
      ) {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(filesArray[0]);
        fileReader.onload = function () {
          actions.sendFile({
            fileEncoded: fileReader.result?.split(",")[1],
            practiceId: practiceId,
            fileType: filesArray[0]?.type,
            fileName: filesArray[0]?.name
          });
        };
      } else {
        showInvalidFileMessage(true);
      }
    }
  };
  useEffect(() => {
    if (postedFilePath) {
      values.messageAttachment.push({
        attachFileName: files[0]?.name,
        attachmentUrl: postedFilePath
      });
      actions.resetMessageExecuteStatus();
      values.hasAttachment = true;
    }
  }, [postedFilePath]);
  useEffect(() => {
    if (postedFilePath != "" && files.length !== prevIndex && files.length > prevIndex) {
      prevIndex !== 0 && actions.resetMessageExecuteStatus();

      const fileUploader = () => {
        let formData = new FormData();
        formData.append("file", files[prevIndex]);
        actions.sendFile({
          file: formData,
          practiceId: practiceId
        });
      };
      if (files.length !== prevIndex && files.length > prevIndex) {
        prevIndex !== 0 && fileUploader();
        setPreveIndex(prevIndex + 1);
      }
    }
  }, [files, postedFilePath, prevIndex]);
  const modules = {
    toolbar: [["bold", "italic", "underline"]]
  };
  return (
    <Box>
      <Box className="compose-message-heading">
        <Typography variant="body1" className="font-24">
          REPLY
        </Typography>
        <Stack direction="column" className="compose-static-text">
          <Typography className="p-4">{MSG_TXT1}</Typography>
          <Typography className="p-4">{MSG_TXT2}</Typography>
        </Stack>
      </Box>
      <Box className="replied-message-style">
        <Stack gap={1}>
          <Typography>{data.type}</Typography>
          <Typography>Subject</Typography>
          <Typography>RE: {data.subject}</Typography>
        </Stack>
        <Stack direction="column" gap={1} marginTop={3}>
          <Typography>Attachments (optional)</Typography>
          <Stack direction="row">
            <AttachFileIcon />
            <form>
              <Button component="label">
                <input type="file" hidden multiple onChange={fileHandler} onClick={handleClick} />
                ATTACH FILE
              </Button>
            </form>
            <Stack direction="column" sx={{ display: "flex" }}>
              <Typography sx={{ mt: 1.4 }}>
                You can upload up to 10MB per attachment or upload a maximum of 10 attachments.
              </Typography>
              {invalidFileMessage ? (
                <Typography sx={{ color: "red", fontSize: 10 }}>
                  File size should not exceed 10MB
                </Typography>
              ) : (
                <></>
              )}
              {postFileLoading ? (
                <Stack direction="row" marginTop={1} gap={1}>
                  <CircularProgress size={22} />
                  <Typography sx={{ color: "#00889f", fontSize: 18 }}>Uploading...</Typography>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
          {files.length !== 0 &&
            files.map((item, index) => {
              return <Typography key={index}>{item.name}</Typography>;
            })}
        </Stack>
        <br />
        <Box className="textarea-style">
          <Typography>Message</Typography>
          <Box className="ql-editor">
            <ReactQuill
              theme="snow"
              modules={modules}
              value={replyMessage}
              onChange={setReplyMessage}
              placeholder="Write your message here..."
            />
          </Box>
        </Box>
        <Grid container className="mt-10">
          <Grid item xs={1.2}>
            <Button
              variant="outlined"
              onClick={() => {
                sendButtonClickHandler();
              }}
              disabled={replyMessage.replace(/<(.|\n)*?>/g, "").trim().length === 0}>
              SEND
            </Button>
          </Grid>
          <Grid item xs={9.8}>
            <Button
              variant="outlined"
              onClick={saveDraftHandler}
              disabled={replyMessage.replace(/<(.|\n)*?>/g, "").trim().length === 0}>
              SAVE AS DRAFT
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              onClick={() => {
                setOpenNavigationModal(true);
              }}>
              CANCEL
            </Button>
          </Grid>
        </Grid>
        {sentMessageLoading || updateMessagesLoading ? (
          <LoadingModal />
        ) : successCreateMessage || updatedSuccessMsg ? (
          <Modal open={alertMessageModalOpen} backdrop="static" aria-labelledby="modal-add-title">
            <Box className="alert-modal">
              <Box className="modal-stack">
                <Stack direction="row" justifyContent="space-between">
                  <Typography>SAVE AS DRAFT</Typography>
                  <IconButton onClick={navigationBoxCloseHandler}>
                    <img alt="" src="/Assets/close.png" />
                  </IconButton>
                </Stack>
              </Box>
              <Divider className="modal-divider" />
              <Box sx={{ py: 1 }}>
                <Typography>Message saved successfully.</Typography>
              </Box>
              <Stack direction="row" className="modal-bottom-section" gap={1}>
                <Button onClick={navigateToInboxHandler}>BACK TO INBOX</Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAlertMessageModalOpen(false);
                  }}>
                  CONTINUE EDITING
                </Button>
              </Stack>
            </Box>
          </Modal>
        ) : (
          <Modal open={closeErrorModal} backdrop="static" aria-labelledby="modal-add-title">
            <Box className="alert-modal">
              <Stack direction="row" className="modal-stack">
                <Typography className="font-w-500">Alert</Typography>
                <IconButton onClick={navigateToInboxHandler}>
                  <img alt="" src="/Assets/close.png" />
                </IconButton>
              </Stack>
              <Divider className="modal-divider" />
              <Box sx={{ py: 1 }}>
                <Typography>{errorCreateMessage}</Typography>
              </Box>
            </Box>
          </Modal>
        )}
        <Modal open={openNavigationModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal">
            <Box className="modal-stack">
              <Stack direction="row" justifyContent="space-between">
                <Typography>CONFIRM NAVIGATION</Typography>
                <IconButton onClick={navigationBoxCloseHandler}>
                  <img alt="" src="/Assets/close.png" />
                </IconButton>
              </Stack>
            </Box>
            <Divider className="modal-divider" />
            <Box sx={{ py: 1 }}>
              <Typography>
                Are you sure you want to leave this page? Any changes made since the last save will
                be lost.
              </Typography>
            </Box>
            <Stack direction="row" className="modal-bottom-section" gap={1}>
              <Button onClick={navigateToInboxHandler}>LEAVE</Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenNavigationModal(false);
                }}>
                CONTINUE EDITING
              </Button>
            </Stack>
          </Box>
        </Modal>
        {sentMessageLoading || updateMessagesLoading ? (
          <LoadingModal />
        ) : successCreateMessage || updatedSuccessMsg ? (
          <Modal open={openSendMessagePopUp} backdrop="static" aria-labelledby="modal-add-title">
            <Box className="alert-modal">
              <Stack direction="row" className="modal-stack">
                <Typography className="font-w-500">Send</Typography>
                <IconButton onClick={navigateToInboxHandler}>
                  <img alt="" src="/Assets/close.png" />
                </IconButton>
              </Stack>
              <Divider className="modal-divider" />
              <Stack direction="column" sx={{ alignItems: "center", justifyContent: "center" }}>
                <Typography sx={{ mt: 1 }}>Message send successfully</Typography>
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={() => {
                    navigateToInboxHandler();
                  }}
                  sx={{ marginTop: 1 }}>
                  Ok
                </Button>
              </Stack>
            </Box>
          </Modal>
        ) : (
          <Modal open={closeErrorModal} backdrop="static" aria-labelledby="modal-add-title">
            <Box className="alert-modal">
              <Stack direction="row" className="modal-stack">
                <Typography className="font-w-500">Alert</Typography>
                <IconButton onClick={navigateToInboxHandler}>
                  <img alt="" src="/Assets/close.png" />
                </IconButton>
              </Stack>
              <Divider className="modal-divider" />
              <Box sx={{ py: 1 }}>
                <Typography>{errorCreateMessage}</Typography>
              </Box>
            </Box>
          </Modal>
        )}
      </Box>
    </Box>
  );
};
const mapStateToProps = ({ messageReducer }) => {
  return {
    sentMessageLoading: messageReducer.sentMessageLoading,
    updateMessagesLoading: messageReducer.updateMessagesLoading,
    successCreateMessage: messageReducer.successCreateMessage,
    errorCreateMessage: messageReducer.errorCreateMessage,
    updatedSuccessMsg: messageReducer.updatedSuccessMsg,
    updatedErrorMsg: messageReducer.updatedErrorMsg,
    downloadedFileUrl: messageReducer.downloadedFileUrl,
    postedFilePath: messageReducer.postedFilePath,
    postFileLoading: messageReducer.postFileLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    createNewMessage: (payload) => {
      dispatch(actionType.createNewMessage(payload));
    },
    updateMultipleMessages: (payload) => {
      dispatch(actionType.updateMultipleMessages(payload));
    },
    sendFile: (payload) => {
      dispatch(actionType.sendFile(payload));
    },
    resetMessageExecuteStatus: () => {
      dispatch(actionType.resetMessageExecuteStatus());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Reply);
