import { Box, Typography, Stack } from "@mui/material";
import { privacyPolicy } from "../../shared/constants";
export default function PrivacyPolicy() {
  return (
    <Box className="container pad-16 mt-8" sx={{ margin: 3 }}>
      <Stack direction="column" spacing={0.5}>
        <Typography component="span" className="ml-8">
          {privacyPolicy.split("\n\n").map((paragraph, key) => (
            <p key={key}>
              {paragraph
                .split("\n")
                .reduce((total, line, index) => [total, <br key={index} />, line])}
            </p>
          ))}
        </Typography>
        <Typography variant="caption" className="footer-links">
          Reviced : March 16 2017
        </Typography>
        <Typography variant="caption" className="footer-links">
          © 2022 All rights reserved
        </Typography>
      </Stack>
    </Box>
  );
}
