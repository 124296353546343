import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Tab,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CardContent,
  Modal,
  IconButton,
  Checkbox,
  CardActionArea
} from "@mui/material";
import { useState, useEffect } from "react";
import * as actionType from "../../store/actions";
import StageServiceItemList from "./StageServiceItemList";
import FapView from "./FapView";
import NextSteps from "../../shared/components/NextStep";
import { connect, useSelector } from "react-redux";
//import ConfirmSelection from "./ConfirmSelection";
import MessageModal from "../../shared/components/MessageModal";
import { programBoxName, toUSAFormat } from "../../shared/constants";
// import { useNavigate } from "react-router-dom";

const EstimateCards = (props) => {
  const {
    actions,
    loading,
    estimateCards,
    stageLoading,
    necessaryLoading,
    adjustmentLoading,
    optionalLoading,
    stages,
    necessary,
    optional,
    adjustmentList,
    fapLoading,
    practiceData,
    programLoading,
    // programSelection,
    programSuccess,
    programError,
    profileData,
    fapList,
    practiceInfoData
  } = props;
  const practiceId = localStorage.getItem("practiceId");
  const patientId = localStorage.getItem("patientId");
  const [selectedCard, setSelectedCard] = useState({});
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [cards, setCards] = useState(estimateCards);
  const [data, setData] = useState(estimateCards);
  const [totalEstimate, setTotalEstimate] = useState(0);
  const [totalEstimateMax, setTotalEstimateMax] = useState(0);
  const [selectedProgramPrice, setSelectedProgramPrice] = useState(0);
  const [selectedProgramBox, setSelectedProgramBox] = useState(null);
  const [programName, setProgramName] = useState("");
  const [programNameWithNumber, setProgramNameWithNumber] = useState("");
  const [clearBox, setClearBox] = useState(false);
  const [isFapView, setIsFapView] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [selectedPMId, setSelectedPMId] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [refundOptionModal, setRefundOptionModal] = useState(false);
  const [refundCheck, setRefundCheck] = useState(false);
  const [isProceedWithoutPgta, setIsProceedWithoutPgta] = useState(null);
  const [isOptout, setIsOptout] = useState(true);
  const [openConfirmPage, setOpenConfirmPage] = useState(false);
  const caseList = useSelector((state) => state.homeReducer.caseStatusList);
  const [isMessageModal, setIsMessageModal] = useState(false);
  // const navigate = useNavigate();
  const handleNetworkCall = (item) => {
    let payload = {
      practiceId, // stages
      gdmId: item.gdmId, // stages
      treatmentPlanId: item.treatmentPlanId, // stages
      estimateId: caseList.finEstimateId,
      estimateTpId: item.finEstimateTpId
    };
    actions.getStages(payload);
    actions.getAdjustments(payload);
    actions.getNecessary(payload);
    actions.getOptional(payload);
    if (item.treatmentType.toString().toLowerCase().includes("ivf")) {
      actions.getFertilityAccessPrograms(payload);
    }
  };

  useEffect(() => {
    if (programSuccess || programError) {
      setIsMessageModal(true);
    }
  }, [programError, programSuccess]);

  useEffect(() => {
    if (estimateCards.length == 0) {
      actions.getEstimateCard({ practiceId, estimateId: caseList && caseList.finEstimateId });
    }
    actions.getProfileData({
      practiceId,
      patientId
    });
    actions.getPracticePGTAData(practiceId);
  }, []);

  useEffect(() => {
    if (cards && cards.length) {
      let item = cards[0];

      cardClick(item);
    }
  }, []);

  useEffect(() => {
    //&& data.treatmentType !== "FP"
    if (necessary.length) {
      let normalPrice = necessary.map(({ tpStage, serviceItems }) => {
        if (!tpStage.range) {
          return serviceItems.map((item) => item.grossPrice).reduce((a, b) => a + b, 0);
        }
      });
      let rangeMinPrice = necessary.map(({ tpStage, serviceItems }) => {
        if (tpStage.range) {
          return serviceItems.map((item) => item.grossPrice)[0];
        }
      });
      let rangeMaxPrice = necessary.map(({ tpStage, serviceItems }) => {
        if (tpStage.range) {
          return serviceItems.map((item) => item.grossPrice)[serviceItems.length - 1];
        }
      });
      const minPrice = rangeMinPrice.filter((item) => item).reduce((a, b) => a + b, 0);
      const maxPrice = rangeMaxPrice.filter((item) => item).reduce((a, b) => a + b, 0);

      if (data && data.treatmentType === "PGT-A Analysis") {
        let originalTpPrice = data && data.originalTpPrice;
        setTotalEstimate(
          originalTpPrice + minPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
        );

        setTotalEstimateMax(
          originalTpPrice + maxPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
        );
      } else {
        setTotalEstimate(
          data.tpPrice + minPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
        );

        setTotalEstimateMax(
          data.tpPrice + maxPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
        );
      }

      // setTotalEstimate(
      //   data.tpPrice + minPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
      // );
      // setTotalEstimateMax(
      //   data.tpPrice + maxPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
      // );
    } else {
      if (data && data.treatmentType === "PGT-A Analysis") {
        let originalTpPrice = data && data.originalTpPrice;
        setTotalEstimate(originalTpPrice);
        setTotalEstimateMax(originalTpPrice);
      } else {
        setTotalEstimate(data.tpPrice);
        setTotalEstimateMax(data.tpPrice);
      }
    }
  }, [necessary]);

  useEffect(() => {
    if (!Object.keys(practiceInfoData).length) {
      let payload = {
        practiceId
      };
      actions.getPracticeInfoData(payload);
    }
  }, [practiceId]);

  const cardClick = (item) => {
    if (practiceData && practiceData.selectionChoice == "Optout") {
      setIsOptout(true);
    }

    actions.resetCostCoverage();
    setTabValue("1");
    setIsFapView(true);
    setSelectedCard(item);
    setData(item);
    setShowCardDetails(true);
    setCards(estimateCards);
    setProgramNameWithNumber("");
    setCards((prev) =>
      prev.map((items) =>
        items.finEstimateTpId === item.finEstimateTpId ? { ...items, bottom_border: true } : items
      )
    );
    handleNetworkCall(item);
    actions.reviewEstimate({
      estimateId: caseList.finEstimateId,
      estimateTpId: item.finEstimateTpId
    });
  };
  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setTabValue(newValue);
    // newValue == "1" ? setData(selectedCard) : setData(selectedCard.linkedEstimateTreatmentPlan);
    let newItem = newValue == "1" ? selectedCard : selectedCard.linkedEstimateTreatmentPlan;
    setData(newItem);
    handleNetworkCall(newItem);
    setIsFapView(true);
    console.log("IsProceedWithoutPgta", isProceedWithoutPgta);
    setIsProceedWithoutPgta(isProceedWithoutPgta);
  };
  const getProgramId = (programId, fapCardsStore) => {
    setSelectedProgramBox(programId);

    programId.programs.map((item) => {
      if (item.selected === true && programId.boxName !== programBoxName.PAY_PER_CYCLE) {
        setSelectedProgramId(item.programId);
        setSelectedPMId(item.pmId);
        if (programId.boxName == programBoxName.PAY_PER_CYCLE) {
          //let cycleCount = item.programName.replace(/(^\d+)(.+$)/i, "$1");
          // console.log()
          //let ivfPrice = item.programPrice - fapCardsStore.globalPriceOfFet * cycleCount;
          // console.log("item.programPrice", item.programPrice);
          // console.log("fapCardsStore.globalPriceOfFet", fapCardsStore.globalPriceOfFet);
          // console.log("fapCardsStore.item", item);
          // console.log("fapCardsStore.item1", fapCardsStore);
          // let ivfPrice = item.programPrice - fapCardsStore.globalPriceOfFet;
          // setSelectedProgramPrice(ivfPrice);
        } else {
          setSelectedProgramPrice(item.programPrice);
        }
      } else if (programId.boxName == programBoxName.PAY_PER_CYCLE) {
        const oneCycle = "1 cycle";
        if (item.programName.toLowerCase() === oneCycle.toLowerCase() && item.noOfCycle === 1) {
          setSelectedProgramId(item.programId);
          setSelectedPMId(item.pmId);
          let ivfPrice = item.programPrice - fapCardsStore.globalPriceOfFet;
          setSelectedProgramPrice(ivfPrice);
        }
      }
    });
  };
  const boxName = (data) => {
    setProgramName(data.boxName);
    if (data.boxName === programBoxName.PAY_PER_CYCLE) {
      setProgramNameWithNumber("Pay Per Cycle");
    } else {
      let seletedBox =
        data && data.programs && data.programs.filter((pData) => pData.selected === true);

      setProgramNameWithNumber(seletedBox && seletedBox[0] && seletedBox[0].programName);
      if (data.boxName == "Single cycle") {
        setSelectedProgramPrice(data.programs[data.programs.length - 1].programPrice);
      }
    }
  };

  const handleProgramSelect = (prgrm, fapCardsStore) => {
    getProgramId(prgrm, fapCardsStore);
    boxName(prgrm);

    handleSelectAndProceed();
    window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
  };

  const handleSelectAndProceed = () => {
    //setIsProceedWithoutPgta(null);
    if (selectedCard.linkedEstimateTreatmentPlan != null && tabValue == "1") {
      setTabValue("2");
      setData(selectedCard.linkedEstimateTreatmentPlan);
      setIsFapView(false);
    } else if (
      selectedCard.refundApplicationStatus === "Submitted Need Clinical Review" &&
      selectedCard.treatmentType.toString().toLowerCase().includes("ivf") &&
      data.linkedEstimateTreatmentPlan == null
    ) {
      setRefundOptionModal(true);
      setRefundCheck(false);
    } else if (selectedCard.linkedEstimateTreatmentPlan == null || tabValue == "2") {
      setConfirmModal(true);
    }
    if (practiceData && practiceData.selectionChoice === "Voluntary" && tabValue == "2") {
      setIsProceedWithoutPgta(false);
    }
  };
  const handlePgtaClick = () => {
    setIsProceedWithoutPgta(true);
    setConfirmModal(true);
  };
  const handleCancelClick = () => {
    setIsProceedWithoutPgta(null);
    setIsOptout(true);
    if (tabValue == "2") {
      setTabValue("1");
      setIsFapView(true);
      setData(selectedCard);
    }
    if (tabValue == "1") {
      setClearBox(true);
      setSelectedProgramId(null);
      setSelectedPMId(null);
    }
  };

  const handleConfirm = () => {
    setClearBox(false);
    let cycleName = "";

    if (selectedProgramBox && selectedProgramBox.programs) {
      selectedProgramBox?.programs.map((item) => {
        if (item.selected === true) {
          cycleName = item.programName;
        }
      });
    }

    let body = {
      programId: selectedProgramId,
      pmId: selectedPMId,
      programName: selectedProgramBox?.boxName || "",
      cycleName: cycleName || "",
      cycleCost: selectedProgramPrice,
      selectedEstimateTreatmentPlan: {
        ...selectedCard,
        linkedEstimateTreatmentPlan: isProceedWithoutPgta
          ? null
          : selectedCard.linkedEstimateTreatmentPlan,
        isSelected: 1,
        isReview: 1
      }
    };
    delete body.selectedEstimateTreatmentPlan.bottom_border;
    const payload = {
      practiceId,
      patientId,
      estimateId: caseList.finEstimateId,
      body
    };

    actions.updateProgramSelections(payload);
  };
  const handleChangeRadio = (e) => {
    console.log("hi");
    e.target.value == "Yes" ? setIsProceedWithoutPgta(false) : setIsProceedWithoutPgta(true);
    setIsOptout(false);
  };

  const disableSelectBtn = () => {
    console.log("programNameWithNumber", programNameWithNumber);
    console.log("programName", programName);
    console.log("isOptout", isOptout);
    console.log("isProceedWithoutPgta", isProceedWithoutPgta);
    console.log("selectedCard", selectedCard);
    if (selectedCard && selectedCard.linkedEstimateTreatmentPlan) {
      return programNameWithNumber === "" || (isOptout && isProceedWithoutPgta == null)
        ? true
        : false;
    }
    if (selectedCard.treatmentType.toString().toLowerCase().includes("ivf") && tabValue == 1) {
      return programName == "" ? true : false;
    }
    if (isOptout && isProceedWithoutPgta == null) {
      return tabValue == 1 ? false : true;
    }
    return false;
  };

  const closeMessageModal = async () => {
    await actions.getCaseStatusesData({ practiceId, patientId });
    await actions.getEstimateCard({
      practiceId,
      estimateId: caseList.finEstimateId
    });
    setIsMessageModal(false);
    console.log("okay click");
    setOpenConfirmPage(true);
    actions.resetMsgs();
  };

  const isBundledSaving = (type) => {
    return ["ivf"].some((val) => {
      return (
        type.toString().toLowerCase().includes(val) &&
        fapList &&
        fapList.programVisualization &&
        fapList.programVisualization.length > 0
      );
    });
  };

  const showRefundApplicationBtton = (cardData) => {
    const patientDetail = cardData;
    const patientAge = patientDetail && Math.floor(profileData?.personAge);

    const donorMinAge = patientDetail && patientDetail.donorMinAge;
    const donorMaxAge = patientDetail && patientDetail.donorMaxAge;
    const nonDonorMinAge = patientDetail && patientDetail.nonDonorMinAge;
    const nonDonorMaxAge = patientDetail && patientDetail.nonDonorMaxAge;
    const primaryQuestionId = patientDetail && patientDetail.questionId; //  Question ID should be  : 2
    const primaryQuestionresponse = patientDetail && patientDetail.responseNo; //yes = 1, No = 2;

    let isButtonVisiable = false;

    if (primaryQuestionId === 2) {
      if (primaryQuestionresponse === 1) {
        // Yes Case
        if (patientAge >= donorMinAge && patientAge <= donorMaxAge) {
          isButtonVisiable = true;
        } else {
          isButtonVisiable = false;
        }
      } else {
        // No Case
        if (patientAge >= nonDonorMinAge && patientAge <= nonDonorMaxAge) {
          isButtonVisiable = true;
        } else {
          isButtonVisiable = false;
        }
      }
    }

    return isButtonVisiable;
  };

  return (
    <Box className="card-section">
      {!openConfirmPage && (
        <>
          {" "}
          {loading ? (
            <Stack justifyContent="center" p={5} alignItems="center">
              <CircularProgress size={50} />
            </Stack>
          ) : (
            <Grid container spacing={2}>
              {cards.map((item, index) => {
                return (
                  <Grid item xs={12} lg={4} key={index}>
                    <Box className={item.bottom_border ? "border-bottom" : ""}>
                      <span className={item.bottom_border ? "bubble-point" : ""} />
                      <Card
                        variant="outlined"
                        sx={{
                          minHeight: "300px",
                          borderBottomLeftRadius: item.bottom_border ? "0px" : "4px",
                          borderBottomRightRadius: item.bottom_border ? "0px" : "4px"
                        }}>
                        <CardActionArea onClick={() => cardClick(item)}>
                          <Stack direction="row" p={1}>
                            <Typography variant="body2">
                              {item.treatmentPlanName}
                              {item.tpLinkId ? " + PGT-A Analysis" : ""}
                            </Typography>
                          </Stack>
                          <Divider sx={{ background: "#1BA3AD", m: 1, height: "1px" }} />
                          <Card
                            variant="outlined"
                            sx={{
                              minHeight: 220,
                              background: item.bottom_border ? "#f9f9f9" : "",
                              borderBottomLeftRadius: item.bottom_border ? "0px" : "4px",
                              borderBottomRightRadius: item.bottom_border ? "0px" : "4px"
                            }}>
                            <Stack
                              direction="column"
                              minHeight={190}
                              justifyContent="space-between">
                              <Stack direction="column" p={1}>
                                <InputLabel>
                                  {item.treatmentType === "PGT-A Analysis"
                                    ? "Package price"
                                    : "Amount due to Practice"}
                                </InputLabel>
                                <Typography>
                                  {data && data.treatmentType !== "PGT-A Analysis"
                                    ? "$" + item.tpPrice.toLocaleString()
                                    : "$" + item.originalTpPrice.toLocaleString()}
                                  {/* ${item.tpPrice.toLocaleString()}{" "} */}
                                  {item.treatmentType === "PGT-A Analysis"
                                    ? " for the PGT-A Analysis Unlimited Program"
                                    : " per cycle"}
                                </Typography>
                                {isBundledSaving(item.treatmentType) ? (
                                  <Typography>(Bundled savings available)</Typography>
                                ) : null}
                              </Stack>
                              {item.tpLinkId ? (
                                <Stack direction="column" p={1}>
                                  <InputLabel>
                                    {item.linkedEstimateTreatmentPlan.treatmentType ===
                                    "PGT-A Analysis"
                                      ? "Package price"
                                      : "Amount due to Practice"}
                                  </InputLabel>
                                  {/* <InputLabel>Services</InputLabel> */}
                                  {/* <Typography>
                                {item.linkedEstimateTreatmentPlan.treatmentPlanName}
                              </Typography> */}
                                  <Typography>
                                    ${item.linkedEstimateTreatmentPlan.tpPrice.toLocaleString()}{" "}
                                    {item.linkedEstimateTreatmentPlan.treatmentType ===
                                    "PGT-A Analysis"
                                      ? " for the PGT-A Analysis Unlimited Program"
                                      : " per cycle"}
                                  </Typography>
                                </Stack>
                              ) : null}
                              <Stack justifyContent="space-around" className="pad-10">
                                {item &&
                                item.treatmentType.toUpperCase() === "IVF" &&
                                fapList &&
                                fapList.programVisualization &&
                                fapList.programVisualization.length > 0 ? (
                                  showRefundApplicationBtton(item) ? (
                                    <Typography variant="body2">
                                      REFUND APPLICATION:{" "}
                                      {item.refundApplicationStatus == null
                                        ? "Incomplete"
                                        : item.refundApplicationStatus}
                                    </Typography>
                                  ) : null
                                ) : null}
                              </Stack>
                            </Stack>
                            {/* <Divider sx={{ m: 1, height: "1px" }} /> */}
                            {/* <Button
                        variant="outlined"
                        component="span"
                        onClick={(e) => {
                          e.preventDefault();
                          cardClick(item);
                        }}
                        sx={{ my: 1, ml: 1, width: "fit-content" }}>
                        Review Details
                      </Button> */}
                          </Card>
                        </CardActionArea>
                      </Card>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
          {showCardDetails ? (
            <Box flexDirection="row" className="p-1" mt={2}>
              <TabContext value={tabValue}>
                <Box>
                  <TabList onChange={handleTabChange} className="primary-one">
                    <Tab label={selectedCard.treatmentPlanName} value="1" />
                    {selectedCard.tpLinkId ? (
                      practiceData && practiceData.selectionChoice !== "NA" ? (
                        <Tab
                          label={selectedCard.linkedEstimateTreatmentPlan.treatmentPlanName}
                          value="2"
                        />
                      ) : null
                    ) : null}
                  </TabList>
                </Box>
                <TabPanel value="1" className="p-0">
                  {stageLoading && necessaryLoading && adjustmentLoading && optionalLoading ? (
                    <Stack justifyContent="center" p={5} alignItems="center">
                      <CircularProgress size={50} />
                    </Stack>
                  ) : (
                    <>
                      <Card className="p-4 mt-8" variant="outlined">
                        <CardContent className="remove-bottom-padding">
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">Treatment Plan</Typography>
                          </Stack>
                          <Divider className="h-divider" />
                          <Stack>
                            {/* <InputLabel>Plan Name</InputLabel> */}
                            <Typography variant="body2">{data.treatmentPlanName}</Typography>
                          </Stack>
                        </CardContent>
                      </Card>

                      <Card className="p-4 mt-8" variant="outlined">
                        <CardContent className="remove-bottom-padding">
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">Cost</Typography>
                          </Stack>
                          <Divider className="h-divider" />
                          <Grid container>
                            <Grid item xs={10} sm={8} md={6}>
                              <Stack>
                                <InputLabel>Services</InputLabel>
                                <Typography variant="body2">{data.treatmentPlanName}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                              <Stack>
                                <InputLabel>Cost</InputLabel>
                                <Typography variant="body2">
                                  ${data.originalTpPrice.toLocaleString()}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>

                          <Stack className="mt-8 ">
                            {stages.length && data?.treatmentType != "PGT-A Analysis"
                              ? stages.map((item, index) => {
                                  return <Typography key={index}>{item.stageName}</Typography>;
                                })
                              : null}
                          </Stack>
                          {adjustmentList.length &&
                          data &&
                          data.treatmentType !== "PGT-A Analysis" ? (
                            <>
                              <Stack py={1}>
                                <Typography variant="body2">Adjustments</Typography>
                              </Stack>
                              {adjustmentList.map((item, i) => {
                                return (
                                  <Grid container key={i}>
                                    <Grid item xs={10} sm={8} md={6}>
                                      <Stack>
                                        <Typography>{item.type} Discount</Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={2} sm={4} md={6}>
                                      <Stack width="100px" justifyContent="end">
                                        <Typography>
                                          {item.category === "G" || item.category === "LI"
                                            ? item.amountType == "A"
                                              ? ` - $${item.amount.toLocaleString()}`
                                              : ` - $${Math.round(
                                                  (item.amount / 100) * data.originalTpPrice
                                                ).toLocaleString()}`
                                            : `+ $${item.amount.toLocaleString()}`}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </>
                          ) : null}

                          <Grid container className="add-to-practice">
                            <Grid item xs={10} sm={8} md={6}>
                              <Typography variant="body2">
                                {data && data.treatmentType === "PGT-A Analysis"
                                  ? "Total Cost of Program"
                                  : data && data.treatmentType && data.treatmentType === "IVF"
                                  ? "Cost per Cycle"
                                  : "Amount Due to Practice"}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                              <Typography variant="body2">
                                {data && data.treatmentType === "PGT-A Analysis"
                                  ? "$" + data.originalTpPrice.toLocaleString()
                                  : "$" + data.tpPrice.toLocaleString()}
                                {/* ${data.tpPrice.toLocaleString()} */}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box className="mt-8">
                            <Typography variant="body2">Not Included But Necessary</Typography>
                            {/* data?.treatmentType != "PGT-A Analysis" */}
                            {necessary.length ? (
                              <StageServiceItemList
                                data={necessary}
                                necessaryLoading={necessaryLoading}
                              />
                            ) : null}
                          </Box>
                          <Divider className="mt-8" />
                          <Grid container className="add-to-practice">
                            <Grid item xs={10} sm={8} md={6}>
                              <Typography variant="body2">
                                {data && data.treatmentType && data.treatmentType === "IVF"
                                  ? "Total Estimated Cost per Cycle"
                                  : "Total Estimated Costs"}
                              </Typography>
                              <Typography
                                variant="caption"
                                style={{ lineHeight: "15px", display: "block", padding: "3px" }}>
                                (Reflects only necessary services and includes costs due outside of
                                the practice)
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                              {tabValue == "1" ? (
                                <Typography variant="body2">
                                  ${totalEstimate && totalEstimate.toLocaleString()}
                                  {necessary?.some((item) => item.tpStage.range === true) > 0
                                    ? " - $" +
                                      (totalEstimateMax && totalEstimateMax.toLocaleString())
                                    : ""}
                                </Typography>
                              ) : (
                                <Typography>${data.tpPrice.toLocaleString()}</Typography>
                              )}
                            </Grid>
                          </Grid>
                          {data && data.depositeType === "Partial In Advance" ? (
                            <>
                              <Divider className="mt-8" />
                              <Grid container className="add-to-practice">
                                <Grid item xs={10} sm={8} md={6}>
                                  <Typography variant="body2">
                                    Deposit Due to Begin Treatment
                                    <Typography variant="caption" pl={1}>
                                      {data.depositeComment ?? ""}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                  <Typography variant="body2">
                                    ${data.depositeAmount && data.depositeAmount.toLocaleString()}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : null}
                          <Box className="p-4 mt-8">
                            <Typography variant="body2">
                              Not Included But May Become Necessary
                            </Typography>
                            {optional.length && data?.treatmentType != "PGT-A Analysis" ? (
                              <StageServiceItemList data={optional} />
                            ) : null}
                          </Box>
                        </CardContent>
                      </Card>
                    </>
                  )}
                </TabPanel>
                <TabPanel value="2" className="p-0">
                  {stageLoading && necessaryLoading && adjustmentLoading && optionalLoading ? (
                    <Stack justifyContent="center" p={5} alignItems="center">
                      <CircularProgress size={50} />
                    </Stack>
                  ) : (
                    <>
                      <Card className="p-4 mt-8" variant="outlined">
                        <CardContent className="remove-bottom-padding">
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">Treatment Plan</Typography>
                          </Stack>
                          <Divider className="h-divider" />
                          <Stack>
                            {/* <InputLabel>Plan Name</InputLabel> */}
                            <Typography variant="body2">{data.treatmentPlanName}</Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                      {practiceData &&
                      practiceData.selectionChoice == "Optout" &&
                      tabValue == "2" ? (
                        <Card className="p-4 mt-8" variant="outlined">
                          <CardContent className="remove-bottom-padding">
                            <Stack direction="row" justifyContent="space-between">
                              <Typography variant="body2">Program Selection</Typography>
                            </Stack>
                            <Divider className="h-divider" />
                            <Stack direction="row">
                              <FormControl>
                                <FormLabel>Select a PGT-A Analysis payment option:</FormLabel>
                                <RadioGroup name="pgtaQuestion" onChange={handleChangeRadio}>
                                  <Stack direction={{ xs: "column", sm: "row" }}>
                                    <FormControlLabel
                                      checked={isProceedWithoutPgta === false ? true : false}
                                      value="Yes"
                                      control={<Radio />}
                                      label="PGT-A Analysis Unlimited Program that offers bundled savings and includes all PGT-A testing until a live birth occurs"
                                    />
                                    <FormControlLabel
                                      checked={isProceedWithoutPgta === true ? true : false}
                                      value="No"
                                      control={<Radio />}
                                      label="I do not want to bundle the cost of PGT-A.  I will pay the genetic lab directly per embryo tested."
                                    />
                                  </Stack>
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          </CardContent>
                        </Card>
                      ) : null}
                      <Card className="p-4 mt-8" variant="outlined">
                        <CardContent className="remove-bottom-padding">
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2">Cost</Typography>
                          </Stack>
                          <Divider className="h-divider" />
                          <Grid container>
                            <Grid item xs={10} sm={8} md={6}>
                              <Stack>
                                <InputLabel>Services</InputLabel>
                                <Typography variant="body2">{data.treatmentPlanName}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                              <Stack>
                                <InputLabel>Cost</InputLabel>
                                <Typography variant="body2">
                                  ${data.originalTpPrice.toLocaleString()}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>

                          <Stack className="mt-8 ">
                            {stages.length && data?.treatmentType != "PGT-A Analysis"
                              ? stages.map((item, index) => {
                                  return <Typography key={index}>{item.stageName}</Typography>;
                                })
                              : null}
                          </Stack>
                          {adjustmentList.length &&
                          data &&
                          data.treatmentType !== "PGT-A Analysis" ? (
                            <>
                              <Stack py={1}>
                                <Typography variant="body2">Adjustments</Typography>
                              </Stack>
                              {adjustmentList.map((item, i) => {
                                return (
                                  <Grid container key={i}>
                                    <Grid item xs={10} sm={8} md={6}>
                                      <Stack>
                                        <Typography>{item.type} Discount</Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={2} sm={4} md={6}>
                                      <Stack width="100px" justifyContent="end">
                                        <Typography>
                                          {item.category === "G" || item.category === "LI"
                                            ? item.amountType == "A"
                                              ? ` - $${item.amount.toLocaleString()}`
                                              : ` - $${(
                                                  (item.amount / 100) *
                                                  data.originalTpPrice
                                                ).toLocaleString()}`
                                            : `+ $${item.amount.toLocaleString()}`}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </>
                          ) : null}

                          <Grid container className="add-to-practice">
                            <Grid item xs={10} sm={8} md={6}>
                              <Typography variant="body2">
                                {data?.treatmentType === "PGT-A Analysis"
                                  ? "Total Cost of Program"
                                  : "Amount Due to Practice"}
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                              <Typography variant="body2">
                                {data && data.treatmentType === "PGT-A Analysis"
                                  ? "$" + data.originalTpPrice.toLocaleString()
                                  : "$" + data.tpPrice.toLocaleString()}
                                {/* ${data.tpPrice.toLocaleString()} */}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box className="mt-8">
                            <Typography variant="body2">Not Included But Necessary</Typography>
                            {necessary.length && data?.treatmentType != "PGT-A Analysis" ? (
                              <StageServiceItemList
                                data={necessary}
                                necessaryLoading={necessaryLoading}
                              />
                            ) : null}
                          </Box>
                          <Divider className="mt-8" />
                          <Grid container className="add-to-practice">
                            <Grid item xs={10} sm={8} md={6}>
                              <Typography variant="body2">Total Estimated Costs</Typography>
                              <Typography variant="caption">
                                (Reflects only necessary services and includes costs due outside of
                                the practice)
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                              {tabValue == "1" ? (
                                <Typography variant="body2">
                                  ${totalEstimate && totalEstimate.toLocaleString()}
                                  {necessary?.some((item) => item.tpStage.range === true) > 0
                                    ? " - $" +
                                      (totalEstimateMax ? totalEstimateMax.toLocaleString() : "")
                                    : ""}
                                </Typography>
                              ) : (
                                <Typography>${data.tpPrice.toLocaleString()}</Typography>
                              )}
                            </Grid>
                          </Grid>

                          <Box className="p-4 mt-8">
                            <Typography variant="body2">
                              Not Included But May Become Necessary
                            </Typography>
                            {optional.length && data?.treatmentType != "PGT-A Analysis" ? (
                              <StageServiceItemList data={optional} />
                            ) : null}
                          </Box>
                        </CardContent>
                      </Card>
                    </>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          ) : null}
          {isFapView & data?.treatmentType?.toString().toLowerCase().includes("ivf") &&
          fapList &&
          fapList.programVisualization &&
          fapList.programVisualization.length > 0 ? (
            fapLoading ? (
              <Stack justifyContent="center" p={5} alignItems="center">
                <CircularProgress size={50} />
              </Stack>
            ) : (
              <FapView
                handleProgramSelect={handleProgramSelect}
                // getProgramId={getProgramId}
                setBoxName={boxName}
                clearBox={clearBox}
                cardData={data}
              />
            )
          ) : null}
          {showCardDetails &&
          (tabValue == "2" ||
            !(
              isFapView &&
              data?.treatmentType?.toString().toLowerCase().includes("ivf") &&
              fapList &&
              fapList.programVisualization &&
              fapList.programVisualization.length > 0
            )) ? (
            <Stack
              direction="row"
              gap={1}
              className="mr-8 m-t-10"
              alignItems="center"
              justifyContent="flex-end">
              <Button variant="outlined" onClick={handleCancelClick}>
                Cancel
              </Button>
              {practiceData && practiceData.selectionChoice == "Voluntary" && tabValue == "2" && (
                <Button variant="contained" onClick={handlePgtaClick}>
                  Proceed Without PGT-A
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleSelectAndProceed}
                disabled={disableSelectBtn()}>
                Select And Proceed
              </Button>
            </Stack>
          ) : null}
        </>
      )}

      {/* {console.log("openConfirmPage", openConfirmPage)} */}
      {/* {openConfirmPage === true ? <ConfirmSelection /> : null} */}
      <NextSteps practiceInfo={practiceInfoData} />
      {/* refund popup */}
      {refundOptionModal ? (
        <Modal open={refundOptionModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="refund-option-modal">
            <Stack direction="row" className="modal-stack">
              <Typography>PROCEED WITHOUT REFUND OPTION CONFIRMATION</Typography>
              <IconButton onClick={() => setRefundOptionModal(false)}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Stack direction="column" py={2}>
              <Typography>
                You may still be eligible for a refund when you receive your application decesion.
                Are you sure you want to continue without a refund application? If you do not bring
                home a baby, you will not receive a refund.
              </Typography>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    value={refundCheck}
                    checked={refundCheck}
                    onChange={(e) => setRefundCheck(e.target.checked)}
                  />
                }
                label="I do not want a refund option if I become eligible"
                labelPlacement="end"
              />
              <Typography>
                Please contact a Fertility Access Concierge at
                <span className="theme-color"> 1-800-873-9203</span>
              </Typography>
            </Stack>
            <Stack direction="row" gap={1} className="align-end">
              <Button
                variant="outlined"
                onClick={() => {
                  setRefundOptionModal(false);
                }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={!refundCheck}
                onClick={() => {
                  setRefundOptionModal(false);
                  setConfirmModal(true);
                }}>
                Continue
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}

      {/* confirm popup */}
      {confirmModal ? (
        <Modal open={confirmModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal">
            <Stack direction="row" className="modal-stack">
              {/* <Typography>Proceed Without Refund Option Confirmation</Typography> */}
              <Typography>CONFIRM YOUR SELECTION</Typography>
              <IconButton onClick={() => setConfirmModal(false)}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box>
              <Typography my={2}>
                Should you choose to change your selection after this confirmation, please call us
                at{" "}
                <span className="primary-one">
                  {(practiceInfoData &&
                    practiceInfoData.phoneNumber &&
                    toUSAFormat(practiceInfoData.phoneNumber)) ||
                    ""}
                </span>
              </Typography>
              <Stack direction="row" py={1} justifyContent="space-between">
                <Stack direction="column" mx={1} mb={1}>
                  <InputLabel sx={{ fontSize: 11 }}>Treatment Plan:</InputLabel>

                  <Typography>{selectedCard.treatmentPlanName}</Typography>
                  {selectedCard.linkedEstimateTreatmentPlan != null && !isProceedWithoutPgta ? (
                    <Typography>
                      {selectedCard?.linkedEstimateTreatmentPlan.treatmentPlanName}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack direction="column" mx={1} alignItems="end"></Stack>
              </Stack>
              {/* {selectedCard.linkedEstimateTreatmentPlan != null && !isProceedWithoutPgta ? (
                <Stack direction="row" py={1} justifyContent="space-between">
                  <Stack direction="column" mx={1} mb={1}>
                    <InputLabel sx={{ fontSize: 11 }}>Treatment Plan:</InputLabel>
                    <Typography>
                      {selectedCard?.linkedEstimateTreatmentPlan.treatmentPlanName}
                    </Typography>
                  </Stack>
                  <Stack direction="column" mx={1} alignItems="end"></Stack>
                </Stack>
              ) : (
                ""
              )} */}
              <Stack direction="row" className="total-cost" justifyContent="space-between">
                <Stack direction="column" mx={1} mb={1}>
                  <InputLabel sx={{ fontSize: 11 }}>Payment option selected</InputLabel>
                  <Typography style={{ marginTop: "3px" }}>
                    {console.log("programNameWithNumber", programNameWithNumber)}
                    {selectedCard?.treatmentType === "IVF"
                      ? (programNameWithNumber && programNameWithNumber) || "--"
                      : selectedCard?.treatmentType === "PGT-A Analysis"
                      ? selectedCard?.linkProgram
                      : ""}
                  </Typography>

                  {selectedCard?.linkedEstimateTreatmentPlan != null && !isProceedWithoutPgta ? (
                    <Typography style={{ marginTop: "3px" }}>
                      {/* {selectedCard?.linkedEstimateTreatmentPlan?.treatmentPlanName || ""} */}
                      {selectedCard?.linkProgram || ""}
                    </Typography>
                  ) : null}
                </Stack>
                <Stack direction="column" mx={1} alignItems="end">
                  <InputLabel sx={{ fontSize: "11px" }}>Cost</InputLabel>
                  {selectedCard?.linkedEstimateTreatmentPlan != null && (
                    <>
                      <Typography style={{ marginTop: "3px" }}>
                        ${selectedProgramPrice.toLocaleString()}
                      </Typography>
                      {!isProceedWithoutPgta ? (
                        <Typography style={{ marginTop: "3px" }}>
                          $
                          {(
                            selectedCard?.linkedEstimateTreatmentPlan?.tpPrice || ""
                          ).toLocaleString()}
                        </Typography>
                      ) : null}
                    </>
                  )}

                  {selectedCard.treatmentType.indexOf("IVF") == -1 &&
                    selectedCard.treatmentType !== "FP" &&
                    selectedCard.treatmentType !== "PGT-A Analysis" && (
                      <Typography style={{ marginTop: "3px" }}>
                        ${totalEstimate && totalEstimate.toLocaleString()}
                      </Typography>
                    )}

                  {selectedCard.treatmentType === "PGT-A Analysis" && (
                    <Typography style={{ marginTop: "3px" }}>
                      ${(selectedCard?.tpPrice || "").toLocaleString()}
                    </Typography>
                  )}
                  {selectedCard.treatmentType === "FP" && (
                    <Typography style={{ marginTop: "3px" }}>
                      ${(selectedCard?.tpPrice || "").toLocaleString()}
                    </Typography>
                  )}
                  {selectedCard.treatmentType.toString().toLowerCase().includes("ivf") &&
                    selectedCard?.linkedEstimateTreatmentPlan == null && (
                      <Typography style={{ marginTop: "3px" }}>
                        ${selectedProgramPrice.toLocaleString()}
                      </Typography>
                    )}
                </Stack>
              </Stack>
            </Box>
            <Stack direction="row" gap={1} mt={3} className="align-right">
              <Button variant="outlined" onClick={() => setConfirmModal(false)}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleConfirm}>
                Confirm
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}

      {/* confirm popup */}
      {programLoading ? (
        <Modal open={programLoading} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}

      {isMessageModal ? (
        <MessageModal
          open={isMessageModal}
          message={programSuccess}
          error={programError}
          closeModal={closeMessageModal}
        />
      ) : null}
    </Box>
  );
};
const mapStateToProps = ({ estimateReducer, profileReducer }) => {
  return {
    estimateCards: estimateReducer.estimateCards,
    loading: estimateReducer.estimateCardLoading,
    stageLoading: estimateReducer.stageLoading,
    necessaryLoading: estimateReducer.necessaryLoading,
    adjustmentLoading: estimateReducer.adjustmentLoading,
    optionalLoading: estimateReducer.optionalLoading,
    stages: estimateReducer.stages,
    necessary: estimateReducer.necessary,
    optional: estimateReducer.optional,
    adjustmentList: estimateReducer.adjustmentList,
    fapLoading: estimateReducer.fapLoading,
    practiceData: estimateReducer.practiceData,
    programLoading: estimateReducer.programLoading,
    programSelection: estimateReducer.programSelection,
    programSuccess: estimateReducer.programSuccess,
    programError: estimateReducer.programError,
    profileData: profileReducer.profileData,
    fapList: estimateReducer.fapList,
    practiceInfoData: profileReducer.practiceInfoData,
    estimateCardLoading: estimateReducer.estimateCardLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    getAdjustments: (payload) => {
      dispatch(actionType.getAdjustments(payload));
    },
    getNecessary: (payload) => {
      dispatch(actionType.getNecessary(payload));
    },
    getOptional: (payload) => {
      dispatch(actionType.getOptional(payload));
    },
    resetCostCoverage: () => {
      dispatch(actionType.resetCostCoverage());
    },
    getFertilityAccessPrograms: (payload) => {
      dispatch(actionType.getFertilityAccessPrograms(payload));
    },
    getPracticePGTAData: (payload) => {
      dispatch(actionType.getPracticePGTAData(payload));
    },
    updateProgramSelections: (payload) => {
      dispatch(actionType.updateProgramSelections(payload));
    },
    reviewEstimate: (payload) => {
      dispatch(actionType.reviewEstimate(payload));
    },
    resetEstimateCards: () => {
      dispatch(actionType.resetEstimateCards());
    },
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    getCaseStatusesData: (payload) => {
      dispatch(actionType.getCaseStatusesData(payload));
    },
    resetCaseStatus: () => {
      dispatch(actionType.resetCaseStatus());
    },
    resetMsgs: () => {
      dispatch(actionType.resetMsgs());
    },
    getProfileData: (payload) => {
      dispatch(actionType.getProfileData(payload));
    },
    getPracticeInfoData: (payload) => {
      dispatch(actionType.getPracticeInfoData(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EstimateCards);
