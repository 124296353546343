import { Card, InputLabel, Divider, Typography, Grid, Stack, Skeleton, Box } from "@mui/material";
import StageServiceItemList from "../../components/estimateTreatment/StageServiceItemList";
import { useEffect, useState } from "react";
import * as actionType from "../../store/actions";
import { connect, useSelector } from "react-redux";

const SelectedProgramPayBill = (props) => {
  const {
    actions,
    stages = [],
    necessary,
    optional,
    estimateCards,
    fapList,
    // cardLoading,
    stageLoading,
    necessaryLoading,
    optionalLoading,
    fapLoading,
    optionalLoaded,
    stagesloaded,
    necessaryLoaded,
    selectedInvoice,
    handleProgramFees,
    prgramFeesNotSet,
    adjustmentList,
    notes,
    stPlan,
    payProgramFees,
    payProgramName
    // noteLoading
  } = props;
  const caseList = useSelector((state) => state.homeReducer.caseStatusList);
  const practiceId = localStorage.getItem("practiceId");
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState({});
  const [programPrice, setProgramPrice] = useState(0);
  const [programName, setProgramName] = useState("");
  const [totalEstimate, setTotalEstimate] = useState(0);
  const [totalEstimateMax, setTotalEstimateMax] = useState(0);
  const [treatmentType, setTreatmentType] = useState("");

  useEffect(() => {
    console.log(estimateCards, "zhr estimateCards");
    console.log(selectedInvoice, "zhr selectedInvoice");
    if (estimateCards.length) {
      let estimateCardsSelected = estimateCards.filter((item) => {
        return item.isSelected == "1";
      });

      let treatmentType = "";
      if (
        estimateCardsSelected &&
        estimateCardsSelected[0] &&
        estimateCardsSelected[0].linkedEstimateTreatmentPlan
      ) {
        console.log("zhr", estimateCardsSelected[0].linkedEstimateTreatmentPlan);

        let linkedEstimate = estimateCardsSelected[0].linkedEstimateTreatmentPlan;
        if (linkedEstimate.invoiceNo === selectedInvoice.invoiceNo) {
          treatmentType = linkedEstimate.treatmentType;

          setSelectedTreatmentPlan(linkedEstimate);
        } else {
          treatmentType = estimateCardsSelected[0].treatmentType;

          setSelectedTreatmentPlan(estimateCardsSelected[0]);
        }
      } else {
        treatmentType =
          estimateCardsSelected &&
          estimateCardsSelected[0] &&
          estimateCardsSelected[0].treatmentType;
        setSelectedTreatmentPlan(estimateCardsSelected[0]);
      }

      const payload = {
        practiceId,
        gdmId: selectedInvoice?.gdmId,
        // treatmentPlanId: selectedInvoice?.treatmentPlanId,
        treatmentPlanId: selectedInvoice?.treatmentPlanId,
        estimateId: selectedInvoice?.finEstimateId,
        estimateTpId: selectedInvoice?.finEstimateTpId
      };
      console.log("zhr payload", payload, stagesloaded, necessaryLoaded, optionalLoaded);
      // if (stages.length == 0 && !stagesloaded) {
      //   actions.getStages(payload);
      // }
      actions.getStages(payload);
      actions.getNecessary(payload);
      actions.getOptional(payload);
      // if (necessary.length == 0 && !necessaryLoaded) {
      //   actions.getNecessary(payload);
      // }
      // if (optional.length == 0 && !optionalLoaded) {
      //   actions.getOptional(payload);
      // }
      console.log("zhr treatmentType", treatmentType);
      setTreatmentType(treatmentType);
      if (treatmentType.indexOf("IVF") != -1) {
        console.log("caselist", caseList);
        // if (fapList?.programVisualization == undefined) {
        actions.getFertilityAccessPrograms({
          ...payload,
          estimateId: selectedInvoice?.finEstimateId,
          estimateTpId: selectedInvoice?.finEstimateTpId
        });
        // }
      }

      actions.getNotes(payload);
      actions.getAdjustments(payload);
    }
  }, [selectedInvoice]);

  console.log("zhr necessary:", necessary);

  useEffect(() => {
    if (estimateCards.length != 0 && necessary != null) {
      let estimateCardsSelected = estimateCards.filter((item) => {
        return item.isSelected == "1";
      });
      let res = {};
      let islinkedEstimate = false;
      if (
        estimateCardsSelected &&
        estimateCardsSelected[0] &&
        estimateCardsSelected[0].linkedEstimateTreatmentPlan
      ) {
        let linkedEstimate = estimateCardsSelected[0].linkedEstimateTreatmentPlan;
        if (linkedEstimate.invoiceNo === selectedInvoice.invoiceNo) {
          res[0] = linkedEstimate;
          islinkedEstimate = true;
        } else {
          res = estimateCardsSelected;
        }
      } else {
        res = estimateCardsSelected;
      }

      if (res[0]?.treatmentType.indexOf("IVF") != -1) {
        if (
          fapList.programVisualization != undefined &&
          fapList.programVisualization.length > 0 &&
          selectedInvoice
        ) {
          let programs = fapList.programVisualization
            .map((item) => item.programs)
            .flat()
            .filter(
              (item) =>
                item.programName.trim() ==
                (selectedInvoice?.programName
                  ? selectedInvoice?.programName
                  : selectedInvoice?.optionSelected)
            );
          // .filter((item) => item.programId == caseList.programId);
          console.log("zhr selectedInvoice.programName", selectedInvoice.programName);
          console.log("zhr programs", programs);
          setProgramName((programs && programs[0] && programs[0].programName) || "");
          if (islinkedEstimate) {
            if (res[0]?.isSelected == 1) {
              setProgramPrice(
                (programs && programs[0] && programs[0].programPrice + res[0]?.tpPrice) || 0
              );
            } else {
              setProgramPrice((programs && programs[0] && programs[0].programPrice) || 0);
            }
          } else {
            setProgramPrice((programs && programs[0] && programs[0].programPrice) || 0);
          }
        }
      } else {
        // other than IVF
        let normalPrice = necessary.map(({ tpStage, serviceItems }) => {
          if (!tpStage.range) {
            return serviceItems.map((item) => item.grossPrice).reduce((a, b) => a + b, 0);
          }
        });
        let rangeMinPrice = necessary.map(({ tpStage, serviceItems }) => {
          if (tpStage.range) {
            return serviceItems.map((item) => item.grossPrice)[0];
          }
        });
        const minPrice = rangeMinPrice.filter((item) => item).reduce((a, b) => a + b, 0);

        setProgramName(res[0]?.optionSelected);
        if (res && res[0] && res[0].treatmentType === "PGT-A Analysis") {
          console.log("setProgramPrice", programPrice, res[0]);
          setProgramPrice(res[0]?.originalTpPrice);
          // setProgramPrice(
          //   res[0]?.originalTpPrice +
          //     minPrice +
          //     normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
          // );
        } else {
          setProgramPrice(
            res[0]?.tpPrice +
              minPrice +
              normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
          );
        }
      }
    }
  }, [fapLoading, necessaryLoading, selectedInvoice]);

  useEffect(() => {
    if (programPrice && !prgramFeesNotSet) {
      handleProgramFees(programPrice);
    }
  }, [programPrice]);

  useEffect(() => {
    if (selectedTreatmentPlan) {
      if (necessary.length) {
        console.log("necessary", necessary);
        let normalPrice = necessary.map(({ tpStage, serviceItems }) => {
          if (!tpStage.range) {
            return serviceItems.map((item) => item.grossPrice).reduce((a, b) => a + b, 0);
          }
        });
        let rangeMinPrice = necessary.map(({ tpStage, serviceItems }) => {
          if (tpStage.range) {
            return serviceItems.map((item) => item.grossPrice)[0];
          }
        });
        let rangeMaxPrice = necessary.map(({ tpStage, serviceItems }) => {
          if (tpStage.range) {
            return serviceItems.map((item) => item.grossPrice)[serviceItems.length - 1];
          }
        });
        const minPrice = rangeMinPrice.filter((item) => item).reduce((a, b) => a + b, 0);
        const maxPrice = rangeMaxPrice.filter((item) => item).reduce((a, b) => a + b, 0);

        if (selectedTreatmentPlan && selectedTreatmentPlan.treatmentType === "PGT-A Analysis") {
          let originalTpPrice = selectedTreatmentPlan && selectedTreatmentPlan.originalTpPrice;

          setTotalEstimate(
            originalTpPrice +
              minPrice +
              normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
          );

          setTotalEstimateMax(
            originalTpPrice +
              maxPrice +
              normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
          );
        } else {
          setTotalEstimate(
            selectedTreatmentPlan?.tpPrice +
              minPrice +
              normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
          );
          setTotalEstimateMax(
            selectedTreatmentPlan?.tpPrice +
              maxPrice +
              normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
          );
        }
      } else {
        if (selectedTreatmentPlan && selectedTreatmentPlan.treatmentType === "PGT-A Analysis") {
          setTotalEstimate(selectedTreatmentPlan?.originalTpPrice);
          setTotalEstimateMax(selectedTreatmentPlan?.originalTpPrice);
        } else {
          setTotalEstimate(selectedTreatmentPlan?.tpPrice);
          setTotalEstimateMax(selectedTreatmentPlan?.tpPrice);
        }
      }
    }
  }, [necessary, selectedTreatmentPlan]);

  return (
    <Card variant="outlined" sx={{ p: 1, mt: 1 }}>
      <Stack>
        <InputLabel>TREATMENT PLAN</InputLabel>
        <Divider className="modal-divider" />
        <Grid container mt={1}>
          <Grid item xs={12} lg={4}>
            {/* {selectedTreatmentPlan?.linkedEstimateTreatmentPlan ? (
              <Typography>
                {selectedTreatmentPlan?.treatmentPlanName} +{" "}
                {selectedTreatmentPlan?.linkedEstimateTreatmentPlan?.treatmentPlanName}
              </Typography>
            ) : (
              <Typography>{selectedTreatmentPlan?.treatmentPlanName}</Typography>
            )} */}
            <Typography>
              {selectedTreatmentPlan?.treatmentPlanCustomName != null
                ? selectedTreatmentPlan?.treatmentPlanCustomName
                : selectedTreatmentPlan?.treatmentPlanName}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Stack mr={1}>
              <InputLabel>ADDITIONAL SERVICES</InputLabel>
              <Typography>
                {/* {selectedTreatmentPlan?.linkedEstimateTreatmentPlan?.treatmentPlanName} */}
                --
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      {notes && !stPlan?.optionSelected ? (
        <Stack my={1}>
          <InputLabel>NOTES</InputLabel>
          <Divider className="modal-divider" />
          <Grid container mt={1}>
            <Grid item xs={12}>
              <Typography>{notes}</Typography>
            </Grid>
          </Grid>
        </Stack>
      ) : null}

      <Stack mt={3}>
        <InputLabel>COST</InputLabel>
        <Divider className="modal-divider" />
        <Grid container mt={1}>
          <Grid item xs={12} /* lg={4} */>
            {/* <Typography>Cost & Coverage</Typography> */}
            {/* </Grid>
          <Grid item xs={12} lg={8}> */}
            <Stack>
              <Typography variant="body2" my={1}>
                Included Services
              </Typography>

              {!stPlan?.optionSelected ? (
                <Stack>
                  <Box mt={0.5}>
                    <Grid container>
                      <Grid item xs={10} sm={8} md={6}>
                        <Typography variant="body2" my={1}>
                          {selectedTreatmentPlan?.treatmentPlanName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={4} md={6}>
                        <Stack>
                          <Typography variant="body2">
                            {selectedTreatmentPlan?.originalTpPrice &&
                              "$" + selectedTreatmentPlan?.originalTpPrice.toLocaleString()}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              ) : null}

              <Stack mt={0.5}>
                {stageLoading ? (
                  <Skeleton />
                ) : (
                  stages.map((item, index) => {
                    return <Typography key={index}>{item.stageName}</Typography>;
                  })
                )}
              </Stack>

              {adjustmentList.length &&
              !stPlan?.optionSelected &&
              selectedTreatmentPlan &&
              selectedTreatmentPlan.treatmentType !== "PGT-A Analysis" ? (
                <Stack>
                  <Box mt={0.5}>
                    <Stack py={1}>
                      <Typography variant="body2">Adjustments</Typography>
                    </Stack>
                    {adjustmentList.map((item, i) => {
                      return (
                        <Grid container key={i}>
                          <Grid item xs={10} sm={8} md={6}>
                            <Stack>
                              <Typography>{item.type} Discount</Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={2} sm={4} md={6}>
                            <Stack>
                              <Typography>
                                {item.category === "G" || item.category === "LI"
                                  ? item.amountType == "A"
                                    ? ` - $${item.amount.toLocaleString()}`
                                    : ` - $${Math.round(
                                        (item.amount / 100) * selectedTreatmentPlan?.originalTpPrice
                                      ).toLocaleString()}`
                                  : `+ $${item.amount.toLocaleString()}`}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Box>
                </Stack>
              ) : null}

              {!stPlan?.optionSelected ? (
                <Stack my={1}>
                  <Box mt={0.5}>
                    <Grid container>
                      <Grid item xs={10} sm={8} md={6}>
                        {/* {stPlan?.optionSelected || payProgramName ? (
                          <Typography variant="body2">Total Cost of Program</Typography>
                        ) : (
                          <Typography variant="body2" my={1}>
                            Amount Due to Practice
                          </Typography>
                        )} */}
                        <Typography variant="body2" my={1}>
                          {treatmentType === "IVF"
                            ? "Cost per Cycle (included in Program Fee)"
                            : treatmentType === "PGT-A Analysis"
                            ? "Total Cost of Program"
                            : "Amount Due to Practice"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={4} md={6}>
                        <Stack>
                          <Typography variant="body2" my={1}>
                            {selectedTreatmentPlan &&
                            selectedTreatmentPlan.treatmentType !== "PGT-A Analysis"
                              ? selectedTreatmentPlan?.tpPrice &&
                                "$" + selectedTreatmentPlan?.tpPrice.toLocaleString()
                              : selectedTreatmentPlan?.originalTpPrice &&
                                "$" + selectedTreatmentPlan?.originalTpPrice.toLocaleString()}
                            {/* $
                            {selectedTreatmentPlan?.tpPrice &&
                              selectedTreatmentPlan?.tpPrice.toLocaleString()} */}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              ) : null}

              <Stack>
                <Typography variant="body2" my={1}>
                  Not Included But Necessary
                </Typography>
                {necessaryLoading ? <Skeleton /> : <StageServiceItemList data={necessary} />}
              </Stack>
              {!stPlan?.optionSelected ? (
                <>
                  <Divider className="mt-8" />

                  <Stack>
                    <Box>
                      <Grid container className="add-to-practice">
                        <Grid item xs={10} sm={8} md={6}>
                          <Typography variant="body2">
                            {treatmentType === "IVF"
                              ? "Estimated Addtional Costs per Cycle (not included in Program Fee)"
                              : "Total Estimated Costs"}
                          </Typography>
                          <Typography variant="caption">
                            (Reflects only necessary services and includes costs due outside of the
                            practice)
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6}>
                          <Stack>
                            <Typography variant="body2">
                              ${totalEstimate && totalEstimate.toLocaleString()}
                              {necessary?.some((item) => item.tpStage.range === true) > 0
                                ? " - $" + (totalEstimateMax && totalEstimateMax.toLocaleString())
                                : ""}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>

                  <Divider className="mt-8" />
                  {selectedTreatmentPlan &&
                  selectedTreatmentPlan.depositeType === "Partial In Advance" ? (
                    <>
                      <Stack>
                        <Box>
                          <Grid container className="add-to-practice">
                            <Grid item xs={10} sm={8} md={6}>
                              <Typography variant="body2">
                                Deposit Due to Begin Treatment
                                <Typography variant="caption" pl={1}>
                                  {selectedTreatmentPlan.depositeComment ?? ""}
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item xs={2} sm={4} md={6}>
                              <Stack>
                                <Typography variant="body2">
                                  $
                                  {selectedTreatmentPlan.depositeAmount &&
                                    selectedTreatmentPlan.depositeAmount.toLocaleString()}
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    </>
                  ) : null}
                </>
              ) : null}

              <Stack>
                <Typography variant="body2" my={1}>
                  Not Included But May Become Necessary
                </Typography>
                {optionalLoading ? <Skeleton /> : <StageServiceItemList data={optional} />}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack mt={3}>
        <Grid container>
          <Grid item xs={12} md={4} lg={4} style={{ marginBottom: "5px" }}>
            <InputLabel>SELECTED PROGRAM</InputLabel>
            <Divider className="modal-divider" sx={{ mt: 1 }} />
            {fapLoading ? (
              <Skeleton width={50} />
            ) : payProgramName ? (
              <InputLabel>{payProgramName}</InputLabel>
            ) : (
              <InputLabel>{stPlan?.optionSelected ? programName : "Pay Per Cycle"}</InputLabel>
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={3} style={{ marginBottom: "5px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Grid item style={{ width: "100%" }}>
                <InputLabel>TREATMENT PLAN</InputLabel>
                <Divider className="modal-divider" sx={{ mt: 1 }} />
                <InputLabel>
                  {selectedTreatmentPlan?.treatmentPlanCustomName != null
                    ? selectedTreatmentPlan?.treatmentPlanCustomName
                    : selectedTreatmentPlan?.treatmentPlanName}
                </InputLabel>
              </Grid>
              <Grid item>
                <InputLabel>REFUND</InputLabel>
                <Divider className="modal-divider" sx={{ mt: 1 }} />
                <InputLabel>
                  {selectedTreatmentPlan?.refundPercentage !== null
                    ? " " + selectedTreatmentPlan?.refundPercentage + "%"
                    : "--"}
                </InputLabel>
              </Grid>
            </Stack>
          </Grid>
          {console.log("zhr stPlan", stPlan)}
          <Grid item xs={12} md={4} lg={5}>
            <Stack direction="row" alignItems="center" justifyContent="right">
              {stPlan?.optionSelected != null || payProgramName ? (
                <Typography variant="body2">TOTAL COST OF PROGRAM</Typography>
              ) : (
                <Typography variant="body2">AMOUNT DUE TO PRACTICE</Typography>
              )}
            </Stack>
            <Divider className="modal-divider" style={{ marginTop: "11px" }} /* sx={{ mt: 2 }} */ />
            <Stack direction="row" alignItems="center" justifyContent="right">
              {console.log("payProgramFees", payProgramFees)}
              {fapLoading ? (
                <Skeleton width={50} />
              ) : (
                <Typography variant="body2" fontSize={18}>
                  {stPlan?.optionSelected
                    ? programPrice.toLocaleString()
                    : payProgramFees
                    ? "$" + payProgramFees.toLocaleString()
                    : selectedTreatmentPlan?.originalTpPrice &&
                      selectedTreatmentPlan?.originalTpPrice.toLocaleString()}
                  {/* {(Math.round(programPrice / 100) * 100).toLocaleString()} */}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
        {/* <Divider className="modal-divider" sx={{ mt: 1 }} /> */}
        {/* <Grid container sx={{ mt: 1 }}>
          <Grid item xs={4} lg={4}>
            {fapLoading ? (
              <Skeleton width={50} />
            ) : payProgramName ? (
              <InputLabel>{payProgramName}</InputLabel>
            ) : (
              <InputLabel>{stPlan?.optionSelected ? programName : "Pay Per Cycle"}</InputLabel>
            )}
          </Grid>
          <Grid item xs={4} lg={3}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <InputLabel>{selectedTreatmentPlan?.treatmentPlanName}</InputLabel>
                {/* <InputLabel>
                  {selectedTreatmentPlan?.linkedEstimateTreatmentPlan?.optionSelected}
                </InputLabel> //*}
              </Stack>
              <InputLabel>
                {selectedTreatmentPlan?.refundPercentage !== null
                  ? " " + selectedTreatmentPlan?.refundPercentage + "%"
                  : "--"}
              </InputLabel>
            </Stack>
          </Grid>
          <Grid item xs={4} lg={5}>
            <Stack direction="row" alignItems="center" justifyContent="right">
              {fapLoading ? (
                <Skeleton width={50} />
              ) : (
                <Typography variant="body2" fontSize={18}>
                  {stPlan?.optionSelected
                    ? programPrice.toLocaleString()
                    : payProgramFees
                    ? "$" + payProgramFees.toLocaleString()
                    : selectedTreatmentPlan?.originalTpPrice &&
                      selectedTreatmentPlan?.originalTpPrice.toLocaleString()}
                  {/* {(Math.round(programPrice / 100) * 100).toLocaleString()} //*}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid> */}
      </Stack>
    </Card>
  );
};
const mapStateToProps = ({ estimateReducer }) => {
  return {
    stages: estimateReducer.stages,
    necessary: estimateReducer.necessary,
    optional: estimateReducer.optional,
    loading: estimateReducer.loading,
    error: estimateReducer.error,
    fapLoading: estimateReducer.fapLoading,
    fapList: estimateReducer.fapList,
    stageLoading: estimateReducer.stageLoading,
    optionalLoading: estimateReducer.optionalLoading,
    necessaryLoading: estimateReducer.necessaryLoading,
    stagesloaded: estimateReducer.stagesloaded,
    necessaryLoaded: estimateReducer.necessaryLoaded,
    optionalLoaded: estimateReducer.optionalLoaded,
    adjustmentList: estimateReducer.adjustmentList,
    notes: estimateReducer.notes
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    getNecessary: (payload) => {
      dispatch(actionType.getNecessary(payload));
    },
    getOptional: (payload) => {
      dispatch(actionType.getOptional(payload));
    },
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    getFertilityAccessPrograms: (payload) => {
      dispatch(actionType.getFertilityAccessPrograms(payload));
    },
    getAdjustments: (payload) => {
      dispatch(actionType.getAdjustments(payload));
    },
    getNotes: (payload) => {
      dispatch(actionType.getNotes(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedProgramPayBill);
