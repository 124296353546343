import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Card,
  CardMedia,
  CardContent
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../../store/actions";
import { Stack } from "@mui/system";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getLocaleDateTime } from "../../shared/constants/common";

const Draft = (props) => {
  const columns = [
    {
      field: "type",
      headerName: "CATEGORY",
      width: 200,
      renderCell: ({ row }) => {
        return <Typography className="word-wrap">{row.type}</Typography>;
      }
    },
    {
      field: "sentDate",
      headerName: "DATE",
      type: "date",
      width: 200,
      // renderCell: ({ row }) => {
      //   return <Typography>{row.sentDate}</Typography>;
      // }
      renderCell: ({ row }) => {
        return (
          <Typography>{getLocaleDateTime(row?.sentDate, "MM/DD/YYYY hh:mm A z", true)}</Typography>
        );
      }
    },
    {
      field: "subject",
      headerName: "SUBJECT",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.subject}</Typography>;
      }
    }
  ];
  const {
    actions,
    ListOfDraftMessages,
    ListOfMessagesLoading,
    deletedMessageLoading,
    downloadedFileUrl
  } = props;
  const [rows, setRows] = useState([]);
  const [openSelectedMessage, setOpenSelectedMessage] = useState({});
  const [selectedMessages, setSelectedMessages] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);
  const userId = localStorage.getItem("userId");
  const navigateToReply = () => {
    navigate(`/messages/${selectedMessages[0].messageId}/reply`, {
      state: {
        data: selectedMessages[0]
      }
    });
  };
  const updateSelectedMessage = ({ row }) => {
    setOpenSelectedMessage(row);
  };
  const messageBackClickHandler = () => {
    setOpenSelectedMessage({});
  };

  const deleteMessageHandler = () => {
    let deletedMessagesIds = selectedMessages.map(({ messageId }) => ({ messageId }));
    let payload = {
      userId,
      deletedMessagesIds
    };
    actions.deleteMessageFromList(payload);
    setSelectionModel([]);
  };
  const downloadHandler = () => {
    selectedMessages[0].messageAttachment.map((item) => {
      let payload = {
        filePath: item.attachmentUrl
      };
      actions.getFile(payload);
    });
    setSelectionModel([]);
  };
  useEffect(() => {
    if (downloadedFileUrl) {
      let a = document.createElement("a");
      a.href = downloadedFileUrl;
      a.download = "document.pdf";
      a.click();
      actions.resetMessageExecuteStatus();
    }
  }, [downloadedFileUrl]);

  useEffect(() => {
    if (userId) {
      let payload = {
        userId: userId,
        messagesStatus: "Draft"
      };
      actions.getMessagesList(payload);
    }
  }, [deletedMessageLoading, userId]);

  useEffect(() => {
    if (ListOfDraftMessages) {
      let rows = ListOfDraftMessages.messageList.map((item, index) => {
        item.id = index;
        return item;
      });
      setRows(rows);
    }
  }, [ListOfDraftMessages]);

  return (
    <Box>
      <Grid container className="mt-15">
        <Grid item xs={1}>
          {Object.keys(openSelectedMessage).length != 0 && (
            <Button onClick={messageBackClickHandler}>
              <KeyboardBackspaceIcon />
            </Button>
          )}
        </Grid>
        <Grid item xs={1.7}>
          <Button
            variant="outlined"
            onClick={navigateToReply}
            disabled={selectedMessages.length != 1}>
            Continue editing
          </Button>
        </Grid>
        <Grid item xs={1.05}>
          <Button
            variant="outlined"
            onClick={deleteMessageHandler}
            disabled={selectedMessages.length == 0}>
            Discard
          </Button>
        </Grid>
        <Grid item xs={8.25}>
          <Button
            variant="outlined"
            onClick={downloadHandler}
            disabled={
              selectedMessages.length != 1 || !selectedMessages[0]?.messageAttachment?.length
            }>
            Download Attachment
          </Button>
        </Grid>
      </Grid>
      {Object.keys(openSelectedMessage).length != 0 ? (
        <Box className="message-body">
          <Stack>
            <Stack gap={1}>
              <Typography>{openSelectedMessage.type}</Typography>
              <Typography>{openSelectedMessage.subject}</Typography>
              <Typography>{openSelectedMessage.sentDate}</Typography>
            </Stack>

            <Divider fitted="true" sx={{ mt: 2, mb: 2 }} />
            <Box
              dangerouslySetInnerHTML={{
                __html: openSelectedMessage?.body?.split("#br#").join("\n")
              }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            {openSelectedMessage.hasAttachment &&
              openSelectedMessage.messageAttachment.map((item, index) => {
                return (
                  <Card className="msg-attachment" key={index}>
                    <CardMedia
                      component="iframe"
                      src={item.attachmentUrl}
                      width="100%"
                      height="80"
                      scrolling="no"
                    />
                    <CardContent>
                      <Stack direction="row" className="flex-between">
                        <Stack direction="row" className="align-center" spacing={1}>
                          <ArticleIcon color="primary" />
                          <Typography>{item.attachFileName}</Typography>
                        </Stack>
                        <Link to={item.attachmentUrl} target="_blank">
                          <OpenInNewIcon />
                        </Link>
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
          </Stack>
        </Box>
      ) : (
        <Box className="grid-style">
          <DataGrid
            sx={{ mx: 1 }}
            loading={ListOfMessagesLoading}
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            rows={rows}
            columns={columns}
            onRowClick={(e) => {
              updateSelectedMessage(e);
            }}
            checkboxSelection={true}
            onSelectionModelChange={(ids) => {
              setSelectionModel(ids);
              const selectedIds = new Set(ids);
              setSelectedMessages(rows.filter((row) => selectedIds.has(row.id)));
            }}
            selectionModel={selectionModel}
          />
        </Box>
      )}
    </Box>
  );
};
const mapStateToProps = ({ messageReducer }) => {
  return {
    ListOfDraftMessages: messageReducer.ListOfDraftMessages,
    ListOfMessagesLoading: messageReducer.ListOfMessagesLoading,
    deletedMessageLoading: messageReducer.deletedMessageLoading,
    downloadedFileUrl: messageReducer.downloadedFileUrl
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMessagesList: (payload) => {
      dispatch(actionType.getMessagesList(payload));
    },
    deleteMessageFromList: (payload) => {
      dispatch(actionType.deleteMessageFromList(payload));
    },
    getFile: (payload) => {
      dispatch(actionType.getFile(payload));
    },
    resetMessageExecuteStatus: () => {
      dispatch(actionType.resetMessageExecuteStatus());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Draft);
