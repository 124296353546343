import { Box, Grid, Stack, Typography, Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import {
  infoHeader,
  infoSubHeader,
  knowEstimateheader,
  knowEstimateInfo,
  knowEstimateTxt,
  assistTxt,
  callNo,
  infoData,
  tasks,
  toPhoneFormat
} from "../../shared/constants";
import "./home.scss";
import HomeCards from "./HomeCards";
import { Link } from "react-router-dom";
import * as actionType from "../../store/actions";
import { connect } from "react-redux";
import HomeModal from "./HomeModal";
//import NextStep from "../../shared/components/NextStep";

const Home = (props) => {
  const {
    actions,
    caseStatusList,
    practiceData,
    profileData,
    loading,
    practiceDataLoading,
    userData
  } = props;
  const practiceId = localStorage.getItem("practiceId");
  const patientId = localStorage.getItem("patientId");
  const impersonatorId = localStorage.getItem("impersonatorId");
  const cognitoUserId = localStorage.getItem("cognitoUserId");
  const userEmail = localStorage.getItem("email");
  const [knowEstimates, setKnowEstimates] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("firstLogin")) {
      setOpenModal(true);
    }
    if (practiceId && patientId) {
      actions.getCaseStatusesData({
        practiceId,
        patientId
      });
      if (!Object.keys(profileData).length) {
        actions.getProfileData({
          practiceId,
          patientId
        });
      }
      if (!Object.keys(practiceData).length) {
        actions.getPracticeData({ practiceId });
      }
    }

    actions.resetEstimateCards();

    if (impersonatorId) {
      actions.createImpersonateLog({
        impersonatorId: impersonatorId,
        eventName: "Login In",
        screenName: "Home",
        eventType: "impersonate",
        ipAddress: "192.168.1.1",
        patientId: cognitoUserId
      });
    }

    actions.getUserList({
      email: userEmail,
      practiceId: practiceId,
      primaryRole: "Patient"
    });
  }, []);

  useEffect(() => {
    if (userData.length > 0) {
      localStorage.setItem("userId", userData[0].userId);
    }
  }, [userData]);

  useEffect(() => {
    if (caseStatusList) {
      tasks.map((item) => {
        if (caseStatusList[item.status] == "completed" || item.status == "completed") {
          setActiveStep(item.step);
          setData(infoData[item.infoName]);
        }
        if (caseStatusList[item.status] == "Published") {
          setActiveStep(item.step);
        }
      });
    } else {
      setActiveStep(1);
    }
  }, [caseStatusList]);

  const handleClick = () => {
    setKnowEstimates(!knowEstimates);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Box>
      {loading ? (
        <Stack justifyContent="center" alignItems="center" p={20}>
          <CircularProgress size={50} />
        </Stack>
      ) : (
        <Box>
          <HomeModal
            open={openModal}
            close={handleClose}
            practiceData={practiceData}
            loading={practiceDataLoading}
          />
          <Box className="bg-image box-info">
            {activeStep == 5 ? (
              <Stack spacing={4} py={6}>
                <Typography>
                  If you have any questions, please contact {practiceData?.longName ?? "-"} at{" "}
                  <Link
                    to={"tel://" + practiceData?.phoneNumber}
                    target="_blank"
                    className="color-white">
                    {(practiceData &&
                      practiceData.phoneNumber &&
                      toPhoneFormat(practiceData.phoneNumber)) ||
                      "-"}
                  </Link>
                  .
                  {/*  If you have any questions,{" "}
                  <Link to="/messages/inbox" className="color-white">
                    send us a message
                  </Link>{" "}
                  or call{" "}
                  <Link to={"tel://" + callNo} target="_blank" className="color-white">
                    {callNo}
                  </Link> */}
                </Typography>
                {/* <Link to="/messages/inbox">
                  <Button variant="contained">Message Us</Button>
                </Link> */}
              </Stack>
            ) : caseStatusList?.estimateStatus != "Published" ? (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Greetings, {profileData?.firstName}! Welcome to your Financial Portal.
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Our goal is to provide you with the ultimate patient experience. A large part of
                    that is knowing your personal information is safe, secure, easily accessible and
                    clear. We will safely offer transparency regarding the cost of treatment,
                    including Fertility Preservation, IVF and more, right here in your portal.
                  </Typography>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Typography>
                    Are you ready to take the next step? Once you complete your diagnostic testing
                    and your provider recommends a course of treatment, one of our expert financial
                    counselors will create a Treatment Estimate on your behalf. Your Treatment
                    Estimate, along with payment options will be available for your review here in
                    your portal.
                  </Typography>
                </Grid>
                <Grid item xs={6}></Grid>
                {/* <Grid item xs={12}>
                  <Typography>
                    If you have any questions,{" "}
                    <Link to="/messages/inbox" className="color-primary">
                      send us a message
                    </Link>{" "}
                    or call{" "}
                    <Link to={"tel://" + callNo} target="_blank" className="color-primary">
                      {callNo}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {caseStatusList?.estimateStatus != "Published" ? (
                    <Button variant="contained" onClick={handleClick}>
                      Know Estimate
                    </Button>
                  ) : (
                    <Stack direction="row" spacing={2}>
                      {data.button?.map((item, index) => {
                        return (
                          <Link to={item.link} key={index}>
                            <Button variant="contained">{item.name}</Button>
                          </Link>
                        );
                      })}
                    </Stack>
                  )}
                </Grid> */}
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {caseStatusList?.estimateStatus != "Published"
                      ? "Hi " + profileData?.firstName + "! " + infoHeader
                      : data?.header}
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={6}>
                  <Typography>
                    {caseStatusList?.estimateStatus != "Published"
                      ? infoSubHeader
                      : data?.subHeader}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    If you have any questions, please call us at{" "}
                    <Link
                      to={"tel://" + practiceData?.phoneNumber}
                      target="_blank"
                      className="color-white">
                      {(practiceData &&
                        practiceData.phoneNumber &&
                        toPhoneFormat(practiceData.phoneNumber)) ||
                        "-"}
                    </Link>
                    {/* If you have any questions,{" "}
                    <Link to="/messages/inbox" className="color-white">
                      send us a message
                    </Link>{" "}
                    or call{" "}
                    <Link to={"tel://" + callNo} target="_blank" className="color-white">
                      {callNo}
                    </Link> */}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {caseStatusList?.estimateStatus != "Published" ? (
                    <Button variant="contained" onClick={handleClick}>
                      Know Estimate
                    </Button>
                  ) : (
                    <Stack direction="row" spacing={2}>
                      {data?.button?.map((item, index) => {
                        return (
                          <Link to={item.link} key={index}>
                            <Button variant="contained">{item.name}</Button>
                          </Link>
                        );
                      })}
                    </Stack>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
          <Box>
            {knowEstimates ? (
              <Grid container spacing={8} className="pad-t-l-r-25">
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    <Typography variant="body1">{knowEstimateheader}</Typography>
                    <Typography>{knowEstimateTxt}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{knowEstimateInfo}</Typography>
                  <Stack py={2}>
                    <Typography variant="body2">{assistTxt}</Typography>
                    <Typography>
                      Please{" "}
                      <Link to="/messages/inbox" className="color-white">
                        Send us a message
                      </Link>
                    </Typography>
                  </Stack>
                  <Typography>
                    To speak with a help desk associate, call{" "}
                    <Link to={"tel://" + callNo} target="_blank" className="color-white">
                      {callNo}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Box>
          <HomeCards activeStep={activeStep} />
          {/* <Card className="pad-10 m-10">
            <NextStep estimatePublishdate={caseStatusList && caseStatusList.publishedDate} />
          </Card> */}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = ({ homeReducer, profileReducer }) => {
  return {
    caseStatusList: homeReducer.caseStatusList,
    loading: homeReducer.loading,
    error: homeReducer.error,
    practiceData: homeReducer.practiceData,
    practiceDataLoading: homeReducer.practiceDataLoading,
    profileData: profileReducer.profileData,
    personData: homeReducer.personData,
    userData: homeReducer.userList
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getCaseStatusesData: (payload) => {
      dispatch(actionType.getCaseStatusesData(payload));
    },
    getPracticeData: (payload) => {
      dispatch(actionType.getPracticeData(payload));
    },
    getProfileData: (payload) => {
      dispatch(actionType.getProfileData(payload));
    },
    getPersonData: (payload) => {
      dispatch(actionType.getPersonData(payload));
    },
    resetEstimateCards: () => {
      dispatch(actionType.resetEstimateCards());
    },
    createImpersonateLog: (payload) => {
      dispatch(actionType.createImpersonateLog(payload));
    },
    getUserList: (payload) => {
      dispatch(actionType.getUserList(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
