import { Box, List } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ListItemLink from "../../shared/components/ListItemLink";
import { msgConfig } from "../../shared/constants";
import useMediaQuery from "@mui/material/useMediaQuery";

const MessageConfiguration = () => {
  const location = useLocation();
  const selectedPath = msgConfig.findIndex((item) => item.url === location.pathname);
  const [selectedIndex, setSelectedIndex] = useState(selectedPath);
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box className="sidebar-container">
      <List disablePadding>
        <div style={{ display: isSmallScreen ? "flex" : "inherit" }}>
          {msgConfig.map(({ title, url, icon }, index) => (
            <ListItemLink
              key={index}
              selected={selectedIndex === index}
              onClick={() => {
                handleListItemClick(index);
              }}
              to={url}
              primary={title}
              icon={<img src={icon} alt="" />}
            />
          ))}
        </div>
      </List>
    </Box>
  );
};

export default MessageConfiguration;
